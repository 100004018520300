.filter-current-subtitle {
    margin-bottom: $indent__xxs;

    &:after {
        content: attr(data-count);
        display: inline-block;
        margin-left: 1rem;
        text-align: center;
    }
}

.item-filtered.filter-options-title {
    background: $filter-selected-pill__background;
    border: 1px solid #c2c2c2;
    border-radius: 3rem;
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.4rem;
    margin-bottom: $indent__xxxs;
    margin-right: $indent__xxxs;
    padding: $indent__xxxxs $indent__xxxs;
}
