.cms-page-view {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        + p {
            margin-top: $indent__xs;
        }
    }
}
