.footer.content {
    border-top: 0;
    max-width: none;
    padding-top: $indent__md;
    position: relative;

    &:before {
        background: linear-gradient(90deg, #0f341c 0%, #0f341c 100%);
        background-blend-mode: multiply;
        bottom: 100%;
        content: '';
        height: 10px;
        left: 0;
        mask-position: 50% 0;
        mask-repeat: no-repeat;
        mask-size: 150%;
        position: absolute;
        width: 100%;

        @include min-screen($screen__sm) {
            height: 30px;
        }
    }

    .button.ghost-white {
        @include max-screen($screen__xxs) {
            font-size: 80%;
        }
    }
}

.footer__content-container {
    border-bottom: 1px solid white;
    display: flex;
    flex-flow: row wrap;
    font-family: 'Source Sans Pro', Helvetiva, Arial, sans-serif;
    margin-bottom: $indent__xs;
    padding-bottom: $indent__xxxxs;
}

.footer__column--presskit {
    > p {
        color: $text__muted-color--light;
        font-family: $font-sourcesanspro--light, Helvetiva, Arial, sans-serif;
    }
}

.footer__column {
    box-sizing: border-box;
    margin-bottom: $indent__sm;
    padding-right: $indent__xxxxs;
    width: 50%;

    @include min-screen($screen__md) {
        padding-right: $indent__xxxs;
        width: 33%;
    }

    @include min-screen($screen__lg) {
        width: 25%;
    }

    &:first-of-type {
        @include max-screen($screen__md) {
            width: 100%;
        }
    }

    &:last-of-type {
        @include max-screen($screen__md) {
            width: 100%;
        }
    }

    * {
        color: $white;
    }

    input {
        color: $text__base-color;
    }

    a:not(.button) {
        &:visited,
        &:focus,
        &:hover {
            color: $white;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    a[href^="tel"] {
        color: $text__muted-color--light;
        font-family: $font-sourcesanspro--light, Helvetiva, Arial, sans-serif;

        &:hover {
            color: $text__muted-color--light;
        }
    }

    ul > li {
        margin-bottom: $indent__xxxxs !important; // important to overwrite general magento styling

        @include min-screen($screen__md) {
            margin-bottom: 1rem !important; // specific as per design
        }
    }

    h3,
    h4,
    h5,
    h6 {
        margin-bottom: $indent__xxs;
    }
}

.footer__column--logo {
    margin-bottom: $indent__lg;
    text-align: center;
    width: 100%;

    @include min-screen($screen__lg) {
        display: none;
        height: 0;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 0;
    }
}

.footer__column--socials {
    margin-top: $indent__xxxxs;
    padding-right: $indent__sm;

    @include min-screen($screen__lg) {
        width: 18%;
    }
}

.footer__column--get-in-touch {
    @include min-screen($screen__lg) {
        width: 14%;
    }
}

.footer__column--deeplinks {
    @include min-screen($screen__lg) {
        width: 15%;
    }
}

.footer__column--presskit {
    @include min-screen($screen__lg) {
        width: 20%;
    }
}

.footer__column--newsletter {
    padding-right: 0;

    @include min-screen($screen__lg) {
        width: 33%;
    }
}
.social-links {
    margin-top: 50px;
}

