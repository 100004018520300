$lists-usps__vink-color: #000 !default;

$lists-icons: () !default;
$lists-icons--color: #000 !default;

ol,
ul {
    list-style-position: inside;
    padding-left: 20px;

    &.list-unstyled {
        list-style: none;
    }

    &.list-inline {
        display: flex;
        padding-left: 0;

        li {
            margin-left: 20px;
            margin-right: 10px;
        }
    }
}

.list-usps {
    list-style: none;

    li:before {
        border: solid $lists-usps__vink-color;
        border-width: 0 0.2rem 0.2rem 0;
        content: "";
        display: inline-block;
        margin: 0 1.2rem 0.5rem 0;
        padding: 0.3rem 0.1rem 0.7rem 0.3rem;
        transform: rotate(45deg);
        vertical-align: middle;
    }
}

.product-tile__content {
    min-height: 140px;
}


.list-style-icon {
    list-style: none;
    margin: 0;
    padding: 0;

    .list-icon {
        margin-bottom: $indent__xxs;
        padding-left: 6rem;
        position: relative;

        &:before {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            content: '';
            display: block;
            position: absolute;
        }

        @each $icon, $settings in $lists-icons {
            &.#{$icon} {
                &:before {
                    @if map-has_key($settings, 'css') {
                        @each $key, $attr in map_get($settings, 'css') {
                            #{$key}: #{$attr};
                        }
                    }
                    background-image: svg-url(svg-icon(map_get($settings, 'icon'), $lists-icons--color));

                    @if map-has_key($settings, 'size') and map-has_key($settings, 'width') {
                        @include svg-size(map_get($settings, 'size'), map_get($settings, 'width'));
                    }
                }
            }
        }
    }
}
