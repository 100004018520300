.swatch-attribute {
    margin-left: 5px;
}


.swatch-option-link-layered {
    color: $text__base-color;
    display: flex;
    font-weight: 300;
    text-decoration: none;

    &:visited,
    &:hover {
        color: $text__base-color;
        font-weight: 300;
        text-decoration: underline;
    }
}

.swatch-option.image,
.swatch-option {
    @include reset-border();
    height: 26px;
    overflow: visible;
    position: relative;
}

.swatch-option.image {
    margin-left: $indent__md;
}

.swatch-option.text:not(.disabled),
.swatch-option.color:not(.selected),
.swatch-option.image:not(.disabled) {
    @include reset-border($important: true);
}

.swatch-option.color.selected {
    outline: $color-black solid 2px;
    outline-offset: 3px;

    &:hover {
        border: solid 1px $color-black;
        outline: $color-black solid 2px;
    }
}

.swatch-option.text,
.swatch-option.image {
    font-weight: 600;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    &:after {
        background: $checkbox-border-background;
        border: 1px solid $checkbox-border-color;
        border-radius: $checkbox-border-radius;
        content: '';
        display: inline-block;
        height: $checkbox-height;
        left: -3.5rem;
        position: absolute;
        top: 3px;
        width: $checkbox-width;

        &:hover {
            cursor: pointer;
        }
    }

    &.selected:after {
        background: $radiobox-border-background--active;
        background-image: svg-url(svg-icon($icon-vink, $radiobox-icon-color--active));
        background-position: center;
        background-repeat: no-repeat;
        background-size: 10px ($icon-vink-size * 10px);
        border: 1px solid $radiobox-border-color--active;
    }
}

.swatch-option.color {
    &.selected {
        border: 1px solid #000;
    }
}

.swatch-option {
    min-width: 20px;
}

.swatch-option.color,
.swatch-option.image {
    height: 28px;
    min-width: 28px;
    width: 28px;

    + span {
        margin-left: 0.2rem;
        margin-top: 0.25rem;
    }
}

.swatch-option.color {
    border-radius: 30px;

    + span {
        display: none;
    }
}

.swatch-option-tooltip {
    display: none !important;
}


// Styled specifically according to design
.swatch-option-link-layered {
    display: inline-block;
    height: 35px;

    @include min-screen($screen__md) {
        height: 45px;
    }
}
