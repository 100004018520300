// Source Sans Pro Light
$font-sourcesanspro--light: 'Source Sans Pro Light';

@font-face {
    font-family: 'Source Sans Pro Light';
    src: url('../fonts/SourceSansPro/SourceSansPro-Light.woff2') format('woff2');
    src: url('../fonts/SourceSansPro/SourceSansPro-Light.eot');
    src: url('../fonts/SourceSansPro/SourceSansPro-Light.eot#iefix') format('embedded-opentype'),
    url('../fonts/SourceSansPro/SourceSansPro-Light.woff') format('woff'),
    url('../fonts/SourceSansPro/SourceSansPro-Light.ttf') format('truetype');
    font-weight: 400;
}

// Source Sans Pro
$font-sourcesanspro: 'Source Sans Pro';

@font-face {
    font-family: $font-sourcesanspro;
    src: url('../fonts/SourceSansPro/SourceSansPro-Regular.woff2') format('woff2');
    src: url('../fonts/SourceSansPro/SourceSansPro-Regular.eot');
    src: url('../fonts/SourceSansPro/SourceSansPro-Regular.eot#iefix') format('embedded-opentype'),
    url('../fonts/SourceSansPro/SourceSansPro-Regular.woff') format('woff'),
    url('../fonts/SourceSansPro/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: 500;
}

// Source Sans Pro Semi Bold
$font-sourcesanspro--semibold: 'Source Sans Pro SemiBold';

@font-face {
    font-family: $font-sourcesanspro--semibold;
    src: url('../fonts/SourceSansPro/SourceSansPro-SemiBold.woff2') format('woff2');
    src: url('../fonts/SourceSansPro/SourceSansPro-SemiBold.eot');
    src: url('../fonts/SourceSansPro/SourceSansPro-SemiBold.eot#iefix') format('embedded-opentype'),
    url('../fonts/SourceSansPro/SourceSansPro-SemiBold.woff') format('woff'),
    url('../fonts/SourceSansPro/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

// Playfair Display

$font-playfairdisplay--regular: 'PlayfairDisplay Regular';

@font-face {
    font-family: $font-playfairdisplay--regular;
    src: url('../fonts/PlayfairDisplay/PlayfairDisplay-Regular.woff2') format('woff2');
    src: url('../fonts/PlayfairDisplay/PlayfairDisplay-Regular.eot');
    src: url('../fonts/PlayfairDisplay/PlayfairDisplay-Regular.eot#iefix') format('embedded-opentype'),
    url('../fonts/PlayfairDisplay/PlayfairDisplay-Regular.woff') format('woff'),
    url('../fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
}

// Playfair Display Bold
$font-playfairdisplay--bold: 'PlayfairDisplay Bold';


@font-face {
    font-family: $font-playfairdisplay--bold;
    src: url('../fonts/PlayfairDisplay/PlayfairDisplay-Bold.woff2') format('woff2');
    src: url('../fonts/PlayfairDisplay/PlayfairDisplay-Bold.eot');
    src: url('../fonts/PlayfairDisplay/PlayfairDisplay-Bold.eot#iefix') format('embedded-opentype'),
    url('../fonts/PlayfairDisplay/PlayfairDisplay-Bold.woff') format('woff'),
    url('../fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf') format('truetype');
    font-weight: 700;
}





