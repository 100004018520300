$indent__xxxxs:  0.7rem;
$indent__xxxs:  1.2rem;
$indent__xxs:   1.6rem;
$indent__xs:    2.5rem;
$indent__sm:    3rem;
$indent__md:    3.5rem;
$indent__lg:    4.4rem;
$indent__xl:    6rem;
$indent__xxl:   8rem;
$indent__xxxl:  10rem;
