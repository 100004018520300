$header-collapsed-height: 60px;
$header-inflated-height: 110px;
$header-animated-properties: (height, background, top);
$logo-max-width: 11.4rem;
$mobile-logo-max-dimensions: 3.2rem;

.logo {
    display: flex;
    float: unset;
    left: 50%;
    margin: 0;
    order: 0;
    position: absolute;
    transform: translateX(-50%);
    z-index: 9999999;

    @include screen($screen__sm, $screen__xxl) {
        .header__content--collapsed & {
            margin: 0;
            order: 2;
        }
    }

    @include screen($screen__sm, $screen__md) {
        .header__content--collapsed & {
            margin-right: $indent__xxs;
        }
    }

    @include max-screen($screen__sm) {
        grid-area: 1 / 2 / 2 / 5;
    }

    .navigation--open & {
        filter: invert(0);
    }
}

body:not(.navigation--open) {
    .header__content--collapsed .logo {
        @include screen($screen__sm, $screen__xxl) {
            left: unset;
            position: static;
            transform: unset;
        }
    }
}

.logo--desktop,
.logo svg {
    @include default-transition($targets: (filter, height));

    @include max-screen($screen__sm) {
        transform: scale(1) !important;
        transform-origin: center;
        width: $logo-max-width !important;
    }

    .header__content--collapsed & {
        //filter: contrast(0) invert(1);
        height: $mobile-logo-max-dimensions;
        transform: scale(0);
        transform-origin: center;
        width: 0;
        @include default-transition($targets: (filter, height));
    }
}

.logo--desktop-collapsed {
    opacity: 0;
    overflow: hidden;
    width: 0;

    .header__content--collapsed & {
        height: 3.4rem;
        opacity: 1;
        width: $mobile-logo-max-dimensions;
        @include default-transition();
    }
}

//
// Header content,
// white background,
// collapsed state
// and
// hidden state (scrolling upward (currently disabled in JS, see header-animation.js options))
// ---------------------------------------------

.header__content {
    background: transparent;
    height: $header-inflated-height;
    padding-top: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 50;
    @include default-transition($targets: $header-animated-properties, $duration: 0.5s);
}

body.has--category-header-image,
body.has--video-hero-widget {
    .header__content {
        &:before {
            background: linear-gradient(180deg, #0f341c 0%, transparent 100%);
            content: '';
            left: 0;
            opacity: 0.7;
            pointer-events: none;
            position: absolute;
            right: 0;
            @include default-transition($targets: $header-animated-properties);
        }

        &.header__content--collapsed {
            &:before {
                opacity: 0;
                @include default-transition($targets: (opacity));
            }
        }
    }
}

body.has--category-header-image {
    .header__content {
        &:before {
            height: 14.5rem; // as per design
        }
    }
}

body.has--video-hero-widget {
    .header__content {
        &:before {
            height: 20rem; // as per design
        }
    }
}

.header__content--collapsed {
    //box-shadow: $default-dropshadow--hover;
    height: $header-collapsed-height;
    @include default-transition($targets: $header-animated-properties, $duration: 0.5s);

    @include max-screen($screen__xs) {
        height: $header-inflated-height;
    }
}

.header__content--white {
    animation: 0.5s slide-top 0s forwards;
    @include default-transition($targets: $header-animated-properties, $duration: 0.5s);
    background: rgba(15, 52, 28, 1);
    transform: translateY(-100%);
    & > div {
        animation: 0.5s slide-top 0s forwards;
        transform: translateY(100%);
    }

}

@keyframes slide-top {
    to {
        transform: translateY(0);
    }
}

.header.content {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    max-width: none;
    padding-top: 0;

    &:before,
    &:after {
        display: none;
    }

    @include max-screen($screen__sm) {
        display: grid;
        grid-column-gap: 0;
        grid-row-gap: 20px;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 1fr);
        height: unset;
    }
}

.page-header {
    border: 0;
    height: $header-inflated-height;
    margin-bottom: 0;
    position: sticky;
    top: 0;
    z-index: 9999;

    body.has--category-header-image &,
    body.has--video-hero-widget & {
        height: initial;

        @include min-screen($screen__sm) {
            height: initial;
        }
    }
}

.page-header__items {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin: 0;

    @include min-screen($screen__sm) {
        gap: $indent__xxs;
        justify-content: flex-end;
    }

    @include max-screen($screen__sm) {
        grid-area: 2 / 2 / 3 / 6;

        & .search-trigger,
        & .authorization-link a,
        & .link.wishlist a,
        & .action.showcart {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 4px;
            justify-content: flex-end;
        }

        .search-trigger,
        .page-header__authorization,
        .link.wishlist,
        .minicart-wrapper {
            width: 25%;
        }
    }
}

.menu-trigger__container {
    margin-top: 4px;

    @include max-screen($screen__sm) {
        grid-area: 2 / 1 / 3 / 2;

        .header__content--collapsed & {
            margin-top: 4px;
        }
    }
}

.page-header__items,
.sections.nav-sections {
    flex: 1;

    @include screen($screen__sm, $screen__xxl) {
        .header__content--collapsed & {
            flex: 0 1 33%;
            margin-left: auto;
            order: 3;
        }
    }
}
