$column-to-row-breakpoint: $screen__md;

.product-info-main__product-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: $indent__sm;

    @include min-screen($column-to-row-breakpoint) {
        flex-direction: row;
        margin-bottom: $indent__xxxl;
    }
}

.product-info-main__description {
    flex: 0 1 66%;
    margin-bottom: $indent__sm;

    @include min-screen($column-to-row-breakpoint) {
        margin-bottom: 0;
        padding-right: $indent__xxxl;
    }
}

@include min-screen($screen__md) {

    .product-info-main__specification {
        flex: 0 1 39%;
        &.with-description {
            flex: 0 1 42%;
        }
    }
}

.product-info-main__specifications {


    flex: 0 1 33%;
    @include min-screen($column-to-row-breakpoint) {
        padding-left: $indent__xxxs;
    }
}

.product-info-main__specifications-column,
.product-info-main__description-description {
    margin-top: $indent__sm;
}

.product-info-main__specifications-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:not(:last-child) {
        border-bottom: solid 1px $border-color__base--light;
    }
}

.product-info-main__specifications-label,
.product-info-main__specifications-value,
.product-info-main__specifications-row {
    margin: 0;
    width: 100%;
}

.product-info-main__specifications-row {
    padding: $indent__xxs 0;
}

.product-info-main__specifications-label {
    font-family: $font-family-sourcesanspro--semibold;
}

.product-info-main__specifications-value {
    text-align: right;
}
