$grid-breakpoints: (
    'xs': $screen__xxs,
    'sm': $screen__xs,
    'md': $screen__md,
    'lg': $screen__lg,
    'xl': $screen__xl
);
$grid-cols: 12;
$grid-gutter: 2%;
$grid-margin-outer: -2%;
