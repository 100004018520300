.hide-desktop {
    @include min-screen($screen__xs) {
        display: none;
    }
}

.hide-mobile {
    @include max-screen($screen__xs) {
        display: none;
    }
}
