.modes,
.display-modes__title {
    text-align: right;
}

.modes-mode.mode-grid {
    &:hover,
    &:visited {
        text-decoration: none;
    }
}
