.products-related,
.products-upsell,
.product-crossell {
    margin-top: $indent__lg;

    .product-items .product-item {
        @include min-screen($screen__sm) {
            @include g-col(4);
        }

        @include min-screen($screen__lg) {
            @include g-col(3);
        }
    }
}
