#cookie-status { // sass-lint:disable-line no-ids
    display: none; // to prevent a flash of unstyled content
}

.message.global {
    &.cookie {
        @extend .bg--gradient-black;

        color: $color-white;
        display: flex;
        padding: 0 0 10px 0;
        z-index: 999;

        &:before {
            background: linear-gradient(90deg, #0f341c 0%, #0f341c 100%);
            background-blend-mode: multiply;
            bottom: 100%;
            content: '';
            height: 30px;
            left: 0;
            mask-position: 50% 0;
            mask-repeat: no-repeat;
            mask-size: 150%;
            position: absolute;
            width: 100%;

        }
        @include max-screen($screen__m) {
            &:before {
                height: 10px;
            }
        }

        a {
            border-bottom: 1px solid #fff;
            color: $color-white;
            padding-bottom: 0.2rem;
            text-decoration: none;
            transition: all 0.225s cubic-bezier(0.32, 1, 0.23, 1);

            &:visited,
            &:focus,
            &:hover {
                color: $white;
            }

            &:hover {
                border-bottom: 1px solid transparent;
                text-decoration: none !important;
            }
        }

        .content {
            align-items: center;
            display: flex;
            margin: 0 auto;

            p {
                padding: 0 $indent__lg;
            }

            @include max-screen($screen__m) {
                flex-direction: column;

                p {
                    padding: $indent__xs $indent__xs 0 $indent__xs;
                }
            }
            @include min-screen(1200px) {
                p {
                    padding: 0 $indent__xxl;
                }
            }

            .actions {
                margin: 0 $indent__md;
                @include max-screen($screen__m) {
                    margin-top: $indent__md;
                }
            }
        }
    }
}
