$pagination-current: #000 !default;

.toolbar-item.toolbar-item--pager {
    display: block;
    justify-content: center;
    margin-top: $indent__lg;

    .pages {
        display: block;
        float: unset;
        text-align: center;
    }
}

.pages .item {
    border: 0;

    &.pages-item-previous {
        margin-right: $indent__xxxxs;
        @include min-screen($screen__md) {
            margin-right: $indent__md;
        }

        .button {
            &:before {
                @include icon('caret-left-black');
                margin-right: 1rem;

                @include max-screen($screen__xxs) {
                    transform: scale(0.9) translateY(1px);
                }
            }

            &:after {
                display: none;
            }

            &:focus,
            &:hover {
                &:before {
                    filter: invert(1);
                }
            }
        }
    }

    &.pages-item-next {
        margin-left: $indent__xxxxs;
        @include min-screen($screen__md) {
            margin-left: $indent__md;
        }
    }

    &.pages-item-next,
    &.pages-item-previous {
        @include max-screen($screen__xs) {
            .button {
                span {
                    display: none;
                }

                &:before,
                &:after {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}

.toolbar-item--pager {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: $indent__xs 0;
    width: 100%;

    @include min-screen($screen__sm) {
        padding: $indent__xxs 0;
    }

    &:empty {
        border-bottom: 0;
        padding: 0;
    }
}

.pages .item strong.page,
.pages .item a.page {
    color: $text__base-color;
    font-family: $font-family-sourcesanspro;
    font-size: 1.9rem;
    font-weight: 400;
    padding: 0 $indent__xxxxs;
    text-decoration: underline;

    &:hover,
    &:visited {
        color: $text__base-color;
    }

    &:hover {
        text-decoration: none;
    }
}

.pages .item.current .page {
    color: $pagination-current;
    text-decoration: none;

    &:hover {
        color: $pagination-current;
        cursor: default;
        text-decoration: none;
    }
}
