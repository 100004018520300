.has--category-header-image,
.catalog-category-view {
    .page-title-wrapper {
        margin-bottom: $indent__md;
    }

    .page-title {
        color: $text__white-color;
        line-height: 6rem;
        margin-bottom: 2rem;
        margin-top: 0;

        @include min-screen($screen__md) {
            margin-top: 2rem;
        }

        @include min-screen($screen__lg) {
            font-size: $font-size-page-title;
        }
    }
}
