.widget-cards {
    margin-bottom: $indent__xxxl;
}

.widget-cards__centered {
    margin-bottom: $indent__lg;
    text-align: center;

    @include min-screen($screen__sm) {
        margin-bottom: $indent__xl;
    }
}

.widget-cards__title {
    font-size: 3.3rem;

    @include min-screen($screen__sm) {
        font-size: 5.5rem;
    }
}

.widget-cards__button {
    margin-top: $indent__sm;
}

.widget-cards__subtitle {
    display: block;
    font-family: $typografy-heading;
    font-size: 1.9rem;
    margin-top: $indent__xxs;

    @include min-screen($screen__sm) {
        font-size: 2.4rem;
        margin-top: 1.8rem;
    }
}

.widgets-cards__slider {
    margin-top: $indent__md;

    &.flickity-enabled {
        margin-bottom: $indent__md;
    }

    @include max-screen($screen__sm) {
        > *:nth-of-type(n+1) {
            display: none;
        }

        &.flickity-enabled {
            > *:nth-of-type(n+1) {
                display: inherit;
            }
        }
    }

    @include min-screen($screen__sm) {
        @include g-row();

        .card__container {
            @include g-col();
        }
    }
}
