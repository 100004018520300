$masonry-gap: 1.6rem !default;



// Sorry Collega's voor deze "Magic code", maar dan is het uiteindelijk makkelijker aan te passen..
$masonory-designs: (
    design-1: (
        default: (
            grid-template-columns: repeat(9, 1fr),
            grid-template-rows: minmax(min-content, 2fr) minmax(min-content, 0.5fr) repeat(2, minmax(min-content, 2fr)),
            grid-template-areas: '"pos-1 pos-2 pos-3 pos-4 pos-4 pos-4 pos-5 pos-5 pos-5" "pos-1 pos-2 pos-3 pos-4 pos-4 pos-4 pos-8 pos-8 pos-8" "pos-6 pos-6 pos-6 pos-4 pos-4 pos-4 pos-8 pos-8 pos-8" "pos-6 pos-6 pos-6 pos-7 pos-7 pos-7 pos-8 pos-8 pos-8"'
        ),
        hide-1: (
            grid-template-columns: repeat(6, 1fr),
            grid-template-areas:
            '"pos-4 pos-4 pos-4 pos-5 pos-5 pos-5" "pos-4 pos-4 pos-4 pos-8 pos-8 pos-8" "pos-4 pos-4 pos-4 pos-8 pos-8 pos-8" "pos-7 pos-7 pos-7 pos-8 pos-8 pos-8"'
        ),
        hide-2: (
            grid-template-columns: repeat(6, 1fr),
            grid-template-areas:
            '"pos-1 pos-2 pos-3 pos-5 pos-5 pos-5" "pos-1 pos-2 pos-3 pos-8 pos-8 pos-8" "pos-6 pos-6 pos-6 pos-8 pos-8 pos-8" "pos-6 pos-6 pos-6 pos-8 pos-8 pos-8"'
        ),
        hide-3: (
            grid-template-columns: repeat(6, 1fr),
            grid-template-areas:
            '"pos-1 pos-2 pos-3 pos-4 pos-4 pos-4" "pos-1 pos-2 pos-3 pos-4 pos-4 pos-4" "pos-6 pos-6 pos-6 pos-4 pos-4 pos-4" "pos-6 pos-6 pos-6 pos-7 pos-7 pos-7"'
        )
    ),
    design-2: (
        default: (
            grid-template-columns: repeat(9, 1fr),
            grid-template-rows: repeat(2, minmax(min-content, 2fr)) minmax(min-content, 0.5fr) minmax(min-content, 2fr),
            grid-template-areas:
            '"pos-1 pos-1 pos-1 pos-2 pos-2 pos-2 pos-3 pos-3 pos-3" "pos-1 pos-1 pos-1 pos-2 pos-2 pos-2 pos-8 pos-8 pos-8" "pos-1 pos-1 pos-1 pos-5 pos-6 pos-7 pos-8 pos-8 pos-8" "pos-4 pos-4 pos-4 pos-5 pos-6 pos-7 pos-8 pos-8 pos-8"'
        ),
        hide-1: (
            grid-template-columns: repeat(6, 1fr),
            grid-template-areas:
            '"pos-2 pos-2 pos-2 pos-3 pos-3 pos-3" " pos-2 pos-2 pos-2 pos-8 pos-8 pos-8" "pos-5 pos-6 pos-7 pos-8 pos-8 pos-8" "pos-5 pos-6 pos-7 pos-8 pos-8 pos-8"'
        ),
        hide-2: (
            grid-template-columns: repeat(6, 1fr),
            grid-template-areas:
            '"pos-1 pos-1 pos-1 pos-3 pos-3 pos-3" "pos-1 pos-1 pos-1 pos-8 pos-8 pos-8" "pos-1 pos-1 pos-1 pos-8 pos-8 pos-8" "pos-4 pos-4 pos-4 pos-8 pos-8 pos-8"'
        ),
        hide-3: (
            grid-template-columns: repeat(6, 1fr),
            grid-template-areas:
            '"pos-1 pos-1 pos-1 pos-2 pos-2 pos-2" "pos-1 pos-1 pos-1 pos-2 pos-2 pos-2" "pos-1 pos-1 pos-1 pos-5 pos-6 pos-7" "pos-4 pos-4 pos-4 pos-5 pos-6 pos-7"'
        )
    ),
    design-3: (
        default: (
            grid-template-columns: repeat(9, 1fr),
            grid-template-rows: repeat(2, minmax(min-content, 2fr)) minmax(min-content, 0.25fr) minmax(min-content, 2fr),
            grid-template-areas:
            '"pos-1 pos-1 pos-1 pos-2 pos-2 pos-2 pos-3 pos-3 pos-3" "pos-1 pos-1 pos-1 pos-5 pos-5 pos-5 pos-3 pos-3 pos-3" "pos-1 pos-1 pos-1 pos-5 pos-5 pos-5 pos-6 pos-7 pos-8" "pos-4 pos-4 pos-4 pos-5 pos-5 pos-5 pos-6 pos-7 pos-8"'
        ),
        hide-1: (
            grid-template-columns: repeat(6, 1fr),
            grid-template-areas:
            '"pos-2 pos-2 pos-2 pos-3 pos-3 pos-3" "pos-5 pos-5 pos-5 pos-3 pos-3 pos-3" "pos-5 pos-5 pos-5 pos-6 pos-7 pos-8" "pos-5 pos-5 pos-5 pos-6 pos-7 pos-8"'
        ),
        hide-2: (
            grid-template-columns: repeat(6, 1fr),
            grid-template-areas:
            '"pos-1 pos-1 pos-1 pos-3 pos-3 pos-3" "pos-1 pos-1 pos-1 pos-3 pos-3 pos-3" "pos-1 pos-1 pos-1 pos-6 pos-7 pos-8" "pos-4 pos-4 pos-4 pos-6 pos-7 pos-8"'
        ),
        hide-3: (
            grid-template-columns: repeat(6, 1fr),
            grid-template-areas:
            '"pos-1 pos-1 pos-1 pos-2 pos-2 pos-2" "pos-1 pos-1 pos-1 pos-5 pos-5 pos-5" "pos-1 pos-1 pos-1 pos-5 pos-5 pos-5" "pos-4 pos-4 pos-4 pos-5 pos-5 pos-5"'
        )
    )
) !default;

$masonory-items: (
    default: 8,
    hide-1: (
        design-1: (1, 2, 3, 6),
        design-2: (1, 4),
        design-3: (1, 4)
    ),
    hide-2: (
        design-1: (4, 7),
        design-2: (2, 5, 6, 7),
        design-3: (2, 5)
    ),
    hide-3: (
        design-1: (5, 8),
        design-2: (3, 8),
        design-3: (3, 6, 7, 8)
    )
) !default;


.masonryGallery--item {
    margin-bottom: $masonry-gap;
    overflow: hidden;
    position: relative;

    img {
        display: block;
        height: 100%;
        object-fit: cover;
        width: 100%;

        .fixed-size & {
            left: 0;
            position: absolute;
            top: 0;
        }
    }

    .button {
        bottom: $indent__xxs;
        position: absolute;
        right: $indent__xxs;
        transform: scale(0.6);
        transform-origin: bottom right;
        z-index: 2;
        @include min-screen($screen__sm) {
            transform: scale(0.8);
        }
    }

    &[data-clickable-element] {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        cursor: pointer;
        @include default-transition($targets: (transform, filter, box-shadow), $duration: 0.5s);

        img {
            @include default-transition($targets: (transform, filter, box-shadow), $duration: 0.5s);
        }

        &:focus,
        &:hover {
            transform: translateY(-5px);

            @include min-screen($screen__sm) {
                box-shadow: $default-dropshadow--hover;
            }

            img {
                transform: scale(1.1);
            }

            [data-clickable-link] {
                background: $button-ghost__background-color--hover;
                border: 1px solid $button-ghost__border-color--hover;
                color: $button-ghost__color;

                &:after {
                    filter: invert(1);
                }

                &:focus,
                &:hover {
                    transform: scale(0.6) translateY(0);
                    @include min-screen($screen__sm) {
                        transform: scale(0.8) translateY(0);
                    }
                }
            }
        }
    }
}

.masonryGallery {
    margin-bottom: $indent__xl;

    @include min-screen($screen__sm) {
        margin-bottom: $indent__xxxl;
    }

    .masonryGallery--container {
        display: grid;
        gap: 0 $masonry-gap;
        grid-auto-rows: max-content;

        @media all and (-ms-high-contrast: none) {
            @include g-row();
            > * {
                @include g-col(6);
                @include min-screen($screen__sm) {
                    @include g-col(4);
                }
            }
        }
    }

    @each $design, $design-map in $masonory-designs {
        &.#{$design} .masonryGallery--container {

            @each $attr, $value in map_get($design-map, 'default') {
                #{$attr}: #{$value};
            }
        }
    }

    @for $i from 1 through map_get($masonory-items, default) {
        .masonryGallery--item:nth-of-type(#{$i}) {

            grid-area: pos-#{$i};
        }
    }

    @include max-screen($screen__sm) {
        @each $design, $design-map in $masonory-designs {
            &.#{$design} {

                @each $column, $colum-map in map-remove($design-map, 'default') {
                    &.#{$column} .masonryGallery--container {

                        @each $attr, $value in $colum-map {
                            #{$attr}: #{$value};
                        }
                    }
                }
            }
        }

        @each $columns, $designs in map_remove($masonory-items, default) {
            &.#{$columns} {

                @each $design, $positions in $designs {
                    &.#{$design} .masonryGallery--item {

                        @each $pos in $positions {
                            &:nth-of-type(#{$pos}) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &.fixed-size {
        &.design-1, &.design-2, &.design-3 {
            .masonryGallery--container {
                grid-template-rows: max-content;
            }
        }
    }

    &.fixed-size.design-1 {
        .masonryGallery--item {
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3) {
                padding-top: (210/146*100%)
            }

            &:nth-of-type(4) {
                padding-top: (446/436*100%)
            }

            &:nth-of-type(5) {
                padding-top: (164/488*100%);
            }

            &:nth-of-type(6) {
                padding-top: (490/490*100%);
            }

            &:nth-of-type(7) {
                padding-top: (250/440*100%);
            }

            &:nth-of-type(8) {
                padding-top: (540/488*100%);
            }
        }
    }

    &.fixed-size.design-2 {
        .masonryGallery--item {
            &:nth-of-type(1) {
                padding-top: (518/474*100%)
            }

            &:nth-of-type(2) {
                padding-top: (470/472*100%)
            }

            &:nth-of-type(3) {
                padding-top: (248/418*100%)
            }

            &:nth-of-type(4) {
                padding-top: (158/474*100%)
            }

            &:nth-of-type(5),
            &:nth-of-type(6),
            &:nth-of-type(7) {
                padding-top: (158/125*100%);
            }

            &:nth-of-type(8) {
                padding-top: (428/418*100%);
            }
        }
    }

    &.fixed-size.design-3 {
        .masonryGallery--item {
            &:nth-of-type(1) {
                padding-top: (564/514*100%)
            }

            &:nth-of-type(2) {
                padding-top: (260/460*100%)
            }

            &:nth-of-type(3) {
                padding-top: (512/516*100%)
            }

            &:nth-of-type(4) {
                padding-top: (174/514*100%)
            }

            &:nth-of-type(5) {
                padding-top: (470/460*100%);
            }

            &:nth-of-type(6),
            &:nth-of-type(7),
            &:nth-of-type(8) {
                padding-top: (220/140*100%);
            }
        }
    }
}

// Random add Items to a large Masonry Grid:
.masonryGallery-random-build {
    column-gap: $masonry-gap;
    columns: 2;
    margin-bottom: $indent__xxxl;

    @include min-screen($screen__sm) {
        columns: unset;
        display: grid;
        gap: 0 $masonry-gap;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: max-content;
    }

    @media all and (-ms-high-contrast: none) {
        @include g-row();
        > * {
            @include g-col(6);
            @include min-screen($screen__sm) {
                @include g-col(4);
            }
        }
    }

    .masonryGallery--container {
        grid-auto-rows: max-content;
    }
}

.masonryGallery--container:nth-of-type(1) {
    @include min-screen($screen__sm) {
        .masonryGallery-random-build & {
            display: grid;
            gap: 0 $masonry-gap;
            grid-row: 1;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: max-content;
        }

        .masonryGallery-random-build.design-1 & {
            > *:nth-of-type(4n) {
                grid-column: 1 / span 3;
            }
        }

        .masonryGallery-random-builddesign-2 & {
            grid-column: 2;

            > *:nth-of-type(4n-3) {
                grid-column: 1 / span 3;
            }
        }

        .masonryGallery-random-build.design-3 & {
            grid-column: 3;

            > *:nth-of-type(4n-3) {
                grid-column: 1 / span 3;
            }
        }
    }
}
