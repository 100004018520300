.shipping-address-items {
    display: flex;
    flex-direction: column;

    @include min-screen($screen__sm) {
        display: inline-block;
        flex-direction: unset;
    }

    .shipping-address-item {
        width: 100% !important;
    }

    .shipping-address-item.not-selected-item {
        padding: $indent__xs;
    }

    .action-select-shipping-item {
        width: auto;
    }

    //.action-hide-popup {
    // @include button('secondary');
    //}

    .edit-address-link {
        bottom: $indent__xxxs !important;
        right: $indent__xxxs !important;
        top: unset !important;

        &:before {
            display: none;
        }

        > span {
            clip: unset !important;
            display: inline-block !important;
            height: auto !important;
            position: static !important;
            width: auto !important;
        }
    }
}

.opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
    display: none;
}

.opc-wrapper .edit-address-link {
    margin-top: 2rem;
}
