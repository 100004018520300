$grid-breakpoints: (
    'sm': 768px,
    'md': 1024px,
    'lg': 1200px
) !default;
$grid-cols: 12 !default;
$grid-gutter: 0.5% !default;
$grid-margin-outer: 0 !default;

/**
   Calculate width of num of col
 */
@function col-width ($col) {
    @return (100% / $grid-cols * $col);
}
/**
   Calculate width of num of col without gutter
 */
@function col-remove-gutter($col) {
    @return col-width($col) - $grid-gutter;
}
/**
   Calculate width of num of col with gutter
 */
@function col-add-gutter($col) {
    @return col-width($col) + ($grid-gutter / 2);
}

@mixin g-row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 6rem;
    margin-left: ($grid-margin-outer / 2);
    margin-right: ($grid-margin-outer / 2);
}

@mixin g-col ($col: 0) {
    box-sizing: border-box;
    flex: 0 0 auto;
    flex-basis: 0;
    flex-grow: 1;
    margin: 0 ($grid-gutter / 2) $grid-gutter;
    max-width: 100%;

    @if $col != 0 {
        flex-basis: col-remove-gutter($col);
        max-width: col-remove-gutter($col);
    }
}


// Build Grid;
.g-row {
    @include g-row;
}

.g-col {
    @include g-col;

    &-offset-0 {
        margin-left: ($grid-margin-outer / 2);
    }

    @for $i from 1 through $grid-cols {
        &-#{$i} {
            flex-basis: col-remove-gutter($i);
            max-width: col-remove-gutter($i);
        }

        &-offset-#{$i} {
            margin-left: col-add-gutter($i);
        }
    }

    @each $name, $bp in $grid-breakpoints {
        @include min-screen($bp) {
            &-#{$name}-offset-0 {
                margin-left: ($grid-margin-outer / 2);
            }

            @for $i from 1 through $grid-cols {
                &-#{$name}-#{$i} {
                    flex-basis: col-remove-gutter($i);
                    max-width: col-remove-gutter($i);
                }

                &-#{$name}-offset-#{$i} {
                    margin-left: col-add-gutter($i);
                }
            }
        }
    }
}



