nav.navigation {
    align-items: center;
    display: none;
    flex: 1;
    height: 0;
    opacity: 0;
    padding: 0;
    width: 0;
    z-index: 1;
    @include default-transition($targets: (opacity, height));

    @include min-screen($screen__sm) {
        display: flex;
    }

    @include screen($screen__sm, $screen__xxl) {
        .header__content--collapsed & {
            flex: 0 1 48%;
            margin: 0;
            order: 1;
        }
    }

    ul {
        display: flex;
        margin-bottom: 0;
        padding: 0;

        a,
        li {
            align-self: center;
            color: $white;
            font-family: $font-family-sourcesanspro--semibold;
            font-size: 1.4rem;
            font-weight: 600;
            letter-spacing: 1.87px;
            margin-bottom: 0;
            margin-right: $indent__xxxs;
            padding: 0;
            text-transform: uppercase;

            @include min-screen($screen__sm) {
                align-self: flex-start;
            }

            @include screen($screen__sm, $screen__md) {
                font-size: 10px;
                margin-right: $indent__xxxxs;
            }
        }
    }

    .header__content--white & {
        ul {
            li {
                animation: 1s slide-right 0s forwards;
                opacity: 0;
                transform: translateX(-100%);
                will-change: transform, opacify;
            }

            a:not(.my-account__link),
            li {
                color: $white;
            }
        }

    }
}

@keyframes slide-right {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.section-item-content.nav-sections-item-content {
    display: none;

    @include min-screen($screen__sm) {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.menu-trigger__container {
    text-align: center;
}

.menu-trigger__text {
    color: $white;
    display: block;
    font-family: $font-family-sourcesanspro--semibold;
    font-size: 1rem;
    text-transform: uppercase;

    @include min-screen($screen__xxs) {
        letter-spacing: 1.24px;
    }

    @include min-screen($screen__sm) {
        display: inline-block;
        font-size: 1.4rem;
        letter-spacing: 1.87px;
        transform: translate($indent__xxs, -0.3rem);
    }

    @include screen($screen__sm, $screen__md) {
        font-size: 10px;
    }

    &:focus,
    &:visited,
    &:active,
    &:hover {
        @include min-screen($screen__sm) {
            color: $white;
            text-decoration: none;
        }
    }

    .default-header-page & {
        color: $black;
        text-decoration: none;
    }

    .header__content--white & {
        color: $white;
    }
}

@include screen($screen__sm, $screen__xxl) {
    .header__content--collapsed {
        .nav-home-link {
            display: none;
        }
    }
}
