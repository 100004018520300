.text-widget__container {
    margin: 0 auto $indent__xxl auto;
    max-width: 650px;
    text-align: center;
}

.text-widget__content {

    .heading {
        margin-bottom: 2rem;
    }

    @include min-screen($screen__md) {
        .heading {
            letter-spacing: 0.73px;
            margin-bottom: 1rem;
        }
    }

    @include max-screen($screen__md) {
        .heading {
            font-size: $font-size-h2;
            letter-spacing: 0;
            line-height: $line-height-h2;
            margin-bottom: unset;
        }

        .subheading {
            font-size: 1.9rem;
            line-height: 2.6rem;
            margin-top: 0.8rem;
        }
    }
}

.text-widget__description {
    margin-bottom: 3.875rem;
    margin-top: 2.1rem;

    @include max-screen($screen__xs) {
        p {
            margin-left: auto;
            margin-right: auto;
            max-width: 300px;
        }
    }

    @include max-screen($screen__md) {
        p {
            line-height: 2.4rem;
        }
    }

    @include min-screen($screen__md) {
        font-size: $font-size-paragraph--large;
        margin-top: $indent__md;
    }
}
