// GENERAL
$font-size__base: $font-size-unit-ratio * $font-size-ratio__base * 1px !default; // Base font size value in px
$font-size__normal: 1.6rem;
$font-size__small: 1.5rem;
$font-size__extrasmall: 1.2rem;
$font-size__large: 2.3rem;
$rfs-rem-value: 10;
$rfs-base-value: 1.8rem;

// Headings

$heading-weight: 400;

$font-size-page-title: 6rem;

$font-size-h1--hero: 12rem;
$line-height-h1--hero: 12rem;

$font-size-h1: 5.5rem;
$line-height-h1: 7rem;
$line-height-h1--mobile: 4rem;

$font-size-h2: 3.2rem;
$line-height-h2: 2.9rem;

$font-size-h3: 2.4rem;
$line-height-h3: 2.9rem;

$font-size-h4: 2.2rem;
$line-height-h4: 2.8rem;

$font-size-h5: 1.9rem;
$line-height-h5: 2.6rem;

$font-size-h6: 1.7rem;
$line-height-h6: 2.4rem;

// Paragraph, warnings, buttons
$font-size-notification: 1.5rem;
$font-size-paragraph--large: 1.8rem;
$font-size-paragraph--small: 1.5rem;

$line-height-paragraph: 2.7rem;
$font-size--button-small: 1rem;
$font-size--button: 1.5rem;
$line-height--button: 1.5rem;

$font-size--product-large: 2.9rem;
$font-size--product-large: 2.9rem;
