.backdrop {
    &:before {
        background: rgba(0, 0, 0, 0.6);
        content: "";
        height: 100%;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: 0.3s all;
        visibility: hidden;
        width: 100%;
        z-index: 5;

        @include max-screen($mobile-navigation-breakpoint) {
            display: none;
        }
    }
}

.backdrop--enabled {
    &:before {
        opacity: 1;
        visibility: visible;
    }
}
