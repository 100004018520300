$card-description__font-size: 1.9rem !default;

.card__container {
    display: flex;
    flex-direction: column;
    margin: 0 ( $grid-gutter / 2 );
    min-height: 100%;
    position: relative;
    width: 100%;

    &.card__container--image-top {
        flex-direction: column-reverse;
    }

    &[data-clickable-element] {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        cursor: pointer;
        @include default-transition($targets: (transform, filter, box-shadow), $duration: 0.5s);

        .card__image--background {
            @include default-transition($targets: (transform, filter, box-shadow), $duration: 0.5s);
        }

        &:focus,
        &:hover {
            transform: translateY(-5px);

            @include min-screen($screen__sm) {
                box-shadow: $default-dropshadow--hover;
            }

            .card__image--background {
                transform: scale(1.1);
            }

            [data-clickable-link]:not(.button-card-cta--view) {
                background: $button-ghost__background-color--hover;
                border: 1px solid $button-ghost__border-color--hover;
                color: $button-ghost__color;

                &:after {
                    filter: invert(1);
                }

                &:focus,
                &:hover {
                    transform: translateY(0);
                }
            }
        }
    }

    &:hover .card__content > svg {
        display: block;
    }
}

.card__content {
    background: $white;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $indent__xs;
    position: relative;
    text-align: center;

    & > svg {
        display: none;
        height: auto !important;
        position: absolute;
        right: 0;
        top: 2px; // correct hover movement
        transform: translate3d(0, -100%, 0) !important; // align to top
        z-index: 10;
    }


}

.card__title {
    font-size: 2.5rem;

    @include min-screen($screen__sm) {
        font-size: 3.2rem;
    }
}

.card__subtitle {
    display: block;
    font-family: $typografy-heading;
    font-size: 1.7rem;
    margin: 1.4rem 0 2.5rem;

    @include min-screen($screen__sm) {
        font-size: 1.9rem;
    }
}

.card__description {
    font-size: $font-size__normal;
    margin: 0 0 $indent__md;

    @include min-screen($screen__sm) {
        font-size: $card-description__font-size;
    }
}

.card__image {
    overflow: hidden;
    padding-top: (254 / 335 * 100%); // Sketch image size = 335 * 245;
    position: relative;

    @include min-screen($screen__sm) {
        padding-top: (345 / 456 * 100%); // Sketch image size = 456 * 345;
    }

    &.card__image-s-large {
        padding-top: (420 / 335 * 100%); // Sketch image size = 335 * 420;
        @include min-screen($screen__sm) {
            padding-top: (500 / 456 * 100%); // Sketch image size = 456 * 500;
        }
    }

    .card__container--image-top & {
        padding-top: (335 / 335 * 100%); // Sketch image size = 335 * 335 ;

        @include min-screen($screen__sm) {
            padding-top: (700 / 700 * 100%); // Sketch image size = 700 * 700;
        }

        &.card__image-s-large {
            padding-top: (254 / 335 * 100%); // Sketch image size = 335 * 245;
            @include min-screen($screen__sm) {
                padding-top: (345 / 456 * 100%); // Sketch image size = 456 * 345;
            }
        }
    }

    &:after {
        bottom: 0;
        box-shadow: inset 0 -30px 13px -30px rgba(56, 56, 56, 0.12);
        content: '';
        display: block;
        height: 10%;
        pointer-events: none;
        position: absolute;
        user-select: none;
        width: 100%;
    }
}

.card__button {
    margin: auto auto 0;
}

body:not(.checkout-cart-index) {
    .card__image--background {
        display: block;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;

        .products-wrapper--shop-the-look & {
            height: auto;
        }
    }
}

body:not(.checkout-cart-index).wishlist-index-index {
    .card__image--background {
        position: static;
    }

    .product-item {
        list-style: none;
    }

    .actions-toolbar {
        .primary {
            display: flex;
            flex-direction: column;

            @include min-screen($screen__md) {
                flex-direction: row;
            }

            > * {
                align-self: flex-start;
                margin-bottom: $indent__xxxs;

                &:not(:last-child) {
                    margin-right: $indent__xxs;
                }
            }
        }
    }
}

.card__attributes {
    display: flex;
    margin-top: auto;
}

.card__actions {
    display: flex;

    @include max-screen($screen__xs) {
        flex-direction: column;
    }
}

.product-item .card__actions.card__actions--grouped-product {
    border-top: 0;
    padding-bottom: 0;
}

.card__label {
    background-image: linear-gradient(136deg, #323232 0%, #1e1e1e 96%, #1e1e1e 96%);
    border-radius: 0.2rem 10rem 10rem 0.2rem;
    color: $white;
    font-family: $font-family-sourcesanspro;
    font-size: 1.9rem;
    left: -0.2rem;
    padding: 0 1.6rem 0 1rem;
    position: absolute;
    top: 1.5rem;
    width: auto;
    z-index: 4;
}
