.fotorama--fullscreen {
    z-index: 9999999 !important;
}

.gallery-placeholder {
    height: 100%;
}

.fotorama__thumb-border {
    border-color: $dark;
}

.fotorama__nav-wrap--vertical .fotorama__nav__shaft {
    background: transparent;
}

.fotorama__nav-wrap--vertical.fotorama__nav-wrap {
    height: 100%;

    @include min-screen($screen__sm) {
        height: calc(100% - #{$indent__xxs});
    }

    .fotorama_vertical_ratio .fotorama__img {
        position: static;
        transform: none;
        width: auto;
    }
}

.fotorama__nav--thumbs {
    height: 100%;
}

.fotorama__thumb {
    background-color: transparent;
    text-align: center;
}

.fotorama__stage__frame .fotorama__img {
    height: 100%;
    object-fit: contain;
    object-position: top;
    top: 0;
    transform: translate3d(-50%, 0, 0);
    width: 100%;

    @include min-screen($screen__sm) {
        .product.media & {
            top: $indent__xxs; // Fix margin-top?
        }
    }
}

.fotorama--fullscreen {
    .fotorama__nav-wrap--vertical.fotorama__nav-wrap {
        left: $indent__xxs;
    }
}

.fotorama__active .fotorama__dot {
    background-color: $dark;
    border-color: $dark;
}

@include min-screen($screen__sm) {
    .fotorama__stage__shaft {
        margin-left: $indent__xxxs;
    }
}
