.products-grid.wishlist {
    + .actions-toolbar {
        @include max-screen($screen__md) {
            flex-direction: column;
        }
    }

    .product-items {
        @include max-screen($screen__md) {
            padding-left: 0;
        }

        .product-item {
            &-photo {
                @include max-screen($screen__md) {
                    margin: 0 auto $indent__s;
                }
            }

            &-name {
                @include max-screen($screen__md) {
                    margin: 0;
                    text-align: center;
                }
            }

            &-actions {
                a.action {
                    @include max-screen($screen__md) {
                        display: block;
                        float: none;
                        margin-right: 0;
                        text-align: center;
                    }
                }
            }

            .price-box {
                @include max-screen($screen__md) {
                    margin: 0;
                    text-align: center;
                }
            }
        }
    }
}
