.logo {
    margin-top: 16px !important; // fix SVG positioning
}

.logo--small {
    max-width: 138px;
}

.logo--small-desktop {
    margin-bottom: $indent__md;

    @include max-screen($screen__lg) {
        display: none;
        height: 0;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 0;
    }
}

body:not(.has--category-header-image):not(.has--video-hero-widget):not(.navigation--open) {
    .header__content:not(.header__content--collapsed) {
        .logo {
            svg {
                path {
                    fill: #fff;
                    filter: invert(1);
                }
            }
        }
    }
}
