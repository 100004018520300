$color-summary-border: #d5d5d5;
$color-shipping-info-border: #e2e2e2;
$color-grey1: #515151;
$color-grey5: $color-grey1;

.checkout-container .opc-block-summary span.title {
    border-bottom: 1px solid $color-summary-border;
    margin-bottom: 1rem;
}

.abs-sidebar-totals .amount,
.cart-totals .amount,
.opc-block-summary .table-totals .amount {
    font-weight: 700;
}

.opc-block-summary .table-totals .grand.totals {
    th,
    td {
        font-weight: 700;
    }
}

.opc-block-summary .title {
    padding-right: 30px;
}

.opc-block-summary .items-in-cart .product {
    padding: $indent__xxxxs 0;
}

.opc-block-shipping-information .shipping-information-title {
    font-weight: 700;
}

@include min-screen($screen__m) {
    .opc-sidebar {
        margin-top: 2.6rem;
        width: 33%;
    }

    .checkout-container .opc-block-summary {
        padding: 3rem;
    }

    .opc-block-summary .table-totals {
        th.mark {
            padding-left: 0;
        }

        td.amount {
            padding-right: 0;
        }
    }

    .opc-block-summary .table-totals .grand.totals {
        th,
        td {
            font-size: 1.625rem;
        }
    }

    .opc-block-summary .items-in-cart > .title {
        margin-bottom: 2rem;

        strong {
            color: $color-grey1;
            font-weight: normal;
        }
    }

    // Shipping information
    .opc-block-shipping-information {
        padding: 0;

        .ship-to,
        .ship-via {
            border: 1px solid $color-shipping-info-border;
            margin-bottom: 2rem;
            padding: 3rem;

            .shipping-information-title {
                border-bottom: 0;
                font-weight: 700;
                margin-bottom: 1rem;
                padding-bottom: 0;
            }
        }
    }

    .opc-block-shipping-information .shipping-information-content {
        font-size: 1.5rem;
    }

}

@include max-screen($screen__m) {
    .checkout-index-index {
        .custom-slide {
            left: 2em !important; // sass-lint:disable-line no-important
        }

        .minicart-wrapper {
            //width: 50px;
            display: block;
            float: none;
            right: 0 !important; // sass-lint:disable-line no-important


            button {
                margin-bottom: 1rem;
                width: 100%;
            }
        }

        .checkout-container .opc-estimated-wrapper {
            padding-left: 0;
        }

        //.opc-estimated-wrapper .estimated-block {
        //    //margin-left: -5px;
        //}
    }
}

#opc-sidebar .table-totals tr.totals-tax { // sass-lint:disable-line no-ids
    th,
    td {
        color: #999;
    }
}

.checkout-container .action.showcart {
    background: $color-grey5;
}
