.block.filter {
    margin: 0;

    @include min-screen($screen__sm) {
        margin: $indent__sm 0;
    }

    .block-title.filter-title {
        @include min-screen($screen__sm) {
            background: transparent;
            border: 0;
            padding: 0;

            .icon.icon-close,
            .icon.icon-settings {
                display: none;

                .filter-active & {
                    display: none;
                }
            }
        }
    }

    .block-title.filter-title strong {
        width: 100%;
        @include caret-toggle();
    }

    &.active .block-title.filter-title,
    .block-title.filter-title {
        display: flex;
        flex-direction: row;

        @include min-screen($screen__sm) {
            display: none;
        }

        @include max-screen($screen__sm) {
            &:after {
                display: none;
            }
        }
    }
}

.block-subtitle.filter-subtitle {
    @include min-screen($screen__md) {
        display: none;
    }
}

.block-content.filter-content .filter-current-subtitle + .items {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: $indent__xs;
    padding-bottom: $indent__xxs;
}

.filter-options .filter-options-title {
    @include caret-toggle();
}

.block-content.filter-content .filter-current-subtitle + .items,
.filter-options .filter-options-content {
    border-bottom: 1px solid $border-color__base--light;

    @include min-screen($screen__sm) {
        margin-bottom: $indent__sm;
        padding-bottom: $indent__sm;
    }
}

.filter__title {
    display: flex;
    font-weight: normal;
    justify-content: space-between;
    margin-bottom: $indent__xxxs;
    position: relative;
    user-select: none;

    > h4 {
        margin: 0;

        @include min-screen($screen__m) {
            margin: inherit;
        }
    }

    &:hover {
        cursor: pointer;
    }

    &:after {
        color: $text__base-color;
        content: url("../images/svg/icon-chevron-up.svg");
        display: inline-block;
        position: absolute;
        right: 5px;
        speak: none;
        top: 6px;
        transform: rotate(180deg);
        transition: transform 0.225s cubic-bezier(0.23, 1, 0.32, 1.2);
        user-select: none;
        vertical-align: top;

        @include min-screen($screen__m) {
            right: -2px;
        }
    }

    &.active {
        &:after {
            transform: rotate(0deg);
            transition: transform 0.225s cubic-bezier(0.23, 1, 0.32, 1.2);
        }
    }
}

.block-subtitle.filter-subtitle {
    display: none;
}
