a {
    &:visited {
        color: $link__base-color;
    }
}

.alink {
    text-decoration: underline;

    &:visited {
        color: $link__base-color;
    }
}

a.no-underline {
    &:hover {
        text-decoration: none;
    }
}
