.page-layout-1column .page-main {
    padding-top: $indent__xs;

    @include min-screen($screen__sm) {
        max-width: unset;
        // Use Widget or wrapper
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
    }

    @include min-screen($screen__xxl) {
        padding-top: 9rem;
    }

    > div:not(.columns),
    .column.main > * {
        margin-left: auto;
        margin-right: auto;
        max-width: $page-container__max-width;
        padding-left: 0;
        padding-right: 0;

        @include min-screen($screen__sm) {
            padding-left: $indent__lg;
            padding-right: $indent__lg;
        }

        @include min-screen($screen__lg) {
            padding-left: $indent__xxl;
            padding-right: $indent__xxl;
        }
    }
}
