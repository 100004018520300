$defined-colors: (
    #000: $black,
    #fff: $white,
    light: $light,
    dark: $dark,
    gradient-blue: $gradient-blue,
    gradient-lilla: $gradient-lilla,
    gradient-brown: $gradient-brown,
    gradient-black: $gradient-black
) !default;

.bg {
    @each $name, $color in $defined-colors {
        &--#{$name} {
            background: $color;
        }
    }
}

[class*="bg--gradient-"] {
    background-blend-mode: multiply;
    background-repeat: repeat;
}
