.product-item {
    .card__title {
        font-size: $font-size-paragraph--large;
        margin-bottom: $indent__xxxxs;

        @include min-screen($screen__sm) {
            font-size: $font-size-h4;
        }
    }

    .card__image {
        background: #fdfdfd;
        padding-top: 80%;
    }

    .card__image--background {
        object-fit: contain;
        object-position: center;
    }

    .card__content {
        padding-bottom: $indent__xs;
        text-align: left;
        @include max-screen($screen__xs) {
            > .nohover {
                margin-bottom: 0.7rem;
            }
        }
    }

    &[data-clickable-element]:hover {
        .card__title:after {
            @include icon('caret-right-black');
            margin-left: $indent__xxxs;
        }
    }

    .product__sku {
        margin-bottom: $indent__xs;
    }

    .button {
        margin-left: auto;
    }

    .towishlist {
        position: absolute;
        right: 4px;
        top: 4px;
        z-index: 3;
    }

    .product__sku,
    .product__custom-quantity,
    .product__custom-stock {
        color: $dark;
        font-size: $font-size__normal;
    }

    .product__custom-stock {
        text-align: center;
        width: 100%;
    }

    .card__actions {
        margin-top: auto;
        padding-bottom: $indent__xxxs;
        padding-top: $indent__xxxs;
    }

    .card__attributes {
        margin-top: 0;
        padding-top: $indent__xxxs;
    }

    .card__actions,
    .card__attributes {
        border-top: 1px $grey solid;
    }

    .card__cta {
        margin-left: auto;
        margin-top: auto;
    }

    .stock-message {
        @include lib-message-icon-inner(error);

        @include min-screen($screen__sm) {
            margin-bottom: $indent__xs;
        }
    }
}
