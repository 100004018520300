.field-tooltip {
    .field-tooltip-action:before {
        color: $black;
    }

    .field-tooltip-content {
        background: #f5f3f2;
        border-radius: 20px;
        left: 80px;
        top: -19px;

        &:before,
        &:after {
            top: 20px;
        }
    }
}
