// sass-lint:disable indentation
%svg-common {
	background: url("svg/sprite.css.svg") no-repeat;
}

.icon-ambiance-meter {
	@extend %svg-common;
	background-position: 0 100%;
}

.icon-ambiance-meter-dims {
	width: 15px;
	height: 14px;
}

.icon-arrow-down-black {
	@extend %svg-common;
	background-position: 100% 49.6551724137931%;
}

.icon-arrow-down-black-dims {
	width: 9px;
	height: 4px;
}

.icon-arrow-down-grey {
	@extend %svg-common;
	background-position: 100% 52.41379310344828%;
}

.icon-arrow-down-grey-dims {
	width: 9px;
	height: 4px;
}

.icon-arrow-up-grey {
	@extend %svg-common;
	background-position: 96.22641509433963% 56.94444444444444%;
}

.icon-arrow-up-grey-dims {
	width: 9px;
	height: 5px;
}

.icon-caret-down-black {
	@extend %svg-common;
	background-position: 80.26315789473684% 34.53237410071942%;
}

.icon-caret-down-black-dims {
	width: 16px;
	height: 10px;
}

.icon-caret-down-white {
	@extend %svg-common;
	background-position: 22.36842105263158% 84.89208633093526%;
}

.icon-caret-down-white-dims {
	width: 16px;
	height: 10px;
}

.icon-caret-left-black {
	@extend %svg-common;
	background-position: 98.76543209876543% 21.58273381294964%;
}

.icon-caret-left-black-dims {
	width: 6px;
	height: 10px;
}

.icon-caret-left-white {
	@extend %svg-common;
	background-position: 94.44444444444444% 44.60431654676259%;
}

.icon-caret-left-white-dims {
	width: 6px;
	height: 10px;
}

.icon-caret-right-black {
	@extend %svg-common;
	background-position: 98.14814814814815% 44.60431654676259%;
}

.icon-caret-right-black-dims {
	width: 6px;
	height: 10px;
}

.icon-caret-right-white {
	@extend %svg-common;
	background-position: 94.44444444444444% 51.798561151079134%;
}

.icon-caret-right-white-dims {
	width: 6px;
	height: 10px;
}

.icon-caret-up-black {
	@extend %svg-common;
	background-position: 32.89473684210526% 84.89208633093526%;
}

.icon-caret-up-black-dims {
	width: 16px;
	height: 10px;
}

.icon-caret-up-grey {
	@extend %svg-common;
	background-position: 43.421052631578945% 84.89208633093526%;
}

.icon-caret-up-grey-dims {
	width: 16px;
	height: 10px;
}

.icon-cart-white {
	@extend %svg-common;
	background-position: 84.13793103448276% 51.93798449612403%;
}

.icon-cart-white-dims {
	width: 23px;
	height: 20px;
}

.icon-check-black {
	@extend %svg-common;
	background-position: 97.45222929936305% 31.428571428571427%;
}

.icon-check-black-dims {
	width: 11px;
	height: 9px;
}

.icon-check-white {
	@extend %svg-common;
	background-position: 97.45222929936305% 37.857142857142854%;
}

.icon-check-white-dims {
	width: 11px;
	height: 9px;
}

.icon-close {
	@extend %svg-common;
	background-position: 34.72222222222222% 36.92307692307692%;
}

.icon-close-dims {
	width: 24px;
	height: 19px;
}

.icon-close-black {
	@extend %svg-common;
	background-position: 51.388888888888886% 36.92307692307692%;
}

.icon-close-black-dims {
	width: 24px;
	height: 19px;
}

.icon-facebook-white {
	@extend %svg-common;
	background-position: 95.03105590062111% 22.22222222222222%;
}

.icon-facebook-white-dims {
	width: 7px;
	height: 14px;
}

.icon-hamburger {
	@extend %svg-common;
	background-position: 68.05555555555556% 35.55555555555556%;
}

.icon-hamburger-dims {
	width: 24px;
	height: 14px;
}

.icon-heart-grey {
	@extend %svg-common;
	background-position: 0 75.96899224806202%;
}

.icon-heart-grey-dims {
	width: 23px;
	height: 20px;
}

.icon-heart-white {
	@extend %svg-common;
	background-position: 15.862068965517242% 75.96899224806202%;
}

.icon-heart-white-dims {
	width: 23px;
	height: 20px;
}

.icon-instagram-white {
	@extend %svg-common;
	background-position: 100% 0;
}

.icon-instagram-white-dims {
	width: 15px;
	height: 15px;
}

.icon-linkedin-white {
	@extend %svg-common;
	background-position: 100% 11.194029850746269%;
}

.icon-linkedin-white-dims {
	width: 15px;
	height: 15px;
}

.icon-loading {
	@extend %svg-common;
	background-position: 31.08108108108108% 75.96899224806202%;
}

.icon-loading-dims {
	width: 20px;
	height: 20px;
}

.icon-magnifier-black {
	@extend %svg-common;
	background-position: 44.5945945945946% 75.96899224806202%;
}

.icon-magnifier-black-dims {
	width: 20px;
	height: 20px;
}

.icon-magnifier-white {
	@extend %svg-common;
	background-position: 58.108108108108105% 75.96899224806202%;
}

.icon-magnifier-white-dims {
	width: 20px;
	height: 20px;
}

.icon-minus-black {
	@extend %svg-common;
	background-position: 34.72222222222222% 53.6%;
}

.icon-minus-black-dims {
	width: 24px;
	height: 24px;
}

.icon-pinterest-white {
	@extend %svg-common;
	background-position: 80.79470198675497% 74.24242424242425%;
}

.icon-pinterest-white-dims {
	width: 17px;
	height: 17px;
}

.icon-play-button-white {
	@extend %svg-common;
	background-position: 0 48.484848484848484%;
}

.icon-play-button-white-dims {
	width: 50px;
	height: 50px;
}

.icon-plus-black {
	@extend %svg-common;
	background-position: 51.388888888888886% 53.6%;
}

.icon-plus-black-dims {
	width: 24px;
	height: 24px;
}

.icon-silk-ka-logo-white {
	@extend %svg-common;
	background-position: 0 0;
}

.icon-silk-ka-logo-white-dims {
	width: 153px;
	height: 48px;
}

.icon-trash {
	@extend %svg-common;
	background-position: 68.05555555555556% 53.6%;
}

.icon-trash-dims {
	width: 24px;
	height: 24px;
}

.icon-twitter-white {
	@extend %svg-common;
	background-position: 0 89.39393939393939%;
}

.icon-twitter-white-dims {
	width: 17px;
	height: 17px;
}

.icon-user-white {
	@extend %svg-common;
	background-position: 69.73684210526316% 75.96899224806202%;
}

.icon-user-white-dims {
	width: 16px;
	height: 20px;
}

.icon-youtube-white {
	@extend %svg-common;
	background-position: 11.258278145695364% 89.39393939393939%;
}

.icon-youtube-white-dims {
	width: 17px;
	height: 17px;
}

