.lac-notification {
    background: rgba(251, 213, 211, 0.7);
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 10;

    .top-container {
        padding: 1rem 120px 1rem 1rem;
    }

    .lac-notification-icon {
        display: none;
    }

    .lac-notification-links {
        align-items: center;
        bottom: 0;
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
    }

    .lac-notification-close-link {
        background: rgba(255, 188, 185, 0.7);
        display: block;
        padding: 1rem;
        text-align: justify;
        vertical-align: text-top;
    }
}
