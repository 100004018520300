@include max-screen($screen__sm) {
    .masonryGallery--desktop {
        display: none;
    }
}

@include min-screen($screen__sm) {
    .masonryGallery--mobile-button {
        display: none;
    }
}
