/*! Flickity v2.2.1
https://flickity.metafizzy.co
// sass-lint:disable space-around-operator leading-zero no-vendor-prefixes
---------------------------------------------- */

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    height: 100%;
    overflow: hidden;
    position: relative;
}

.flickity-slider {
    height: 100%;
    position: absolute;
    width: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
    background: hsla(0, 0%, 100%, 0.75);
    border: 0;
    color: #333;
    position: absolute;
}

.flickity-button:hover {
    background: #fff;
    cursor: pointer;
}

.flickity-button:focus {
    box-shadow: 0 0 0 5px #19f;
    outline: none;
}

.flickity-button:active {
    opacity: 0.6;
}

.flickity-button:disabled {
    cursor: auto;
    opacity: 0.3;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
}

.flickity-button-icon {
    fill: currentColor;
}

/* ---- page dots ---- */

.flickity-page-dots {
    bottom: -25px;
    line-height: 1;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;
}

.flickity-rtl .flickity-page-dots {
    direction: rtl;
}

.flickity-page-dots .dot {
    background: #333;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 10px;
    margin: 0 8px;
    opacity: 0.25;
    width: 10px;
}

.flickity-page-dots .dot.is-selected {
    opacity: 1;
}

/* flickity-fade */

.flickity-enabled.is-fade .flickity-slider > * {
    pointer-events: none;
    z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
    pointer-events: auto;
    z-index: 1;
}
