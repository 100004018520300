$typografy-heading: $font-family-playfairdisplay--regular;

html {
    font-size: 50%;

    @include min-screen($screen__xxs) {
        font-size: 62.5%;
    }
}

body {
    color: $text__base-color;
    font-family: $font-family-sourcesanspro--light;
    font-size: $font-size__normal;
    font-weight: normal;
    line-height: $line-height__base;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    display: block;
    font-family: $font-playfairdisplay--regular, Helvetica, Arial, sans-serif;
    font-weight: $heading-weight;
    margin-bottom: 0;
    margin-top: 0;
    position: relative;

    > strong {
        font-weight: inherit;
    }
}

h1,
.h1 {
    @include font-size($font-size-h1);
    line-height: $line-height-h1--mobile;

    @include min-screen($screen__sm) {
        line-height: $line-height-h1;
    }

    &.h1--jumbo-heading {
        font-size: clamp(7rem, 7vw, 12rem);
        letter-spacing: -1.71px;
        line-height: clamp(7rem, 7vw, 12rem);
    }

    &.h1--giganto-heading {
        font-size: clamp(7rem, 11vw, 16rem);
        letter-spacing: -1.71px;
        line-height: clamp(7rem, 10vw, 16rem);
    }
}

h2,
.h2 {
    @include font-size($font-size-h2);
    line-height: $line-height-h2;
}

h3,
.h3 {
    @include font-size($font-size-h3);
    letter-spacing: 0.43px;
    line-height: $line-height-h3;
}

h4,
.h4 {
    @include font-size($font-size-h4);
    line-height: $line-height-h4;
}

h5,
.h5 {
    @include font-size($font-size-h5);
    line-height: $line-height-h5;
}

h6,
.h6 {
    @include font-size($font-size-h6);
    line-height: $line-height-h6;
}

p {
    line-height: $line-height-paragraph;
    margin-bottom: $indent__xxs;

    &.text-small {
        @include font-size($font-size-paragraph--small);
    }

    &.text-large {
        @include font-size($font-size-paragraph--large);
    }

    a {
        text-decoration: underline;
    }
}

strong {
    font-weight: bold;
}

a {
    color: $link__base-color;

    &:hover {
        color: $text__base-color;
    }
}

.page-title-wrapper {
    text-align: center;

    > h1 {
        margin-bottom: $indent__sm;
        margin-top: $indent__sm;
    }
}

