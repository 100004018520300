.header__content {
    .action.toggle {
        user-select: none;
    }
}

.has--category-header-image,
.has--video-hero-widget {
    .header__content {
        .action.toggle {
            filter: invert(1);
        }
    }
}

.page-header .switcher .options.active ul.dropdown {
    border: 0;
    min-width: $indent__lg !important;
    right: -5px;
    text-align: center;

    @include max-screen($screen__sm) {
        &:before {
            top: -12px;
        }

        &:after {
            display: none;
        }
    }

    @include min-screen($screen__sm) {
        top: 120%;
    }
}

.page-header .switcher {
    display: none;
    margin-right: 0;

    @include min-screen($screen__sm) {
        display: inline-block;
    }

    strong {
        font-family: $font-family-sourcesanspro--semibold;
        font-size: 1.4rem;
        font-weight: 600;
        letter-spacing: 1.87px;
    }
}

.page-header .switcher .options ul.dropdown,
.page-footer .switcher .options ul.dropdown {
    flex-flow: column;
    min-width: 10rem;
    right: 0;

    &:before {
        left: auto;
        right: 0.4rem;
    }

    &:after {
        left: auto;
        right: 0.3rem;
    }
}

.options .action.toggle {
    &:after {
        @include icon('arrow-down-black');
        margin-top: 1rem !important; // specific as per design
    }
}
