@import 'keyframes';

@mixin animate-once {
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

@mixin animate-infinitely($duration: 1s) {
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@mixin animate-once-cubic-ease($duration: 0.2s) {
    animation-duration: $duration;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    @include animate-once();
}

@mixin animation__animate-scale-in {
    animation: animate-scale-in;
    transform: scale(0);
    transform-origin: center;
    @include animate-once-cubic-ease($duration: 0.5s);
}

@mixin animation__animate-in {
    animation: animate-in;
    opacity: 0;
    @include animate-once-cubic-ease($duration: 0.5s);
}

@mixin animation__animate-fly-in-from-left {
    animation: animate-fly-in-from-left;
    transform: translateX(-50%);
    @include animate-once-cubic-ease($duration: 0.5s);
}

@mixin animation__animate-fly-in-from-top {
    animation: animate-fly-in-from-top;
    transform: translateY(-80%);
    @include animate-once-cubic-ease($duration: 0.2s);
}

@mixin animation--delay($delay: 0.1s) {
    animation-delay: $delay;
}

@mixin animation__animate-rotate($duration) {
    animation: animate-rotate;
    transform: rotate(0deg);
    @include animate-infinitely($duration);
}

.animate-scale-in {
    @include animation__animate-scale-in();

    > * {
        @include animation__animate-scale-in();
    }
}

.animate-in {
    @include animation__animate-in();

    > * {
        @include animation__animate-in();
    }
}

.animate-fly-in-from-left {
    @include animation__animate-fly-in-from-left();
}

.animate-fly-in-from-top {
    @include animation__animate-fly-in-from-top();

    > * {
        @include animation__animate-fly-in-from-top();
    }
}

@keyframes circle-spinner {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@for $i from 1 through 10 {
    .animate-in--#{$i}00 {

        @include animation--delay($delay: #{'.'}#{$i}s);

        > * {
            @include animation--delay($delay: #{'.'}#{$i}s);
        }
    }
}
