.column.main {
    iframe {
        width: 100% !important; // overwrite iframe specific styling

        @include min-screen($screen__sm) {
            width: calc(100% - 8rem) !important; // overwrite iframe specific styling
        }

        @include min-screen($screen__lg) {
            width: calc(100% - 16rem) !important; // overwrite iframe specific styling
        }
    }
}
