.qty-incrementer {
    align-items: center;
    display: flex;
    margin-bottom: $indent__xxs;

    .field {
        position: relative;
        width: 9rem;
    }

    &:after {
        content: attr(data-after);
        font-family: $font-family-sourcesanspro;
        font-size: $font-size-paragraph--large;
        margin-left: $indent__xxs;
        margin-right: $indent__xs;
    }

    .label {
        display: none;
    }

    .button__increment,
    .button__decrement {
        align-items: center;
        background: none;
        border: 0;
        box-shadow: unset;
        box-sizing: border-box;
        color: $black;
        display: inline-flex;
        font-size: 1.5rem;
        height: auto;
        line-height: 1.9rem;
        padding: 1.6rem;
        position: absolute;
        right: 0;
        text-decoration: none;
        text-transform: uppercase;
        vertical-align: top;
        white-space: nowrap;

        &:after {
            display: none;
        }

        &:hover,
        &:active,
        &:focus {
            transform: none;
        }
    }

    .button__increment {
        padding-bottom: 0.3rem;
        top: 0;
    }

    .button__decrement {
        bottom: 0;
        padding-top: 0.3rem;
    }
}

.box-tocart .fieldset {
    display: flex;
    position: relative;
    @include min-screen($screen__sm) {
        flex-direction: column;
    }

    @include min-screen($screen__md) {
        flex-direction: row;
    }
}
