$default-transition-function: cubic-bezier(0.23, 1, 0.32, 1);
$default-duration: 0.3s;

/**
 * e.g. @include default-transition($targets: (top, width), $duration: 0.2s, $transition-function: ease-out);
 *      @include default-transition($targets: (height), $duration: 1s);
 */
@mixin default-transition($targets: (all), $duration: $default-duration, $transition-function: $default-transition-function) {
    $transitions: ();
    $will-change: ();

    @each $target in $targets {
        $transitions: append($transitions, ($target $duration $transition-function), $separator: comma);

        @if not $target == all {
            $will-change: append($will-change, $target, $separator: comma);
        }
    }

    transition: $transitions;
    will-change: $will-change;
}
