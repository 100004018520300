.widget-imagetext {
    @include max-screen($screen__sm) {
        margin-bottom: $indent__xl;
    }

    .g-col {
        align-self: center;
    }
}

.widget-imagetext--reverse {
    @include min-screen($screen__sm) {
        flex-direction: row-reverse;

        .g-col:first-of-type {
            text-align: right;
        }
    }
}
