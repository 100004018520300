:root {
    --plyr-video-controls-background: transparent;
    --plyr-control-padding: var(--plyr-control-spacing) * 1;
}

.video-widget {
    position: relative;
}

.video-widget__description {
    margin: $indent__xs 0;
    font-size: $rfs-base-value;

    p {
        font-size: inherit;
    }
}


.video-widget__container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $indent__xxxl;

    > * {
        flex-grow: 1;
    }

    @include min-screen($screen__md) {
        justify-content: center;
    }
}

.plyr__video-embed iframe {
    transform: scale(1.1);
}

button.plyr__control.plyr__control--overlaid {
    width: $indent__xxl;
    height: $indent__xxl;
    max-height: unset;

    > svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:after {
        display: none;
    }
}

.video-widget__player {
    flex: 1 0 100%;
    margin-right: $indent__sm;
    position: relative;

    .plyr__control--overlaid {
        padding: 38px 29px;

        svg {
            top: -7px;
        }
    }

    #player { // sass-lint:disable-line no-ids
        height: 100%;
        will-change: auto;
        position: relative;

        > iframe {
            width: 100%;
            height: 100%;
        }
    }

    @include max-screen($screen__md) {
        margin-bottom: 2rem;
    }

    @include min-screen($screen__md) {
        flex: 1 1 56.5%;
    }
}

.video-widget__modal {
    height: 100%;
    background: rgba(31, 31, 31, 0.4);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
    margin: 0 $indent__xxl;
    border: 0;

    iframe {
        height: 100%;
        width: 100%;
        border: 0;
        position: relative;
        z-index: 2;
    }
}

.video-widget__content {
    position: relative;

    @include min-screen($screen__md) {
        margin-left: $indent__sm;
    }

    .appointment-wrapper {
        display: none;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: none;
    }

    .close-button {
        height: $indent__md;
        width: $indent__md;
        border: solid 1px #f00;
        z-index: 2;
        position: absolute;
        left: 0;
        top: 0;
    }

    text-align: center;
    background-color: $white;
    padding: $indent__xl $indent__sm;

    @include min-screen($screen__md) {
        flex: 0 1 29.5%;
    }

    .button {
        border: 0;
    }

    .video-widget__disclaimer {
        color: $dark;
        margin-top: 4rem;
    }

    .text-small {
        line-height: $font-size-paragraph--large;
    }
}

.content__title {
    margin-bottom: $indent__xxs;
}
