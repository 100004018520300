$padding-column: 1.4rem 0 !default;
$padding-row: 0 2.8rem !default;
$border: solid 0.1rem #cfcfcf;

.catalog-product-view {
    &.page-layout-1column .page-main {
        padding-top: $indent__xs;
    }

    .breadcrumb-wrapper {
        @include min-screen($screen__sm) {
            display: flex;
        }

        .button--back-button + .breadcrumbs {
            @include min-screen($screen__sm) {
                align-self: center;
                display: flex;
                margin: 0 0 0 $indent__xxs;
            }
        }
    }

    .page-title {
        @include min-screen($screen__sm) {
            line-height: $font-size-h1;
        }
    }

    .product.media {
        @include max-screen($screen__sm) {
            margin-bottom: $indent__xxs;
        }

        @include min-screen($screen__sm) {
            min-height: 50vh;
        }

        @include min-screen($screen__xl) {
            min-height: 70vh;
        }
    }

    .product-info-price {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $indent__xxxs;
        position: relative;

        @include min-screen($screen__sm) {
            margin-bottom: 4rem;
        }

        .price-final_price {
            font-family: $font-family-sourcesanspro--semibold;
            font-size: $font-size--product-large;
            font-weight: 600;
        }

        .price-container:after {
            font-family: $font-family-sourcesanspro;
            font-size: $font-size-paragraph--large;
        }
    }

    .stock-message {
        @include lib-message-icon-inner(error);

        @include min-screen($screen__sm) {
            margin-bottom: $indent__xs;
        }
    }

    .price-container {
        &:after {
            color: $black;
        }
    }

    .qty-incrementer {
        flex-direction: column;
        padding-right: $indent__xxxxs;

        @include min-screen($screen__sm) {
            flex-direction: row;
        }

        @include min-screen($screen__md) {
            flex-direction: column;
        }
        @include min-screen($screen__lg) {
            flex-direction: row;
        }
    }

    button.tocart {
        height: auto;
        white-space: normal;

        @include min-screen($screen__md) {
            white-space: nowrap;
        }
    }

    .product-add-form {
        .product-info__qty-label {
            margin-top: $indent__xxxxs;
            text-align: center;

            @include min-screen($screen__sm) {
                text-align: left;
            }

            @include min-screen($screen__md) {
                text-align: center;
            }

            @include min-screen($screen__lg) {
                display: none;
            }
        }

        + .product-info__qty-wrapper {
            display: none;

            @include min-screen($screen__lg) {
                display: block;
            }
        }
    }
}

.product-page__top {
    display: flex;
    flex-direction: column;
    margin-bottom: $indent__sm;

    @include min-screen($screen__sm) {
        flex-direction: row;
    }

    .product-info-main {
        width: 100%;

        @include min-screen($screen__sm) {
            padding: $indent__sm;
            @include g-col(5);
        }
    }

    .product.media {
        width: 100%;

        @include min-screen($screen__sm) {
            padding: $indent__sm 0;
            @include g-col(7);
        }
    }

    .page-title {
        margin-top: 0;
        text-align: left;

        @include max-screen($screen__sm) {
            margin-bottom: $indent__xxxs;
        }
    }
}

.page-product-grouped {
    .product-info-price {
        margin-bottom: $indent__xs;
    }

    .product-info__price .price-container {
        &:after {
            display: none;
        }
    }
}

.product-page__top--stock-price {
    border: $border;
    display: flex;
    flex-direction: column;
    margin-top: $indent__xs;
    padding: $padding-row;

    @include min-screen($screen__xs) {
        flex-direction: row;
        padding: $padding-column;
    }

    @include min-screen($screen__sm) {
        flex-direction: column;
        padding: $padding-row;
    }

    @include min-screen($screen__lg) {
        flex-direction: row;
        margin-top: $indent__lg;
        padding: $padding-column;
    }

    .page-product-grouped & {
        display: none;
    }
}

.productgroup-placeholder-tierprice {
    border-top: $border;
    flex: 0 1 auto;
    min-width: 50%;
    padding: $padding-column;

    @include min-screen($screen__xs) {
        border-left: $border;
        border-top: 0;
        padding: $padding-row;
    }

    @include min-screen($screen__sm) {
        border-left: 0;
        border-top: $border;
        padding: $padding-column;
    }

    @include min-screen($screen__lg) {
        border-left: $border;
        border-top: 0;
        padding: $padding-row;
    }
}

.product-info__stock {
    flex: 0 1 auto;
    min-width: 42%;
    padding: $padding-column;

    @include min-screen($screen__xs) {
        padding: $padding-row;
    }

    @include min-screen($screen__sm) {
        border-left: 0;
        padding: $padding-column;
    }

    @include min-screen($screen__lg) {
        padding: $padding-row;
    }

    > div + div {
        margin-top: 0.3rem;
    }
}

.product-info__stock--title,
.prices-tier--title {
    margin-bottom: $indent__xxxxs;

    strong {
        font-family: $font-family-sourcesanspro;
        font-weight: normal;
    }
}

.stock--available,
.stock--next-week {
    &:before {
        @include icon('check-black');
    }
}

.stock--unavailable {
    &:before {
        content: '\2715';
    }
}

.stock--available,
.stock--next-week,
.stock--unavailable {
    &:before {
        margin-right: $indent__xxxs;
    }
}

.stock--next-week {
    color: $dark;
}

.productgroup-placeholder-tierprice {
    display: none;

    &.visible {
        display: block;
    }
}

.prices-tier .item {
    color: $dark;
    font-family: $font-family-sourcesanspro;
    margin-top: 0.3rem;

    .price-container {
        color: $black;
        font-size: $font-size__normal;
        font-weight: normal;
    }
}

.productgroup-placeholder-tierprice .price-container:after {
    content: '';
}

.product.attribute.sku {
    display: flex;
    font-family: $font-family-sourcesanspro--light;
    margin-bottom: $indent__xs;

    @include min-screen($screen__sm) {
        margin-top: -$indent__xxxs;
    }

    strong {
        font-weight: normal;
        margin-right: $indent__xxxs;
    }
}
