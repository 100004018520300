.ie11 {
    .page-layout-events-page {
        @include min-screen($screen__xl) {
            .column.main {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .event-widget {
                justify-self: unset;
                width: 48%;
            }

            .event-widget.intro {
                order: 0;
            }

            .event-widget-card {
                margin-bottom: unset;

                &:nth-of-type(2n+2) {
                    transform: unset !important;
                }

                &:last-of-type {
                    padding-bottom: unset;
                }
            }
        }
    }
}

.page-layout-events-page {
    overflow-x: hidden;

    .plyr--video {
        @include max-screen($screen__s) {
            margin-bottom: 5rem;
            transform: scale(1.5, 1.5);
        }

        @include min-screen($screen__xl) {
            margin-bottom: 5rem;
        }
    }

    .column.main {
        .pagebuilder-column {
            [data-content-type="text"] {
                @include max-screen($screen__xl) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .event-widget {
                        flex: 1 1 45%;
                        margin-right: 3.4%;
                        margin-top: 4rem;
                    }

                    .widget--centered {
                        order: 1;
                    }
                }

                @include max-screen($screen__xs) {
                    .event-widget__button {
                        .button {
                            width: 100%;

                            span {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 250px;
                            }
                        }
                    }
                }

                @include min-screen($screen__md) {
                    margin-top: 5rem;
                }

                @include min-screen($screen__xl) {
                    display: grid;
                    gap: 3.4%;
                    grid-auto-columns: max-content;
                    grid-template-columns: 1fr 1fr;
                    .event-widget {
                        &:not(:nth-of-type(2n+2)) {
                            justify-self: right;
                        }
                    }
                }

                @include min-screen($screen__xxl) {
                    gap: 6.4%;

                    .event-widget.intro {
                        justify-self: center;
                        width: 92%;
                    }
                }
            }
        }
    }

    .event-video-header {
        position: relative;

        .scroll-down {
            @include max-screen($screen__md) {
                display: none;
            }
        }

        .plyr iframe {
            @include max-screen($screen__md) {
                transform: scale(2.5, 4);
            }

            @include min-screen($screen__md) {
                transform: scale(1, 1);
            }
        }

        @include max-screen($screen__md) {
            .plyr iframe {
                height: auto;
            }
        }
    }


    .event-widget__title {
        margin-bottom: 2rem;
        margin-top: 2rem;

        .intro-heading {
            margin-bottom: 40rem;
            order: 0;
        }
    }


    .event-widget {
        margin-bottom: 4rem;
    }


    @include min-screen($screen__xl) {


        .event-widget-card {
            &:nth-of-type(2n+2) {
                margin-bottom: 6rem;
                transform: translateY(-50%);
            }

            &:last-of-type {
                padding-bottom: 10rem;
            }
        }
    }


    @keyframes scroll-down-top {
        0% {
            opacity: 0;
            transform: translate(-50%);
        }

        30% {
            opacity: 1;
        }

        to {
            opacity: 0;
            transform: translate(-50%, 22px);
        }
    }

    @keyframes scroll-down-bottom {
        0% {
            opacity: 0;
            top: 42px;
        }

        50% {
            opacity: 1;
        }

        to {
            opacity: 0;
            top: 64px;
        }
    }
}
