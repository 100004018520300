.img--rounded-edges {
    border-radius: 20px;
}

.am-fileicon {
    margin-right: 1rem;
    max-height: 2.5rem;
}

img.lazy {
    max-width: 20px;
    width: 20px;
}

img.lazy-loading {
    opacity: 0;
    transition: 0.5s opacity cubic-bezier(0.32, 1, 0.23, 1);
}

img.lazy-loaded {
    opacity: 1;
}
