@keyframes animate-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes animate-scale-in {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes animate-fly-in-from-left {
    0% {
        opacity: 0;
        transform: translateX(-80%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes animate-fly-in-from-top {
    0% {
        transform: translateY(-80%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes animate-rotate {
    100% {
        transform: rotate(360deg);
    }
}
