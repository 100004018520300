.page-product-grouped {
    .product-info-main > p {
        @include min-screen($screen__lg) {
            max-width: 500px;
        }
    }

    .product-info-stock-sku {
        .stock {
            display: none;
        }
    }

    .product-page__top {
        .product-info-main {
            padding: 0;
        }
    }

    .product-page__top {
        .product-info-main {
            @include min-screen($screen__xxl) {
                max-width: 50%;
            }
        }
    }

    .sticky-gallery {
        position: fixed !important;
        top: $indent__xxl !important;
    }

    .gallery-placeholder {
        position: relative;
        top: 0;

        @include min-screen($screen__xl) {
            margin-right: auto;
            max-width: 730px;
        }
    }

    .product-add-form form {
        display: flex;
        flex-direction: column;
    }

    .breadcrumb-wrapper {
        margin-top: $indent__xxxxs;

        @include min-screen($screen__sm) {
            margin-bottom: $indent__xs;
            margin-top: 0;
        }
    }

    .product.attribute.sku {
        display: flex;
        margin-bottom: $indent__xs;

        strong {
            margin-right: $indent__xxxxs;
        }
    }

    .box-tocart {
        margin-top: $indent__xxxs;
    }

    .addStep,
    .removeStep {
        @include max-screen($screen__sm) {
            display: none;
        }
    }
}

.grouped-product__container {
    margin-top: $indent__xs;
    padding-top: $indent__xs;
}

.grouped-product__data {
    padding: 0;

    @include max-screen($screen__sm) {
        display: grid;
        grid-column-gap: $indent__xxxs;
        grid-template-columns: 1fr 1fr;
    }
}

.grouped-product__title {
    margin-bottom: $indent__xs;
}

.grouped-product__price {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.productgroup-placeholder-price {
    margin-right: $indent__xxxxs;
}

.grouped-product__content-container,
.grouped-product__media-container {
    width: 100%;

    @include min-screen($screen__sm) {
        min-height: 100%;
    }

    @include min-screen($screen__lg) {
        width: 50%;
    }
}

.grouped-product__content-container {
    display: flex;
    flex-direction: column;
    margin-top: $indent__xxxs;

    @include min-screen($screen__sm) {
        padding: $indent__xs $indent__xs $indent__xs 0;
    }

    @include min-screen($screen__lg) {
        padding: $indent__xxxxs 0 0 $indent__xs;
    }

    @include min-screen($screen__xl) {
        width: 60%;
    }

    > *:not(:last-child) {
        margin-bottom: 0.3rem; // specific
    }
}

.grouped-product__media-container {
    cursor: pointer;
    margin-bottom: $indent__xxxxs;
    position: relative;

    @include min-screen($screen__sm) {
        margin-bottom: 0;
    }

    @include min-screen($screen__xl) {
        width: 200px;
    }
}

.grouped-product {
    display: flex;
    flex-direction: column;
    list-style: none;
    position: relative;

    @include min-screen($screen__lg) {
        flex-direction: row;
        margin-bottom: $indent__xxs;
    }
}

.grouped-product__control {
    display: flex;
    flex-direction: column;
    position: relative;

    @include min-screen($screen__sm) {
        flex-direction: row;
        margin-top: auto;
    }

    .control.qty {
        margin-right: $indent__xxxs;
        max-width: $indent__xl;

        input[type="number"] {
            padding: $indent__xxxs;
            text-align: center;
        }
    }
}

.grouped-product__qty-label {
    align-items: center;
    display: flex;
    flex: 66%;
    justify-content: flex-end;
    text-align: right;
}

.grouped-product__qty {
    @include min-screen($screen__sm) {
        align-self: center;
    }
}

.stock {
    font-family: $font-family-sourcesanspro;
}
