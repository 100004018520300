.large-underline,
a.large-underline {
    border-bottom: 1px solid $white;
    padding-bottom: 0.2rem; // specific as per design
    transition: all 0.225s cubic-bezier(0.32, 1, 0.23, 1);

    &:hover {
        border-bottom: 1px solid transparent;
        text-decoration: none !important;
        transition: all 0.225s cubic-bezier(0.32, 1, 0.23, 1);
    }
}
