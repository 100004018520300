.product-items {
    @include g-row();

    .product-item {
        @include g-col(6);
        @include min-screen($screen__lg) {
            @include g-col(4);
        }
    }

    .product-widget {
        @include g-col(12);
        @include min-screen($screen__lg) {
            @include g-col(8);
        }

        &-small {
            @include g-col(6);
            @include min-screen($screen__lg) {
                @include g-col(4);
            }
        }
    }
}

.catalog-category-view-lookbook .button--back-button {
    margin-bottom: $indent__xxs;
    margin-top: -70px;
    position: relative;
    @include max-screen($screen__sm) {
        margin-top: -75px;
        transform: scale(1);
    }
}

.catalog-category-view-lookbook  .product-items  {
    .product-item {
        @include min-screen($screen__md) {
            @include g-col(4);
        }

        @include min-screen($screen__lg) {
            @include g-col(3);
        }
    }

    .product-widget {
        @include min-screen($screen__md) {
            @include g-col(8);
        }

        @include min-screen($screen__lg) {
            @include g-col(6);
        }

        &-small {
            @include min-screen($screen__md) {
                @include g-col(4);
            }

            @include min-screen($screen__lg) {
                @include g-col(3);
            }
        }
    }
}
