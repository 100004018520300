.smile-elasticsuite-autocomplete-result {
    background: #fff;
    border-radius: 0.4rem;
    box-shadow: $default-dropshadow--hover;
    font-size: 90%;
    margin-top: $indent__xxxs;
    max-height: 30vh;
    overflow-y: auto;
    padding: $indent__xxxs;

    @include min-screen($screen__sm) {
        font-size: 100%;
        max-height: 70vh;
        padding: $indent__xxxs $indent__xs;
    }
}

.search-autocomplete {
    width: 100% !important;
    z-index: 10;
}

.autocomplete-list-title {
    font-size: $font-size-h4;
}

.autocomplete-list {
    dd {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
}

.smile-elasticsuite-autocomplete-result {
    .amount {
        color: #7d7d7d;
    }

    .product-image-box {
        float: left;
        margin-right: 10px;
        max-width: 45px;
    }

    dd {
        border-bottom: 1px solid transparent;
        clear: both;
        cursor: pointer;

        &:after {
            clear: both;
            content: '';
            display: block;
            height: 0;
            visibility: hidden;
        }
    }

    .f-fix {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .product-item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .price-from .price-label,
        .price-to .price-label {
            padding-bottom: 0;
            padding-right: 0;
        }

        .product-secondary {
            padding-left: 2rem;
        }

        .normal-price,
        .price-from,
        .special-price {
            margin: 0;
        }

        .old-price {
            display: none;
        }

        .price-box {
            .old-price .price,
            .normal-price .price,
            .price-from .price,
            .special-price .price {
                font-size: 1.3rem;
                font-weight: 700;
                padding: 0;
            }
        }
    }
}

.form.minisearch.processing {
    .search-button {
        &:before {
            position: absolute;
            right: 1.2rem;
            top: 1.3rem;

            @include icon(
                'loading',
                $scale: null,
                $display: inline-block,
                $load-icon: true
            );

            @include animation__animate-rotate(2s);
        }
    }
}

.search--minisearch {
    cursor: pointer;
}

.minisearch .search-button,
.search-trigger__link {
    background: none;
    border: 0;

    &:before {
        position: absolute;
        right: 1.5rem;
        top: 1.3rem;

        @include icon(
            'magnifier-black',
            $scale: null,
            $display: inline-block,
            $load-icon: true
        );
    }

    &:disabled {
        &:before {
            opacity: 0.7;
        }
    }

    span {
        display: none;
    }
}
