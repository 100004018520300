$c-grey: #ccc;
$c-grey-light: #f7f7f7;
$c-grey-dark: #d6d6d6;
$c-off-black: $text__base-color;
$color-grey6: $c-grey;

.checkout-container {
    .action.showcart {
        background: transparent;
        border-color: transparent;
    }

    .authentication-wrapper {
        display: none;
    }

    .form-login {
        .actions-toolbar {
            margin-bottom: 1em;
        }
    }

    .opc-wrapper {
        @include min-screen($screen__m) {
            padding-right: 0;
            width: 65%;
        }

        .step-content {
            margin-bottom: 50px;
        }

        .form-login,
        .form-shipping-address {
            max-width: inherit;
        }

        .form-login {
            margin-bottom: 0;
            margin-top: 0;

            & + hr {
                border-top-color: #000;
                margin: 0;
            }
        }

        .form-login .fieldset .note {
            align-items: center;
            align-self: flex-end;
            display: flex;
            font-size: 1.4rem;
            text-align: right;
        }
    }

    .opc-wrapper .step-title,
    #block-discount-heading {
        border: 0;
        color: $c-off-black;
        font-size: 2rem;
        font-weight: 700;
        padding-bottom: 20px;
    }

    .checkout-shipping-method {
        .table-checkout-shipping-method {
            width: 100%;

            tbody td {
                border-color: $color-grey6;
                color: $c-off-black;
                cursor: pointer;
                padding: 13px 0;

                &.col-method-title,
                &.col-carrier,
                &.col-price {
                    font-size: 0.9375em;
                    line-height: 1.7;
                }

                .price,
                &.col-method-title {
                    color: #000;
                }
            }
        }

        @include min-screen($screen__m) {
            .table-checkout-shipping-method tbody .row:hover td {
                color: #000;
            }
        }

        .actions-toolbar {
            margin-top: 20px;

            @include min-screen($screen__m) {
                margin-top: 50px;
            }
        }
    }
}

.opc-wrapper .shipping-address-item {
    border: 1px solid $color-grey6;
    font-size: 1.5rem;
    line-height: 1.6;
    margin-bottom: 30px;
    margin-right: 40px;
    opacity: 0.8;
    width: calc(50% - 20px);

    &:nth-child(even) {
        margin-right: 0;
    }
}

.opc-wrapper .checkout-shipping-address .shipping-address-item {
    .button__container {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }

    .edit-address-link {
        &:before {
            width: 100%;
        }

        &:hover {
            &:before {
                display: none;
            }
        }
    }
}

.opc-wrapper .shipping-address-item.selected-item {
    border-color: $c-off-black;
    border-width: 4px;
    opacity: 1;

    &:after {
        background: $c-off-black;
    }
}


.shipping-address-items {
    .shipping-address-item {
        .action-select-shipping-item {
            float: none;
            margin-top: 10px;
            position: unset;
        }
    }
}

#dpd_parcelshop_container {
    table {
        min-width: 250px !important; // sass-lint:disable-line no-important
    }

    tbody {
        tr td:first-child {
            display: none;
        }

        td {
            font-size: 1.3rem;
        }
    }

    a.parcelshoplink {
        padding: 12px 10px;
    }
}

.checkout-container .checkout-shipping-method .table-checkout-shipping-method tbody td {
    padding-right: 10px;
}
