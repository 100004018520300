.form-login {
    .actions-toolbar {
        flex-direction: column;
        width: auto;

        @include min-screen($screen__sm) {
            flex-direction: row;
        }
    }
}

.checkout-container .actions-toolbar .secondary {
    margin-left: 0;
    margin-top: $indent__xxs;

    @include min-screen($screen__sm) {
        margin-left: $indent__xxs;
        margin-top: 0;
    }
}
