$checkbox-border-radius: 3px !default;
$checkbox-border-color: $white !default;
$checkbox-border-color--active: $white !default;
$checkbox-border-background: $white !default;
$checkbox-border-background--active: $white !default;
$checkbox-icon-color--active: $black !default;
$checkbox-width: 20px !default;
$checkbox-height: 20px !default;

$radiobox-border-radius: 100% !default;
$radiobox-border-color: $border-color__base !default;
$radiobox-border-color--active: $border-color__base--focus !default;
$radiobox-border-background: $white !default;
$radiobox-border-background--active: $white !default;
$radiobox-icon-color--active: $black !default;
$radiobox-width: 21px !default;
$radiobox-height: 21px !default;

$input-border-radius: 23px !default;
$input-text__background-color: $white !default;
$input-text__border-color: 1px solid $border-color__base !default;
$input-text__border-color--active: 1px solid $border-color__base--focus !default;
$text-form__placeholder: $dark-grey;
$font-size-paragraph--main: 1.6rem !default;

textarea {
    -moz-appearance: none; /* Firefox */  // sass-lint:disable-line no-vendor-prefixes
    -webkit-appearance: none; /* Safari and Chrome */ // sass-lint:disable-line no-vendor-prefixes
    appearance: none;
    background: $input-text__background-color;
    border: 1px solid $input-text__border-color;
    border-radius: $input-border-radius;
    font-family: $font-family-sourcesanspro--light;
    font-size: $font-size-paragraph--main;
    min-height: 70px;
    padding: 9px 16px;
    scrollbar-color: $muted-grey transparent; // sass-lint:disable-line no-misspelled-properties
    scrollbar-width: thin; // sass-lint:disable-line no-misspelled-properties
    transition: all 0.2s cubic-bezier(0.32, 1, 0.23, 1);

    &:not([disabled]):focus,
    &:focus {
        border: $input-text__border-color--active;
        box-shadow: none;
        outline: none;
    }

    &::placeholder {
        color: $text-form__placeholder;
    }

    &::-webkit-scrollbar {
        background-color: transparent;
        height: 10px;
        width: 15px;
    }

    &::-webkit-scrollbar-track {
        margin: $indent__xxs 0 0 0;
    }

    &::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: $muted-grey;
        border: 3px solid transparent;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
        border-color: transparent;
    }

    &::-webkit-resizer {
        background: transparent;
        border: transparent;
        display: none;
    }
}

.control--select {
    display: inline-block;
    position: relative;

    &:after {
        @include icon('arrow-down-grey');
        pointer-events: none;
        position: absolute;
        right: 2.3rem;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.2s cubic-bezier(0.32, 1, 0.23, 1);
    }

    &:not([disabled]):focus,
    &:focus {
        &:after {
            transform: rotate(90deg);
        }
    }
}

select {
    -moz-appearance: none; /* Firefox */ // sass-lint:disable-line no-vendor-prefixes
    -webkit-appearance: none; /* Safari and Chrome */ // sass-lint:disable-line no-vendor-prefixes
    appearance: none;
    border: 1px solid $input-text__border-color;
    border-radius: $input-border-radius;
    display: inline-block;
    font-family: $font-family-sourcesanspro--light;
    font-size: $font-size--button;
    font-weight: 400;
    height: 44px;
    padding: 5px 40px 4px 28px;
    transition: all 0.2s cubic-bezier(0.32, 1, 0.23, 1);
    width: auto;

    &:not([disabled]):focus,
    &:focus {
        border: $input-text__border-color--active;
        box-shadow: none;
        outline: none;
    }
}

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background: $input-text__background-color;
    border: 1px solid $input-text__border-color;
    border-radius: $input-border-radius;
    box-shadow: none;
    font-family: $font-family-sourcesanspro--light;
    font-size: $font-size-paragraph--main;
    height: auto;
    line-height: 20px;
    padding: 0.85rem 1.6rem;
    transition: all 0.2s cubic-bezier(0.32, 1, 0.23, 1);

    @include min-screen($screen__xs) {
        padding: 1.2rem 2.8rem;
    }

    & + label {
        padding: 9px 16px;
    }

    &:not([disabled]):focus,
    &:focus {
        border: $input-text__border-color--active;
        box-shadow: none;
        outline: none;
    }

    &::placeholder {
        color: $text-form__placeholder;
    }
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="radio"],
input[type="checkbox"] {
    margin-bottom: $indent__xxs;
    position: relative;

    &:focus {
        border-color: transparent;
        box-shadow: none;
        outline-style: none;
    }

    & + label {
        position: relative;

        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            transition: all 0.2s cubic-bezier(0.32, 1, 0.23, 1);
        }
    }
}


input[type="checkbox"] {
    display: none;

    & + label {
        display: block;
        line-height: 3rem;
        margin-bottom: 0;
        margin-left: 4rem;
        padding-left: calc(#{$checkbox-width} + #{$indent__s}) !important; // Override long selectors

        &:hover {
            cursor: pointer;
        }

        &:after {
            background-color: $checkbox-border-background;
            background-image: none;
            background-position: center;
            background-size: 15px;
            border: 1px solid $checkbox-border-color;
            border-radius: $checkbox-border-radius;
            height: $checkbox-height;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: $checkbox-width;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &:checked + label:after {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52' width='52' height='52'><path fill='none' stroke-width='4' stroke='black' d='M14.1 27.2l7.1 7.2 16.7-16.8'/></svg>");
        background-repeat: no-repeat;
        background-size: 24px;
        border: 1px solid $checkbox-border-color--active;
    }
}

input[type="radio"] {
    body:not(.checkout-index-index) & {
        display: none;
    }

    & + label {
        display: block;
        line-height: 3rem;
        margin-bottom: 0;
        margin-left: 4rem;

        &:hover {
            cursor: pointer;
        }

        &:after {
            background-color: $radiobox-border-background;
            background-position: center;
            background-size: 15px;
            border: 1px solid $radiobox-border-color;
            border-radius: $radiobox-border-radius;
            height: $radiobox-height;
            left: -4rem;
            top: 0.3rem;
            width: $radiobox-width;
        }
    }

    &:checked + label:after {
        &:hover {
            cursor: pointer;
        }

        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100'><path d='M 25, 50 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0' /></svg>");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 26px;
        border: 1px solid $radiobox-border-color--active;
    }
}
