#hamburger-trigger { // sass-lint:disable-line no-ids
    -moz-transform: rotate(0deg);
    -moz-transition: 0.5s ease-in-out;
    -o-transform: rotate(0deg);
    -o-transition: 0.5s ease-in-out;
    -webkit-transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    cursor: pointer;
    display: inline-block;
    height: 14px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    width: 24px;
}

#hamburger-trigger span { // sass-lint:disable-line no-ids
    -moz-transform: rotate(0deg);
    -moz-transition: 0.25s ease-in-out;
    -o-transform: rotate(0deg);
    -o-transition: 0.25s ease-in-out;
    -webkit-transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    background: $color-white-smoke;
    border-radius: 9px;
    display: block;
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    width: 100%;
}

.default-header-page:not(.navigation--open) {
    #hamburger-trigger span {
        filter: invert(1);
    }
}

#hamburger-trigger span:nth-child(1) { // sass-lint:disable-line no-ids
    top: 0;
}

#hamburger-trigger span:nth-child(2),
#hamburger-trigger span:nth-child(3) { // sass-lint:disable-line no-ids
    top: 6px;
}

#hamburger-trigger span:nth-child(4) { // sass-lint:disable-line no-ids
    top: 12px;
}



.navigation--open {

    #hamburger-trigger span:nth-child(1) {
        left: 50%;
        top: 18px;
        width: 0;
    }

    #hamburger-trigger span:nth-child(2) {
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    #hamburger-trigger span:nth-child(3) {
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    #hamburger-trigger span:nth-child(4) {
        left: 50%;
        top: 6px;
        width: 0;
    }
}

