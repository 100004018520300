body,
.smooth-container {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
}

.cms-no-route .page-main {
    margin-top: $indent__sm;
}

.columns {
    @include min-screen($screen__md) {
        display: flex;
        flex-direction: row;
    }
}

.full-width-top-container {
    overflow: hidden;

    @include min-screen($screen__md) {
        height: 0;
        padding-bottom: Min(56.25%, 100vh);
    }
}

.full-width-top-container ~ .breadcrumbs {
    display: none;
}

.navigation,
.breadcrumbs,
.page-header__container,
.category-header__container,
.page-header .header.panel,
.header.content,
.footer.content,
.page-main,
.page-wrapper > .widget,
.page-wrapper > .page-bottom,
.block.category.event,
.top-container {
    padding-left: $indent__xs;
    padding-right: $indent__xs;

    @include min-screen($screen__sm) {
        padding-left: $indent__lg;
        padding-right: $indent__lg;
    }

    @include min-screen($screen__lg) {
        padding-left: $indent__xxl;
        padding-right: $indent__xxl;
    }
}

.page-layout-2columns-left .column.main,
.page-layout-2columns-right .column.main {
    @include min-screen($screen__sm) {
        width: 70%;
    }

    @include min-screen($screen__md) {
        flex: 1;
    }
}

.page-layout-2columns-left .sidebar-main,
.page-layout-2columns-left .sidebar-additional,
.page-layout-2columns-right .sidebar-main {
    @include min-screen($screen__sm) {
        padding-right: 6rem;
        width: 30%;
    }

    @include min-screen($screen__md) {
        box-sizing: content-box;
        flex: 0 0 300px;
    }
}

.margin-top {
    margin-top: $indent__xxs;
}


.product {
    &.pricing {
        display: none;
    }
}
