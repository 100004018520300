.widget-promo__container {
    display: flex;
    min-height: 100%;
    overflow: hidden;
    padding-top: 25%;
    position: relative;

    @include min-screen($screen__lg) {
        padding-top: 0;
    }

    &[data-clickable-element] {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        cursor: pointer;
        @include default-transition($targets: (transform, filter, box-shadow), $duration: 0.5s);

        .widget-promo__image--background {
            @include default-transition($targets: (transform, filter, box-shadow), $duration: 0.5s);
        }

        &:focus,
        &:hover {
            transform: translateY(-5px);

            @include min-screen($screen__sm) {
                box-shadow: 0 50px 60px rgba(0, 0, 0, 0.1);
            }

            .widget-promo__image--background {
                transform: scale(1.1);
            }

            [data-clickable-link] {

                &:after {
                    filter: invert(1);
                }

                &:focus,
                &:hover {
                    transform: translateY(0);
                }

                &.ghost {
                    background: $button-ghost__background-color--hover;
                    border: 1px solid $button-ghost__border-color--hover;
                    color: $button-ghost__color;
                }

                &.ghost-white {
                    background: $button-ghost-white__background-color--hover;
                    border: 1px solid $button-ghost-white__border-color--hover;
                    color: $button-ghost-white__color--hover;
                }
            }
        }
    }
}

.widget-promo__content {
    display: flex;
    margin-top: auto;
    padding: $indent__md $indent__sm;
    position: relative;
    width: 100%;
}

.widget-promo__content--right {
    align-self: flex-end;
    margin-left: auto;
}

.widget-promo__image {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.widget-promo__image--background {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}

.widget-promo__title {
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 1.4;
    @include min-screen($screen__sm) {
        font-size: 4rem;
    }
}

.widget-promo__subtitle {
    display: block;
    font-size: 2rem;
    line-height: 1.2;
    margin-top: 1rem;

    @include min-screen($screen__sm) {
        font-size: $font-size-h3;
    }
}

.widget-promo--light {
    .widget-promo__title,
    .widget-promo__subtitle {
        color: $white;
    }
}

