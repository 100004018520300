.hide-above-screen-lg {
    @include min-screen($screen__lg) {
        display: none;
        height: 0;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 0;
    }
}
