// Discount field
.opc-wrapper .form-discount {
    max-width: 100%;
}

.checkout-container .actions-toolbar .secondary {
    margin-left: 2rem;
}


.checkout-agreement.field.choice.required .label {
    &:after {
        content: "";
    }

    &:before {
        color: #f00;
        content: "*";
        font-size: 21px;
        left: 5px;
        position: absolute;
    }

    span {
        white-space: pre-wrap;
    }
}

.checkout-payment-method .payment-option._collapsible .payment-option-content {
    padding-left: $indent__xxs;
}

// Payment methods
.checkout-payment-method .payment-method-title label {
    color: #202020;

    img.payment-icon {
        display: none;
    }
}

// Payment method selected
.checkout-payment-method ._active .payment-method-title label {
    color: #000;
}


.payment-method-content .field + label:after {
    display: none;
}

/* Sorry iets te specifiek O_O */
.checkout-index-index .opc-payment .payment-method-title.field > label.label > span {
    margin-left: 1rem;
}

// Payment methods content
.checkout-payment-method {
    @include max-screen($screen__m) {
        margin: 0;
    }

    input[type="radio"] + label:before {
        top: 0;
    }

    input[type="radio"] {
        display: none;
    }

    input[type="radio"] + label {
        margin-left: 5rem;

        &:after {
            left: -5rem;
            top: 0;
        }
    }
}

.opc-wrapper .edit-address-link {
    color: $text__base-color;

    &:focus,
    &:visited {
        color: $text__base-color;
    }

    &:hover {
        color: $text__muted-color;
    }
}

// Agreements & Newsletter
.checkout-agreement,
.payment-option.newsletter {
    margin-top: 20px;
    position: relative;

    input[type="checkbox"] + label {
        display: inline-block;
        padding-left: 25px;
        position: static;

        span {
            color: #000;
            display: block;
            font-size: 0.9375em;
        }

        span a {
            color: #ccc;
        }
    }

    input[type="checkbox"] + label:before {
        left: 0;
        position: absolute;
    }

    input[type="checkbox"] + label:after {
        top: 6px;
    }
}

// Fixes
.checkout-billing-address input[type="checkbox"] + label span {
    color: #ccc;
    font-size: 0.9375em;
}

#iDealContainer .adyen-checkout__dropdown__button {
    text-decoration: none;
}

.checkout-payment-method {
    .field-select-billing,
    .billing-address-form {
        max-width: 100%;
    }
}

.checkout-billing-address .billing-address-form {
    margin-bottom: 30px;
}

.checkout-payment-method .payment-method._active .payment-method-content {
    display: flex;
    flex-flow: column;

    > div {
        width: 100%;
    }
}

.checkout-payment-method .checkout-billing-address .billing-address-details,
.checkout-payment-method .payment-method-content {
    padding-left: 0;
}

.billing-address-details {
    box-sizing: border-box;
}

.adyen-checkout__dropdown--large {
    width: 270px !important; // sass-lint:disable-line no-important
}

.payment-method-content .field input,
.payment-method-content .field select {
    max-width: 100% !important; // sass-lint:disable-line no-important
}

.opc-wrapper {
    .street .field {
        margin-right: 1rem;
        margin-top: 0;
    }

    .street .control {
        align-items: flex-start;
        display: flex;

        .control {
            display: flex;
            flex-flow: column;
        }
    }
}

.checkout-payment-method .checkout-billing-address .action-update {
    display: block;
    width: auto;
}

.field.checkout-agreement {
    padding-left: 2rem;
    &:before {
        display: none;
    }

    .fieldset > &.required > label:after {
        left: 0;
        margin-left: 0;
    }

    &.choice.required .label:before {
        left: 3rem;
    }

    button {
        display: inline-flex;
        margin-top: -0.5rem;
        &:focus {
            box-shadow: none;
        }

        &:hover {
            translate: none;
        }
    }

}

button.action.primary.checkout {
    height: auto;
}
