.scroll-down {
    bottom: 6rem;
    height: 4.8rem;
    position: absolute;
    right: 10%;
    transform: scale(1.3);
    transform-origin: bottom;
    width: 2.7rem;
    z-index: 999;

    @include min-screen($screen__md) {
        bottom: 8rem;
        right: calc(50% - 1.35rem);
        transform: scale(1.5);
        transform-origin: bottom;
    }

    &:after {
        animation: scroll-down-bottom 2s infinite;
        border-bottom: 2px solid $white;
        border-right: 2px solid $white;
        content: '';
        display: block;
        height: 1rem;
        left: 0.5rem;
        position: absolute;
        top: -1rem;
        transform: rotate(45deg);
        width: 1rem;
    }
}
