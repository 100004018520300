$contact-left-margin: 14% !default;

.contact-index-index {
    .page-title-wrapper {
        margin-bottom: $indent__md;
        text-align: center;
    }
}

// Sorry, had to fight against Magento/SnowDogs very specific selectors...
.page-layout-1column .page-main .column.main > .form.contact {
    max-width: $screen__md;

    .contact-info-wrapper {
        display: flex;
        flex-direction: column;

        &.regular-contact {
            .contact-info-business {
                order: 2;
            }

            .contact-info-regular {
                order: 1;
            }
        }

        .contact-info-business {
            order: 1;
        }

        .contact-info-regular {
            order: 2;
        }
    }

    select {
        width: 100%;
    }

    // Input field wrapper
    .field {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        width: 100%;
    }

    // Input field label
    .fieldset > .field:not(.choice) > .label {
        float: unset;
        margin-left: 0;
        margin-top: auto;
        padding: $indent__xxxxs $indent__xxxs;
        text-align: left;
        width: auto;
    }

    // Input field
    .fieldset > .field:not(.choice) > .control {
        width: 100%;

        @include min-screen($screen__sm) {
            width: 100%;
        }
    }

    // Submit button
    .actions-toolbar {
        margin-left: 0;

        @include min-screen($screen__sm) {
            margin-left: 0;
        }
    }

    .fieldset {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        @include min-screen($screen__sm) {
            flex-direction: row;
            gap: $indent__xxxs;
            margin-bottom: $indent__xxs;
        }

        .field {
            &__half {
                flex-basis: calc(50% - 0.6rem);
                flex-shrink: 0;
            }

            &__third {
                flex-basis: calc(33.333% - 0.8rem);
                flex-shrink: 0;
            }

            &__quarter {
                flex-basis: calc(25% - 0.9rem);
                flex-shrink: 0;
            }
        }
    }

    // Additional text
    .contact-form__text {
        margin-bottom: $indent__xs;
        margin-left: 0;
        width: auto;
    }

    .contact-form__text--align-center {
        text-align: center;
    }

    .contact-form__text--bottom {
        margin-top: $indent__xs;
    }

    .switch {
        display: inline-block;
        height: $indent__sm;
        position: relative;
        width: 4.75rem;
    }

    .switch input {
        height: 0;
        opacity: 0;
        width: 0;
    }

    .slider {
        background-color: $muted-grey;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        @include default-transition();
    }

    .slider:before {
        background-color: $white;
        bottom: 0.25rem;
        content: "";
        height: $indent__xs;
        left: 0.25rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: $indent__xs;
        @include default-transition();
    }

    input:checked + .slider {
        background-color: $black;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $black;
    }

    input:checked + .slider:before {
        transform: translate(70%, -50%);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 2.125rem;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .contact-form__business-switch--text {
        margin: $indent__xxxxs 0 0 $indent__xxxxs;
    }

    div#companyandaddress, // sass-lint:disable-line no-ids
    div#business_account { // sass-lint:disable-line no-ids
        @include min-screen($screen__sm) {
            margin-top: $indent__xl;
        }
    }

    .contact-form__business-switch {
        @include min-screen($screen__sm) {
            margin-bottom: $indent__xs;
        }
    }

    .contact-form__personal-info-header,
    .contact-form__company-info-header {
        margin-bottom: $indent__xxs;
        margin-top: $indent__xxs;
    }
}

.additional-info-container {
    max-width: 1024px !important;
    text-align: center;
}
