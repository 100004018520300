// sass-lint:disable space-around-operator leading-zero no-vendor-prefixes

@keyframes plyr-progress {
    to {
        background-position: 25px 0;
        background-position: var(--plyr-progress-loading-size, 25px) 0;
    }
}

@keyframes plyr-popup {
    0% {
        opacity: .5;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes plyr-fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.plyr {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    align-items: center;
    direction: ltr;
    display: flex;
    flex-direction: column;
    font-family: inherit;
    font-family: var(--plyr-font-family, inherit);
    font-variant-numeric: tabular-nums;
    font-weight: 400;
    font-weight: var(--plyr-font-weight-regular, 400);
    line-height: 1.7;
    line-height: var(--plyr-line-height, 1.7);
    max-width: 100%;
    min-width: 200px;
    position: relative;
    text-shadow: none;
    transition: box-shadow .3s ease;
    z-index: 0;

    audio {
        display: block;
        height: 100%;
        width: 100%;
    }

    iframe {
        display: block;
        height: 100%;
        width: 100%;
    }

    video {
        display: block;
        height: 100%;
        width: 100%;
    }

    button {
        font: inherit;
        line-height: inherit;
        width: auto;
    }

    &:focus {
        outline: 0;
    }

    &:not(.plyr--hide-controls) {
        .plyr__controls {
            &:not(:empty) {
                ~ {
                    .plyr__captions {
                        transform: translateY(calc(10px * -4));
                    }
                }
            }
        }
    }

    [data-plyr="airplay"] {
        display: none;
    }

    [data-plyr="captions"] {
        display: none;
    }

    [data-plyr="fullscreen"] {
        display: none;
    }

    [data-plyr="pip"] {
        display: none;
    }

    .plyr__control.plyr__tab-focus {
        .plyr__tooltip {
            opacity: 1;
            transform: translate(-50%, 0) scale(1);
        }
    }

    .plyr__control {
        &:hover {
            .plyr__tooltip {
                opacity: 1;
                transform: translate(-50%, 0) scale(1);
                z-index: 3;
            }
        }

        &:after {
            display: none;
        }
    }

    &:-webkit-full-screen {
        background: #000;
        background: #000;
        border-radius: 0 !important;
        border-radius: 0 !important;
        height: 100%;
        height: 100%;
        margin: 0;
        margin: 0;
        width: 100%;
        width: 100%;

        video {
            height: 100%;
            height: 100%;
        }

        .plyr__video-wrapper {
            height: 100%;
            height: 100%;
            position: static;
            position: static;
        }

        .plyr__control {
            .icon--exit-fullscreen {
                display: block;
                display: block;

                + {
                    svg {
                        display: none;
                        display: none;
                    }
                }
            }
        }
    }

    &:-ms-fullscreen {
        background: #000;
        background: #000;
        border-radius: 0 !important;
        border-radius: 0 !important;
        height: 100%;
        height: 100%;
        margin: 0;
        margin: 0;
        width: 100%;
        width: 100%;

        video {
            height: 100%;
            height: 100%;
        }

        .plyr__video-wrapper {
            height: 100%;
            height: 100%;
            position: static;
            position: static;
        }

        .plyr__control {
            .icon--exit-fullscreen {
                display: block;
                display: block;

                + {
                    svg {
                        display: none;
                        display: none;
                    }
                }
            }
        }
    }

    &:fullscreen {
        background: #000;
        border-radius: 0 !important;
        height: 100%;
        margin: 0;
        width: 100%;

        video {
            height: 100%;
        }

        .plyr__video-wrapper {
            height: 100%;
            position: static;
        }

        .plyr__control {
            .icon--exit-fullscreen {
                display: block;

                + {
                    svg {
                        display: none;
                    }
                }
            }
        }
    }






    &:-webkit-full-screen.plyr--hide-controls {
        cursor: none;
        cursor: none;
    }

    &:-ms-fullscreen.plyr--hide-controls {
        cursor: none;
        cursor: none;
    }

    &:fullscreen.plyr--hide-controls {
        cursor: none;
    }

    &:-moz-full-screen {
        background: #000;
        border-radius: 0 !important;
        height: 100%;
        margin: 0;
        width: 100%;

        video {
            height: 100%;
        }

        .plyr__video-wrapper {
            height: 100%;
            position: static;
        }

        .plyr__control {
            .icon--exit-fullscreen {
                display: block;

                + {
                    svg {
                        display: none;
                    }
                }
            }
        }
    }

    &:-moz-full-screen.plyr--hide-controls {
        cursor: none;
    }

    [hidden] {
        display: none !important;
    }
}

.plyr--full-ui {
    box-sizing: border-box;

    * {
        box-sizing: inherit;
    }

    &::after {
        box-sizing: inherit;
    }

    &::before {
        box-sizing: inherit;
    }

    a {
        touch-action: manipulation;
    }

    button {
        touch-action: manipulation;
    }

    input {
        touch-action: manipulation;
    }

    label {
        touch-action: manipulation;
    }

    input[type="range"] {
        -webkit-appearance: none;
        background: 0 0;
        border: 0;
        border-radius: calc(13px * 2);
        border-radius: calc(var(--plyr-range-thumb-height, 13px) * 2);
        color: #00b3ff;
        color: var(--plyr-range-fill-background, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
        display: block;
        height: calc((3px * 2) + 13px);
        height: calc((var(--plyr-range-thumb-active-shadow-width, 3px) * 2) + var(--plyr-range-thumb-height, 13px));
        margin: 0;
        min-width: 0;
        padding: 0;
        transition: box-shadow .3s ease;
        width: 100%;

        &::-webkit-slider-runnable-track {
            -webkit-transition: box-shadow .3s ease;
            -webkit-user-select: none;
            background: 0 0;
            background-image: linear-gradient(to right, currentColor 0, transparent 0);
            background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0));
            border: 0;
            border-radius: calc(5px / 2);
            border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
            height: 5px;
            height: var(--plyr-range-track-height, 5px);
            transition: box-shadow .3s ease;
            user-select: none;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            -webkit-transition: all .2s ease;
            background: #fff;
            background: var(--plyr-range-thumb-background, #fff);
            border: 0;
            border-radius: 100%;
            box-shadow: 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2);
            box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2));
            height: 13px;
            height: var(--plyr-range-thumb-height, 13px);
            margin-top: calc(((13px - 5px) / 2) * -1);
            margin-top: calc(((var(--plyr-range-thumb-height, 13px) - var(--plyr-range-track-height, 5px)) / 2) * -1);
            position: relative;
            transition: all .2s ease;
            width: 13px;
            width: var(--plyr-range-thumb-height, 13px);
        }

        &::-moz-range-track {
            -moz-transition: box-shadow .3s ease;
            background: 0 0;
            border: 0;
            border-radius: calc(5px / 2);
            border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
            height: 5px;
            height: var(--plyr-range-track-height, 5px);
            transition: box-shadow .3s ease;
            user-select: none;
        }

        &::-moz-range-thumb {
            -moz-transition: all .2s ease;
            background: #fff;
            background: var(--plyr-range-thumb-background, #fff);
            border: 0;
            border-radius: 100%;
            box-shadow: 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2);
            box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2));
            height: 13px;
            height: var(--plyr-range-thumb-height, 13px);
            position: relative;
            transition: all .2s ease;
            width: 13px;
            width: var(--plyr-range-thumb-height, 13px);
        }

        &::-moz-range-progress {
            background: currentColor;
            border-radius: calc(5px / 2);
            border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
            height: 5px;
            height: var(--plyr-range-track-height, 5px);
        }

        &::-ms-track {
            -ms-transition: box-shadow .3s ease;
            -ms-user-select: none;
            background: 0 0;
            border: 0;
            border-radius: calc(5px / 2);
            border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
            color: transparent;
            height: 5px;
            height: var(--plyr-range-track-height, 5px);
            transition: box-shadow .3s ease;
            user-select: none;
        }

        &::-ms-fill-upper {
            -ms-transition: box-shadow .3s ease;
            -ms-user-select: none;
            background: 0 0;
            border: 0;
            border-radius: calc(5px / 2);
            border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
            height: 5px;
            height: var(--plyr-range-track-height, 5px);
            transition: box-shadow .3s ease;
            user-select: none;
        }

        &::-ms-fill-lower {
            -ms-transition: box-shadow .3s ease;
            -ms-user-select: none;
            background: 0 0;
            background: currentColor;
            border: 0;
            border-radius: calc(5px / 2);
            border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
            height: 5px;
            height: var(--plyr-range-track-height, 5px);
            transition: box-shadow .3s ease;
            user-select: none;
        }

        &::-ms-thumb {
            -ms-transition: all .2s ease;
            background: #fff;
            background: var(--plyr-range-thumb-background, #fff);
            border: 0;
            border-radius: 100%;
            box-shadow: 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2);
            box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2));
            height: 13px;
            height: var(--plyr-range-thumb-height, 13px);
            margin-top: 0;
            position: relative;
            transition: all .2s ease;
            width: 13px;
            width: var(--plyr-range-thumb-height, 13px);
        }

        &::-ms-tooltip {
            display: none;
        }

        &:focus {
            outline: 0;
        }

        &::-moz-focus-outer {
            border: 0;
        }
    }

    input[type="range"].plyr__tab-focus {
        &::-webkit-slider-runnable-track {
            outline-color: #00b3ff;
            outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
            outline-offset: 2px;
            outline-style: dotted;
            outline-width: 3px;
        }

        &::-moz-range-track {
            outline-color: #00b3ff;
            outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
            outline-offset: 2px;
            outline-style: dotted;
            outline-width: 3px;
        }

        &::-ms-track {
            outline-color: #00b3ff;
            outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
            outline-offset: 2px;
            outline-style: dotted;
            outline-width: 3px;
        }
    }

    .plyr__video-embed {
        > .plyr__video-embed__container {
            padding-bottom: 240%;
            position: relative;
            transform: translateY(-38.28125%);

            iframe {
                width: 100% !important;
            }
        }
    }
}

.plyr__badge {
    background: #4a5464;
    background: var(--plyr-badge-background, #4a5464);
    border-radius: 2px;
    border-radius: var(--plyr-badge-border-radius, 2px);
    color: #fff;
    color: var(--plyr-badge-text-color, #fff);
    font-size: 9px;
    font-size: var(--plyr-font-size-badge, 9px);
    line-height: 1;
    padding: 3px 4px;
}

.plyr__captions {
    animation: plyr-fade-in .3s ease;
    bottom: 0;
    display: none;
    font-size: 13px;
    font-size: var(--plyr-font-size-small, 13px);
    left: 0;
    padding: 10px;
    padding: var(--plyr-control-spacing, 10px);
    position: absolute;
    text-align: center;
    transition: transform .4s ease-in-out;
    width: 100%;

    span {
        &:empty {
            display: none;
        }
    }
}

.plyr--captions-active {
    .plyr__captions {
        display: block;
    }
}

.plyr__caption {
    -webkit-box-decoration-break: clone;
    background: rgba(0, 0, 0, .8);
    background: var(--plyr-captions-background, rgba(0, 0, 0, .8));
    border-radius: 2px;
    box-decoration-break: clone;
    color: #fff;
    color: var(--plyr-captions-text-color, #fff);
    line-height: 185%;
    padding: .2em .5em;
    white-space: pre-wrap;

    div {
        display: inline;
    }
}

.plyr__control {
    background: 0 0;
    border: 0;
    border-radius: 3px;
    border-radius: var(--plyr-control-radius, 3px);
    color: inherit;
    cursor: pointer;
    flex-shrink: 0;
    overflow: visible;
    padding: calc(10px * .7);
    padding: calc(var(--plyr-control-spacing, 10px) * .7);
    position: relative;
    transition: all .3s ease;

    svg {
        display: block;
        fill: currentColor;
        height: 18px;
        height: var(--plyr-control-icon-size, 18px);
        pointer-events: none;
        width: 18px;
        width: var(--plyr-control-icon-size, 18px);
    }

    &:focus {
        outline: 0;
    }

    &:not(.plyr__control--pressed) {
        .icon--pressed {
            display: none;
        }

        .label--pressed {
            display: none;
        }
    }
}

.plyr__control.plyr__tab-focus {
    outline-color: #00b3ff;
    outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
    outline-offset: 2px;
    outline-style: dotted;
    outline-width: 3px;
}

a.plyr__control {
    text-decoration: none;

    &::after {
        display: none;
    }

    &::before {
        display: none;
    }
}

.plyr__control.plyr__control--pressed {
    .icon--not-pressed {
        display: none;
    }

    .label--not-pressed {
        display: none;
    }
}

.plyr__controls {
    align-items: center;
    display: flex;
    text-align: center;

    > div {
        align-self: center;
    }

    .plyr__progress__container {
        flex: 1;
        min-width: 0;
    }

    .plyr__controls__item {

        button {
            align-self: center;
            height: 32px;

            &:hover {
                background: transparent;
                border: 0;

                svg {
                    fill: #fff;
                }
            }
        }

        margin-left: calc(10px / 4);
        margin-left: calc(var(--plyr-control-spacing, 10px) / 4);

        &:hover,
        &:focus {
            background-color: transparent;
            border: 0;
            transform: none;

        }

        &:first-child {
            margin-left: 0;
            margin-right: auto;
        }

        &:last-child,
        &:first-child {
            transform: translateY(21%);

            svg {
                fill: #fff;
            }
        }
    }

    .plyr__controls__item.plyr__progress__container {
        padding-left: calc(10px / 4);
        padding-left: calc(var(--plyr-control-spacing, 10px) / 4);

        &:first-child {
            padding-left: 0;
        }
    }

    .plyr__controls__item.plyr__time {
        padding: 0 calc(10px / 2);
        padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);

        + {
            .plyr__time {
                padding-left: 0;
            }
        }

        &:first-child {
            padding-left: 0;
        }
    }

    &:empty {
        display: none;
    }

    > .plyr__control {
        &:first-child {
            .plyr__tooltip {
                left: 0;
                transform: translate(0, 10px) scale(.8);
                transform-origin: 0 100%;

                &::before {
                    left: calc((18px / 2) + calc(10px * .7));
                    left: calc((var(--plyr-control-icon-size, 18px) / 2) + calc(var(--plyr-control-spacing, 10px) * .7));
                }
            }

            + {
                .plyr__control {
                    .plyr__tooltip {
                        left: 0;
                        transform: translate(0, 10px) scale(.8);
                        transform-origin: 0 100%;

                        &::before {
                            left: calc((18px / 2) + calc(10px * .7));
                            left: calc((var(--plyr-control-icon-size, 18px) / 2) + calc(var(--plyr-control-spacing, 10px) * .7));
                        }
                    }

                    .plyr__tooltip--visible {
                        transform: translate(0, 0) scale(1);
                    }

                    &:hover {
                        .plyr__tooltip {
                            transform: translate(0, 0) scale(1);
                        }
                    }
                }

                .plyr__control.plyr__tab-focus {
                    .plyr__tooltip {
                        transform: translate(0, 0) scale(1);
                    }
                }
            }

            .plyr__tooltip--visible {
                transform: translate(0, 0) scale(1);
            }

            &:hover {
                .plyr__tooltip {
                    transform: translate(0, 0) scale(1);
                }
            }
        }

        &:last-child {
            .plyr__tooltip {
                left: auto;
                right: 0;
                transform: translate(0, 10px) scale(.8);
                transform-origin: 100% 100%;

                &::before {
                    left: auto;
                    right: calc((18px / 2) + calc(10px * .7));
                    right: calc((var(--plyr-control-icon-size, 18px) / 2) + calc(var(--plyr-control-spacing, 10px) * .7));
                    transform: translateX(50%);
                }
            }

            .plyr__tooltip--visible {
                transform: translate(0, 0) scale(1);
            }

            &:hover {
                .plyr__tooltip {
                    transform: translate(0, 0) scale(1);
                }
            }
        }

        &:first-child.plyr__tab-focus {
            .plyr__tooltip {
                transform: translate(0, 0) scale(1);
            }
        }

        &:last-child.plyr__tab-focus {
            .plyr__tooltip {
                transform: translate(0, 0) scale(1);
            }
        }
    }
}

.plyr--airplay-supported {
    [data-plyr="airplay"] {
        display: inline-block;
    }
}

.plyr--captions-enabled {
    [data-plyr="captions"] {
        display: inline-block;
    }
}

.plyr--fullscreen-enabled {
    [data-plyr="fullscreen"] {
        display: inline-block;
    }
}

.plyr--pip-supported {
    [data-plyr="pip"] {
        display: inline-block;
    }
}

.plyr__menu {
    display: flex;
    position: relative;

    .plyr__control {
        svg {
            transition: transform .3s ease;
        }
    }

    .plyr__control[aria-expanded="true"] {
        svg {
            transform: rotate(90deg);
        }

        .plyr__tooltip {
            display: none;
        }
    }
}

.plyr__menu__container {
    animation: plyr-popup .2s ease;
    background: rgba(255, 255, 255, .9);
    background: var(--plyr-menu-background, rgba(255, 255, 255, .9));
    border-radius: 4px;
    bottom: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
    box-shadow: var(--plyr-menu-shadow, 0 1px 2px rgba(0, 0, 0, .15));
    color: #4a5464;
    color: var(--plyr-menu-color, #4a5464);
    font-size: 15px;
    font-size: var(--plyr-font-size-base, 15px);
    margin-bottom: 10px;
    position: absolute;
    right: -3px;
    text-align: left;
    white-space: nowrap;
    z-index: 3;

    > div {
        overflow: hidden;
        transition: height .35s cubic-bezier(.4, 0, .2, 1), width .35s cubic-bezier(.4, 0, .2, 1);
    }

    &::after {
        border: 4px solid transparent;
        border: var(--plyr-menu-arrow-size, 4px) solid transparent;
        border-top-color: rgba(255, 255, 255, .9);
        border-top-color: var(--plyr-menu-background, rgba(255, 255, 255, .9));
        content: '';
        height: 0;
        position: absolute;
        right: calc(((18px / 2) + calc(10px * .7)) - (4px / 2));
        right: calc(((var(--plyr-control-icon-size, 18px) / 2) + calc(var(--plyr-control-spacing, 10px) * .7)) - (var(--plyr-menu-arrow-size, 4px) / 2));
        top: 100%;
        width: 0;
    }

    [role="menu"] {
        padding: calc(10px * .7);
        padding: calc(var(--plyr-control-spacing, 10px) * .7);
    }

    [role="menuitem"] {
        margin-top: 2px;

        &:first-child {
            margin-top: 0;
        }
    }

    [role="menuitemradio"] {
        margin-top: 2px;

        &:first-child {
            margin-top: 0;
        }
    }

    .plyr__control {
        -ms-user-select: none;
        -webkit-user-select: none;
        align-items: center;
        color: #4a5464;
        color: var(--plyr-menu-color, #4a5464);
        display: flex;
        font-size: 13px;
        font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
        padding-bottom: calc(calc(10px * .7) / 1.5);
        padding-bottom: calc(calc(var(--plyr-control-spacing, 10px) * .7) / 1.5);
        padding-left: calc(calc(10px * .7) * 1.5);
        padding-left: calc(calc(var(--plyr-control-spacing, 10px) * .7) * 1.5);
        padding-right: calc(calc(10px * .7) * 1.5);
        padding-right: calc(calc(var(--plyr-control-spacing, 10px) * .7) * 1.5);
        padding-top: calc(calc(10px * .7) / 1.5);
        padding-top: calc(calc(var(--plyr-control-spacing, 10px) * .7) / 1.5);
        user-select: none;
        width: 100%;

        > span {
            align-items: inherit;
            display: flex;
            width: 100%;
        }

        &::after {
            border: 4px solid transparent;
            border: var(--plyr-menu-item-arrow-size, 4px) solid transparent;
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .plyr__control--forward {
        padding-right: calc(calc(10px * .7) * 4);
        padding-right: calc(calc(var(--plyr-control-spacing, 10px) * .7) * 4);

        &::after {
            border-left-color: #728197;
            border-left-color: var(--plyr-menu-arrow-color, #728197);
            right: calc((calc(10px * .7) * 1.5) - 4px);
            right: calc((calc(var(--plyr-control-spacing, 10px) * .7) * 1.5) - var(--plyr-menu-item-arrow-size, 4px));
        }

        &:hover {
            &::after {
                border-left-color: currentColor;
            }
        }
    }

    .plyr__control--forward.plyr__tab-focus {
        &::after {
            border-left-color: currentColor;
        }
    }

    .plyr__control--back {
        font-weight: 400;
        font-weight: var(--plyr-font-weight-regular, 400);
        margin: calc(10px * .7);
        margin: calc(var(--plyr-control-spacing, 10px) * .7);
        margin-bottom: calc(calc(10px * .7) / 2);
        margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) * .7) / 2);
        padding-left: calc(calc(10px * .7) * 4);
        padding-left: calc(calc(var(--plyr-control-spacing, 10px) * .7) * 4);
        position: relative;
        width: calc(100% - (calc(10px * .7) * 2));
        width: calc(100% - (calc(var(--plyr-control-spacing, 10px) * .7) * 2));

        &::after {
            border-right-color: #728197;
            border-right-color: var(--plyr-menu-arrow-color, #728197);
            left: calc((calc(10px * .7) * 1.5) - 4px);
            left: calc((calc(var(--plyr-control-spacing, 10px) * .7) * 1.5) - var(--plyr-menu-item-arrow-size, 4px));
        }

        &::before {
            background: #dcdfe5;
            background: var(--plyr-menu-back-border-color, #dcdfe5);
            box-shadow: 0 1px 0 #fff;
            content: '';
            height: 1px;
            left: 0;
            margin-top: calc(calc(10px * .7) / 2);
            margin-top: calc(calc(var(--plyr-control-spacing, 10px) * .7) / 2);
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 100%;
        }

        &:hover {
            &::after {
                border-right-color: currentColor;
            }
        }
    }

    .plyr__control--back.plyr__tab-focus {
        &::after {
            border-right-color: currentColor;
        }
    }

    .plyr__control[role="menuitemradio"] {
        padding-left: calc(10px * .7);
        padding-left: calc(var(--plyr-control-spacing, 10px) * .7);

        &::after {
            background: #fff;
            border: 0;
            border-radius: 100%;
            height: 6px;
            left: 12px;
            opacity: 0;
            top: 50%;
            transform: translateY(-50%) scale(0);
            transition: transform .3s ease, opacity .3s ease;
            width: 6px;
        }

        &::before {
            background: rgba(0, 0, 0, .1);
            border-radius: 100%;
            content: '';
            display: block;
            flex-shrink: 0;
            height: 16px;
            margin-right: 10px;
            margin-right: var(--plyr-control-spacing, 10px);
            transition: all .3s ease;
            width: 16px;
        }

        &:hover {
            &::before {
                background: rgba(35, 40, 47, .1);
            }
        }
    }

    .plyr__control[role="menuitemradio"][aria-checked="true"] {
        &::before {
            background: #00b3ff;
            background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
        }

        &::after {
            opacity: 1;
            transform: translateY(-50%) scale(1);
        }
    }

    .plyr__control[role="menuitemradio"].plyr__tab-focus {
        &::before {
            background: rgba(35, 40, 47, .1);
        }
    }

    .plyr__menu__value {
        align-items: center;
        display: flex;
        margin-left: auto;
        margin-right: calc((calc(10px * .7) - 2) * -1);
        margin-right: calc((calc(var(--plyr-control-spacing, 10px) * .7) - 2) * -1);
        overflow: hidden;
        padding-left: calc(calc(10px * .7) * 3.5);
        padding-left: calc(calc(var(--plyr-control-spacing, 10px) * .7) * 3.5);
        pointer-events: none;
    }
}

.plyr__poster {
    background-color: #000;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .2s ease;
    width: 100%;
    z-index: 1;
}

.plyr--stopped.plyr__poster-enabled {
    .plyr__poster {
        opacity: 1;
    }
}

.plyr__time {
    font-size: 13px;
    font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));

    + {
        .plyr__time {
            &::before {
                content: '\2044';
                margin-right: 10px;
                margin-right: var(--plyr-control-spacing, 10px);
            }
        }
    }
}

.plyr__tooltip {
    background: rgba(255, 255, 255, .9);
    background: var(--plyr-tooltip-background, rgba(255, 255, 255, .9));
    border-radius: 3px;
    border-radius: var(--plyr-tooltip-radius, 3px);
    bottom: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
    box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, .15));
    color: #4a5464;
    color: var(--plyr-tooltip-color, #4a5464);
    font-size: 13px;
    font-size: var(--plyr-font-size-small, 13px);
    font-weight: 400;
    font-weight: var(--plyr-font-weight-regular, 400);
    left: 50%;
    line-height: 1.3;
    margin-bottom: calc(calc(10px / 2) * 2);
    margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) / 2) * 2);
    opacity: 0;
    padding: calc(10px / 2) calc(calc(10px / 2) * 1.5);
    padding: calc(var(--plyr-control-spacing, 10px) / 2) calc(calc(var(--plyr-control-spacing, 10px) / 2) * 1.5);
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, 10px) scale(.8);
    transform-origin: 50% 100%;
    transition: transform .2s .1s ease, opacity .2s .1s ease;
    white-space: nowrap;
    z-index: 2;

    &::before {
        border-left: 4px solid transparent;
        border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
        border-right: 4px solid transparent;
        border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
        border-top: 4px solid rgba(255, 255, 255, .9);
        border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, rgba(255, 255, 255, .9));
        bottom: calc(4px * -1);
        bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
        content: '';
        height: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 0;
        z-index: 2;
    }
}

.plyr__tooltip--visible {
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
}

.plyr__progress {
    left: calc(13px * .5);
    left: calc(var(--plyr-range-thumb-height, 13px) * .5);
    margin-right: 13px;
    margin-right: var(--plyr-range-thumb-height, 13px);
    position: relative;

    input[type="range"] {
        margin-left: calc(13px * -.5);
        margin-left: calc(var(--plyr-range-thumb-height, 13px) * -.5);
        margin-right: calc(13px * -.5);
        margin-right: calc(var(--plyr-range-thumb-height, 13px) * -.5);
        position: relative;
        width: calc(100% + 13px);
        width: calc(100% + var(--plyr-range-thumb-height, 13px));
        z-index: 2;
    }

    .plyr__tooltip {
        font-size: 13px;
        font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
        left: 0;
    }
}

.plyr__progress__buffer {
    -webkit-appearance: none;
    background: 0 0;
    border: 0;
    border-radius: 100px;
    height: 5px;
    height: var(--plyr-range-track-height, 5px);
    left: 0;
    margin-left: calc(13px * -.5);
    margin-left: calc(var(--plyr-range-thumb-height, 13px) * -.5);
    margin-right: calc(13px * -.5);
    margin-right: calc(var(--plyr-range-thumb-height, 13px) * -.5);
    margin-top: calc((5px / 2) * -1);
    margin-top: calc((var(--plyr-range-track-height, 5px) / 2) * -1);
    padding: 0;
    position: absolute;
    top: 50%;
    width: calc(100% + 13px);
    width: calc(100% + var(--plyr-range-thumb-height, 13px));

    &::-webkit-progress-bar {
        background: 0 0;
    }

    &::-webkit-progress-value {
        -webkit-transition: width .2s ease;
        background: currentColor;
        border-radius: 100px;
        min-width: 5px;
        min-width: var(--plyr-range-track-height, 5px);
        transition: width .2s ease;
    }

    &::-moz-progress-bar {
        -moz-transition: width .2s ease;
        background: currentColor;
        border-radius: 100px;
        min-width: 5px;
        min-width: var(--plyr-range-track-height, 5px);
        transition: width .2s ease;
    }

    &::-ms-fill {
        -ms-transition: width .2s ease;
        border-radius: 100px;
        transition: width .2s ease;
    }
}

.plyr--loading {
    .plyr__progress__buffer {
        animation: plyr-progress 1s linear infinite;
        background-image: linear-gradient(-45deg, rgba(35, 40, 47, .6) 25%, transparent 25%, transparent 50%, rgba(35, 40, 47, .6) 50%, rgba(35, 40, 47, .6) 75%, transparent 75%, transparent);
        background-image: linear-gradient(-45deg, var(--plyr-progress-loading-background, rgba(35, 40, 47, .6)) 25%, transparent 25%, transparent 50%, var(--plyr-progress-loading-background, rgba(35, 40, 47, .6)) 50%, var(--plyr-progress-loading-background, rgba(35, 40, 47, .6)) 75%, transparent 75%, transparent);
        background-repeat: repeat-x;
        background-size: 25px 25px;
        background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
        color: transparent;
    }
}

.plyr--video.plyr--loading {
    .plyr__progress__buffer {
        background-color: rgba(255, 255, 255, .25);
        background-color: var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, .25));
    }
}

.plyr--audio.plyr--loading {
    .plyr__progress__buffer {
        background-color: rgba(193, 200, 209, .6);
        background-color: var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, .6));
    }
}

.plyr__volume {
    align-items: center;
    display: flex;
    max-width: 110px;
    min-width: 80px;
    position: relative;
    width: 20%;

    input[type="range"] {
        margin-left: calc(10px / 2);
        margin-left: calc(var(--plyr-control-spacing, 10px) / 2);
        margin-right: calc(10px / 2);
        margin-right: calc(var(--plyr-control-spacing, 10px) / 2);
        position: relative;
        z-index: 2;
    }
}

.plyr--is-ios {
    .plyr__volume {
        min-width: 0;
        width: auto;
    }
}

.plyr--audio {
    display: block;

    .plyr__controls {
        background: #fff;
        background: var(--plyr-audio-controls-background, #fff);
        border-radius: inherit;
        color: #4a5464;
        color: var(--plyr-audio-control-color, #4a5464);
        padding: 10px;
        padding: var(--plyr-control-spacing, 10px);
    }

    .plyr__control.plyr__tab-focus {
        background: #00b3ff;
        background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
        color: #fff;
        color: var(--plyr-audio-control-color-hover, #fff);
    }

    .plyr__control {
        &:hover {
            background: #00b3ff;
            background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
            color: #fff;
            color: var(--plyr-audio-control-color-hover, #fff);
        }
    }

    .plyr__control[aria-expanded="true"] {
        background: #00b3ff;
        background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
        color: #fff;
        color: var(--plyr-audio-control-color-hover, #fff);
    }

    .plyr__progress__buffer {
        color: rgba(193, 200, 209, .6);
        color: var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, .6));
    }
}

.plyr--full-ui.plyr--audio {
    input[type="range"] {
        &::-webkit-slider-runnable-track {
            background-color: rgba(193, 200, 209, .6);
            background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, .6)));
        }

        &::-moz-range-track {
            background-color: rgba(193, 200, 209, .6);
            background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, .6)));
        }

        &::-ms-track {
            background-color: rgba(193, 200, 209, .6);
            background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, .6)));
        }

        &:active {
            &::-webkit-slider-thumb {
                box-shadow: 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2), 0 0 0 3px rgba(35, 40, 47, .1);
                box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, .1));
            }

            &::-moz-range-thumb {
                box-shadow: 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2), 0 0 0 3px rgba(35, 40, 47, .1);
                box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, .1));
            }

            &::-ms-thumb {
                box-shadow: 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2), 0 0 0 3px rgba(35, 40, 47, .1);
                box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, .1));
            }
        }
    }
}

.plyr--video {
    background: #000;
    overflow: hidden;

    .plyr__controls {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .75));
        background: var(--plyr-video-controls-background, linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .75)));
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
        bottom: 0;
        color: #fff;
        color: var(--plyr-video-control-color, #fff);
        left: 0;
        padding: calc(10px / 2);
        padding: calc(var(--plyr-control-spacing, 10px) / 2);
        padding-top: calc(10px * 2);
        padding-top: calc(var(--plyr-control-spacing, 10px) * 2);
        position: absolute;
        right: 0;
        transition: opacity .4s ease-in-out, transform .4s ease-in-out;
        z-index: 3;
    }

    .plyr__control.plyr__tab-focus {
        background: #00b3ff;
        background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
        color: #fff;
        color: var(--plyr-video-control-color-hover, #fff);
    }

    .plyr__control[aria-expanded="true"] {
        background: #00b3ff;
        background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
        color: #fff;
        color: var(--plyr-video-control-color-hover, #fff);
    }

    .plyr__progress__buffer {
        color: rgba(255, 255, 255, .25);
        color: var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, .25));
    }
}

.plyr--video.plyr--menu-open {
    overflow: visible;
}

.plyr__video-wrapper {
    background: #000;
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.plyr__video-embed {
    height: 0;
    padding-bottom: 56.25%;

    iframe {
        border: 0;
        left: 0;
        position: absolute;
        top: 0;
    }
}

.plyr__video-wrapper--fixed-ratio {
    height: 0;
    padding-bottom: 56.25%;

    video {
        border: 0;
        left: 0;
        position: absolute;
        top: 0;
    }
}

.plyr--video.plyr--hide-controls {
    .plyr__controls {
        opacity: 0;
        pointer-events: none;
        transform: translateY(100%);
    }
}

.plyr__control--overlaid {
    background: transparent;
    border: 0;
    border: 1px solid #fff;
    border-radius: 100%;
    color: #fff;
    display: none;
    left: 50%;
    max-height: 50px;
    opacity: .9;
    padding: calc(10px * 1.5);
    padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: .3s;
    z-index: 2;

    svg {
        fill: #fff;
        left: 2px;
        position: relative;
    }

    & button {
        &:focus {
            opacity: 1;
            transform: translate(-50%, -50%);
        }
    }

    &:hover {
        svg {
            fill: #fff;
        }

        background: transparent;
        border: var(--plyr-video-control-color);
        border: 1px solid #fff;
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

.plyr--playing {
    .plyr__control--overlaid {
        opacity: 0;
        visibility: hidden;
    }
}

.plyr--full-ui.plyr--video {
    .plyr__control--overlaid {
        display: block;
    }

    input[type="range"] {
        &::-webkit-slider-runnable-track {
            background-color: rgba(255, 255, 255, .25);
            background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, .25)));
        }

        &::-moz-range-track {
            background-color: rgba(255, 255, 255, .25);
            background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, .25)));
        }

        &::-ms-track {
            background-color: rgba(255, 255, 255, .25);
            background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, .25)));
        }

        &:active {
            &::-webkit-slider-thumb {
                box-shadow: 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2), 0 0 0 3px rgba(255, 255, 255, .5);
                box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, .5));
            }

            &::-moz-range-thumb {
                box-shadow: 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2), 0 0 0 3px rgba(255, 255, 255, .5);
                box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, .5));
            }

            &::-ms-thumb {
                box-shadow: 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2), 0 0 0 3px rgba(255, 255, 255, .5);
                box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, .15), 0 0 0 1px rgba(35, 40, 47, .2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, .5));
            }
        }
    }
}

.plyr--fullscreen-fallback {
    background: #000;
    border-radius: 0 !important;
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    margin: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10000000;

    video {
        height: 100%;
    }

    .plyr__video-wrapper {
        height: 100%;
        position: static;
    }

    .plyr__control {
        .icon--exit-fullscreen {
            display: block;

            + {
                svg {
                    display: none;
                }
            }
        }
    }
}


.plyr--fullscreen-fallback.plyr--hide-controls {
    cursor: none;
}

.plyr__ads {
    border-radius: inherit;
    bottom: 0;
    cursor: pointer;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    > div {
        height: 100%;
        position: absolute;
        width: 100%;

        iframe {
            height: 100%;
            position: absolute;
            width: 100%;
        }
    }

    &::after {
        background: #23282f;
        border-radius: 2px;
        bottom: 10px;
        bottom: var(--plyr-control-spacing, 10px);
        color: #fff;
        content: attr(data-badge-text);
        font-size: 11px;
        padding: 2px 6px;
        pointer-events: none;
        position: absolute;
        right: 10px;
        right: var(--plyr-control-spacing, 10px);
        z-index: 3;

        &:empty {
            display: none;
        }
    }
}

.plyr__cues {
    background: currentColor;
    display: block;
    height: 5px;
    height: var(--plyr-range-track-height, 5px);
    left: 0;
    margin: var(--plyr-range-track-height, 5px)/2 0 0;
    opacity: .8;
    position: absolute;
    top: 50%;
    width: 3px;
    z-index: 3;
}

.plyr__preview-thumb {
    background-color: rgba(255, 255, 255, .9);
    background-color: var(--plyr-tooltip-background, rgba(255, 255, 255, .9));
    border-radius: 3px;
    bottom: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
    box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, .15));
    margin-bottom: calc(calc(10px / 2) * 2);
    margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) / 2) * 2);
    opacity: 0;
    padding: 3px;
    padding: var(--plyr-tooltip-radius, 3px);
    pointer-events: none;
    position: absolute;
    transform: translate(0, 10px) scale(.8);
    transform-origin: 50% 100%;
    transition: transform .2s .1s ease, opacity .2s .1s ease;
    z-index: 2;

    &::before {
        border-left: 4px solid transparent;
        border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
        border-right: 4px solid transparent;
        border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
        border-top: 4px solid rgba(255, 255, 255, .9);
        border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, rgba(255, 255, 255, .9));
        bottom: calc(4px * -1);
        bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
        content: '';
        height: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 0;
        z-index: 2;
    }
}

.plyr__preview-thumb--is-shown {
    opacity: 1;
    transform: translate(0, 0) scale(1);
}

.plyr__preview-thumb__image-container {
    background: #c1c8d1;
    border-radius: calc(3px - 1px);
    border-radius: calc(var(--plyr-tooltip-radius, 3px) - 1px);
    overflow: hidden;
    position: relative;
    z-index: 0;

    img {
        height: 100%;
        left: 0;
        max-height: none;
        max-width: none;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.plyr__preview-thumb__time-container {
    bottom: 6px;
    left: 0;
    position: absolute;
    right: 0;
    white-space: nowrap;
    z-index: 3;

    span {
        background-color: rgba(0, 0, 0, .55);
        border-radius: calc(3px - 1px);
        border-radius: calc(var(--plyr-tooltip-radius, 3px) - 1px);
        color: #fff;
        font-size: 13px;
        font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
        padding: 3px 6px;
    }
}

.plyr__preview-scrubbing {
    bottom: 0;
    filter: blur(1px);
    height: 100%;
    left: 0;
    margin: auto;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .3s ease;
    width: 100%;
    z-index: 1;

    img {
        height: 100%;
        left: 0;
        max-height: none;
        max-width: none;
        object-fit: contain;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.plyr__preview-scrubbing--is-shown {
    opacity: 1;
}

.plyr--no-transition {
    transition: none !important;
}

.plyr__sr-only {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

@media (min-width: 480px) {
    .plyr__captions {
        font-size: 15px;
        font-size: var(--plyr-font-size-base, 15px);
        padding: calc(10px * 2);
        padding: calc(var(--plyr-control-spacing, 10px) * 2);
    }

    .plyr--video {
        .plyr__controls {
            padding: 10px;
            padding: var(--plyr-control-spacing, 10px);
            padding-top: calc(10px * 3.5);
            padding-top: calc(var(--plyr-control-spacing, 10px) * 3.5);
        }
    }
}

@media (min-width: 768px) {
    .plyr__captions {
        font-size: 18px;
        font-size: var(--plyr-font-size-large, 18px);
    }
}

@media (max-width: calc(768px - 1px)) {
    .plyr__time {
        + {
            .plyr__time {
                display: none;
            }
        }
    }
}

@media (min-width: 1024px) {
    .plyr {
        &:-webkit-full-screen {
            .plyr__captions {
                font-size: 21px;
                font-size: var(--plyr-font-size-xlarge, 21px);
                font-size: 21px;
                font-size: var(--plyr-font-size-xlarge, 21px);
            }
        }

        &:-ms-fullscreen {
            .plyr__captions {
                font-size: 21px;
                font-size: var(--plyr-font-size-xlarge, 21px);
                font-size: 21px;
                font-size: var(--plyr-font-size-xlarge, 21px);
            }
        }

        &:fullscreen {
            .plyr__captions {
                font-size: 21px;
                font-size: var(--plyr-font-size-xlarge, 21px);
            }
        }

        &:-moz-full-screen {
            .plyr__captions {
                font-size: 21px;
                font-size: var(--plyr-font-size-xlarge, 21px);
            }
        }
    }

    .plyr--fullscreen-fallback {
        .plyr__captions {
            font-size: 21px;
            font-size: var(--plyr-font-size-xlarge, 21px);
        }
    }
}
