@mixin reset-border($important: false) {
    @if $important {
        border: 0 !important;
        outline: none !important;

        &:hover,
        &:visited,
        &:focus {
            border: 0 !important;
            outline: none !important;
        }
    }
    @else {
        border: 0;
        outline: none;

        &:hover,
        &:visited,
        &:focus {
            border: 0;
            outline: none;
        }
    }
}
