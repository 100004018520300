@media (pointer: coarse) {
    // Mobile Fix
    .plyr--video.plyr--hide-controls {
        .plyr__controls {
            opacity: 1;
            pointer-events: auto;
            transform: translateY(0);
        }
    }
}
