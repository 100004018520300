@mixin caret-toggle() {
    @extend .h4;
    font-weight: normal;
    position: relative;
    user-select: none;

    &:hover {
        cursor: pointer;
    }

    &[aria-expanded="true"] {
        margin-bottom: $indent__xxs;

        @include min-screen($screen__sm) {
            margin-bottom: $indent__sm;
        }

        &:after {
            @include icon('caret-up-grey');
            position: absolute;
            right: 0;
            top: 0.9rem;
            transform: rotate(0deg);
            transition: transform 0.5s cubic-bezier(0.32, 1, 0.23, 1);
            vertical-align: middle;
            will-change: transform;
        }
    }

    &[aria-expanded="false"] {
        border-bottom: 1px solid $border-color__base--light;
        margin-bottom: $indent__xxs;
        padding-bottom: $indent__xxs;

        @include min-screen($screen__sm) {
            margin-bottom: $indent__sm;
            padding-bottom: $indent__sm;
        }

        &:after {
            @include icon('caret-up-grey');
            position: absolute;
            right: 0;
            top: 0.9rem;
            transform: rotate(180deg);
            transition: transform 0.5s cubic-bezier(0.32, 1, 0.23, 1);
            vertical-align: middle;
            will-change: transform;
        }

        @include min-screen($screen__md) {
            margin-bottom: $indent__xs;
            padding-bottom: $indent__xs;
        }
    }
}
