.columns .price-container {
    font-size: $font-size--product-large;
    font-weight: 800;

    &:after {
        color: $dark;
        font-size: $font-size__normal;
        font-weight: normal;
    }
}

.grouped-product__price .price-container {
    font-size: $font-size-paragraph--large;
}

//.old-price,
//.special-price {
//    .price-label {
//        display: none;
//    }
//}
//
//.price-box.price-final_price {
//    display: flex;
//    flex-direction: row-reverse;
//    align-items: baseline;
//    justify-content: space-evenly;
//    flex-wrap: wrap;
//    line-height: 1.4rem;
//    color: $text__muted-color;
//    margin-top: $indent__xxs;
//    margin-right: $indent__xxxs;
//
//    .product-tile & {
//        margin: $indent__xxs $indent__xxs 0 auto;
//    }
//
//    .price {
//        padding: 0;
//    }
//}
//
//.old-price {
//    .price-wrapper {
//        .price {
//            font-size: $font-size__normal;
//            text-decoration: line-through;
//            padding: 0 $indent__xxxs;
//        }
//    }
//}
//
//
//.price-to {
//    display: none;
//}
//
//.price-as-configured,
//.price-from,
//.price-to {
//    .price-label {
//        font-size: 1.4rem;
//        text-transform: lowercase;
//        width: auto;
//        display: inline-block;
//        text-align: right;
//        padding-right: 2rem;
//        padding-bottom: 0.8rem;
//        color: $text__muted-color;
//
//        .product-info-price & {
//            text-align: left;
//        }
//    }
//
//    .product-info-price & {
//        .price-final_price {
//            margin-bottom: $indent__xxs;
//        }
//    }
//}
//
//.price-as-configured,
//.normal-price,
//.special-price,
//.price-from {
//    display: block;
//
//    .price {
//        color: $text__base-color;
//        font-size: 2rem;
//        font-weight: 900;
//        padding: 0 2rem 0 8px;
//
//        .catalog-product_compare-index  & {
//            padding-right: 0;
//        }
//    }
//}
//
//.special-price,
//.old-price {
//    .product-tile & {
//        margin-left: auto;
//    }
//}
//
//.special-price {
//    .product-tile & {
//        margin-right: $indent__xxxs;
//    }
//}
//
//.normal-price,
//.special-price,
//.price-from {
//    margin-top: 0.6rem;
//    margin-bottom: 0;
//}
//
//.special-price.special-price--large {
//    margin: auto 0;
//}
//
//.add-to-cart-bottom {
//    .price,
//    .old-price,
//    .special-price {
//        @include max-screen($screen__md) {
//            font-size: 1.2rem;
//            line-height: 1.4rem;
//        }
//    }
//
//    .price-box.price-final_price {
//        margin: 0;
//    }
//}
