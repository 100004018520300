.counter-number {
    color: $white;
}

.minicart-wrapper .action.showcart .counter.qty {
    background: $black;
    border-radius: 100px;
    font-family: $font-family-sourcesanspro;
    padding: 0 $indent__xxxs;
    position: absolute;
    right: -#{$indent__xs};
    top: -#{$indent__xxxs};
    transform: scale(0.7);

    @include min-screen($screen__sm) {
        right: -#{$indent__md};
        top: -#{$indent__xxs};
        transform: scale(0.8);
    }
}
