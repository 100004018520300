$black: #000;
$white: #fff;

$dark-grey: #3f3f3f;
$dark: #868686;
$muted-grey: #c9c9c9;
$grey: #dadada;
$light-grey: #d4d3d2;
$light: #f5f3f2;

$color-red: #f00;

$gradient-black: linear-gradient(135deg, #0f341c 0%, #0f341c 100%);
$gradient-blue: linear-gradient(135deg, #2d72bb 0%, #0053a1 100%), url('../images/background/pattern-30.png');
$gradient-lilla: linear-gradient(135deg, #ab8fc0 0%, #b870ae 100%), url('../images/background/pattern-30.png');
$gradient-brown: linear-gradient(135deg, #873e0c 0%, #8e3a00 100%), url('../images/background/pattern-30.png');

