@keyframes infinite-spinning {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.price-loader-icon {
    animation: infinite-spinning 1s infinite;
    border: 2px solid #333;
    border-radius: 100%;
    border-right-color: transparent;
    display: inline-block;
    height: 18px;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    width: 18px;
}
