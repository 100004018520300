//
// Search
// -------------------------------------------
.block-search {
    color: black;
}

body.has--video-hero-widget,
body.has--category-header-image {
    .block-search {
        color: white;
    }
}

.header__content--white .block-search {
    color: white;

}

body:not(.navigation--open) {
    .block-search {
        @include max-screen($screen__sm) {
            display: none;
        }
    }
}

.block-search--minisearch {
    .block-content,
    .block-title {
        display: none;
    }

    &:before {
        @include max-screen($screen__sm) {
            content: none;
        }

        @include default-transition($targets: (filter), $duration: 0.5s);
        @include icon('magnifier-white');
        vertical-align: bottom;
    }

    .header__content--white & {
        &:before {
            filter: invert(0);
            @include default-transition($targets: (filter), $duration: 0.5s);
        }
    }
}


//
// Login
// -------------------------------------------

.authorization-link {
    text-align: center;

    .authorization-link__icon {
        transform: translateY(-1px);
        @include default-transition($targets: (filter), $duration: 0.5s);

        @include max-screen($screen__xxs) {
            transform: translateY(1px);
        }

        .header__content--white & {
            filter: invert(0);
            @include default-transition($targets: (filter), $duration: 0.5s);
        }
    }

    .authorization-link__text {
        color: $white;
        display: block;
        font-family: $font-family-sourcesanspro--semibold;
        font-size: 1rem;
        text-transform: uppercase;

        @include default-transition($duration: 0.5s);

        @include min-screen($screen__xxs) {
            letter-spacing: 0.0875rem;
            font-size: 0.875rem;
        }

        @include min-screen($screen__sm) {
            @include lib-visibility-hidden();
        }

    }
}

.default-header-page:not(.navigation--open) {
    .authorization-link__text, .wishlist__text, .action.showcart .text, .search-trigger__text {
        filter: invert(1);
    }
}

.switcher-language {
    display: block !important;
    position: absolute;
    right: 25px;
    top: 45px;

    @include max-screen($screen__sm) {
        top: 20px;
    }

    @include min-screen($screen__sm) {
        position: relative;
        right: unset;
        top: unset;
    }
}


//
// Minicart
// -------------------------------------------

.minicart-wrapper {
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0;
    text-align: center;

    .action.showcart {
        text-decoration: none;

        &:before {
            @include icon('cart-white');
            @include default-transition($targets: (filter), $duration: 0.5s);
            transform: translateY(2px);

            @include min-screen($screen__sm) {
                transform: translate(1px, 3px);
            }
        }

        .header__content--white & {
            &:before {
                filter: invert(0);
                @include default-transition($targets: (filter), $duration: 0.5s);
            }
        }

        .text {
            @include lib-visually-hidden-reset();
            border-bottom: 0 !important;
            color: $white;
            display: block;
            font-family: $font-family-sourcesanspro--semibold;
            font-size: 1rem;
            text-transform: uppercase;

            @include min-screen($screen__xxs) {
                letter-spacing: 0.0875rem;
                font-size: 0.875rem;
            }

            @include min-screen($screen__sm) {
                display: none;
            }

            .header__content--white &,
            .default-header-page & {
                color: $white;
                @include default-transition($duration: 0.5s);
            }
        }
    }
}


//
// Wishlist
// -------------------------------------------

.link.wishlist {
    text-align: left;

    .wishlist__text {
        color: $white;
        display: block;
        font-family: $font-family-sourcesanspro--semibold;
        font-size: 1rem;
        text-transform: uppercase;

        @include min-screen($screen__xxs) {
            letter-spacing: 0.0875rem;
            font-size: 0.875rem;
        }

        @include min-screen($screen__sm) {
            display: none;
        }

        .header__content--white &,
        .default-header-page & {
            color: $white;
            @include default-transition($duration: 0.5s);
        }
    }
}

.icon--wishlist {
    transform: translateY(3px) scale(0.9);
    vertical-align: baseline;

    @include default-transition($duration: 0.5s);

    .header__content--white & {
        filter: invert(0);
        @include default-transition($duration: 0.5s);
    }
}

//
// Search
// -------------------------------------------

.search-trigger {
    text-align: center;

    &__text {
        color: $white;
        display: block;
        font-family: $font-family-sourcesanspro--semibold;
        font-size: 1rem;
        text-transform: uppercase;

        @include min-screen($screen__xxs) {
            letter-spacing: 0.0875rem;
            font-size: 0.875rem;
        }

        .header__content--white &,
        .default-header-page & {
            color: $white;
        }
    }

    @include min-screen($screen__sm) {
        display: none;
    }

    .icon__search {
        .header__content--white & {
            filter: invert(0);
            @include default-transition($duration: 0.5s);
        }
    }
}

//
// new customer link
// -------------------------------------------

//
// new customer link with no header and background light
// -------------------------------------------
a.new_customer_link {
    color: $black;
    text-transform: uppercase;
    margin-right: 1.2rem;
    font-family: Source Sans Pro SemiBold, Helvetica, Arial, sans-serif;
    margin-bottom: 0;
    padding: 0;
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.87px;
}

//
// new customer link with no header and background cat header or home video
// -------------------------------------------

body.has--video-hero-widget,
body.has--category-header-image {
    a.new_customer_link {
        color: $white;
        text-transform: uppercase;
        margin-right: 1.2rem;
        font-family: Source Sans Pro SemiBold, Helvetica, Arial, sans-serif;
        margin-bottom: 0;
        padding: 0;
        font-weight: 600;
        font-size: 1.4rem;
        letter-spacing: 1.87px;
    }
}

//
// new customer link - navigation opened
// -------------------------------------------

body.navigation--open {
    a.new_customer_link {
        color: $white;
        text-transform: uppercase;
        margin-right: 1.2rem;
        font-family: Source Sans Pro SemiBold, Helvetica, Arial, sans-serif;
        margin-bottom: 0;
        padding: 0;
        font-weight: 600;
        font-size: 1.4rem;
        letter-spacing: 1.87px;
    }
}

//
// new customer link - all other cases
// -------------------------------------------

.header__content--white a.new_customer_link {
    color: $white;
    text-transform: uppercase;
    margin-right: 1.2rem;
    font-family: Source Sans Pro SemiBold, Helvetica, Arial, sans-serif;
    margin-bottom: 0;
    padding: 0;
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.87px;
}

@media only screen and (max-width: 1000px) {
    a.new_customer_link {
        display: none;
    }
}

//
// new customer link (navigation item)
// -------------------------------------------
.not-in-header {
    display: none;
}

.navigation--open .not-in-header {
    display: block;
}

//
// Styling for the header that is shown when there is no Video hero / Category image header, and the menu is not open.
// ------------------------------------------------

.default-header-page:not(.navigation--open) {

    .logo--desktop {
        filter: invert(1);
    }

    .authorization-link .icon,
    .icon--wishlist,
    .icon__search {
        filter: invert(1);
    }

    .block-search,
    .minicart-wrapper .action.showcart {
        &:before {
            filter: invert(1);
        }
    }

    .section-item-title {
        &:before {
            filter: invert(0.4);
        }

        .header__content--white & {
            &:before {
                filter: invert(0);
            }
        }
    }
}
.header__content--white .authorization-link .icon,
.header__content--white .icon--wishlist,
.header__content--white .icon__search {
    filter: invert(0) !important;
}

.header__content--white .block-search,
.header__content--white .action.showcart {
    &:before {
        filter: invert(0) !important;
    }
}

.authorization-link .icon,
.icon--wishlist,
.icon__search {
    filter: invert(0);
}

.block-search,
.minicart-wrapper .action.showcart {
    &:before {
        filter: invert(0);
    }
}

//
// store switcher
//


.header__content--white .view-nl,
.header__content--white .view-fr,
.header__content--white .view-de,
.header__content--white .view-en,
.header__content--white .view-es,
.header__content--white .view-it,
{
    color: white;
    filter: invert(1);
}

.header__content--white .switcher-option,
.header__content--white .switcher-option,
.header__content--white .switcher-option,
.header__content--white .switcher-option,
.header__content--white .switcher-option,
.header__content--white .switcher-option,
{
    color: white;
    filter: invert(0);
}

.header__content--white .action.toggle.switcher-trigger {
    color: black;
    filter: invert(1);
}
