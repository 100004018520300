.checkout-cart-index {
    .page-main {
        margin: $indent__sm 0;
    }

    .cart-summary #block-shipping {  // sass-lint:disable-line no-ids
        display: none;
    }
}

.checkout-methods-items {
    .button {
        width: 100%;
    }

    .action.multicheckout {
        display: none;
    }
}

.cart.table-wrapper .col.qty .input-text {
    min-width: 8rem;
}

.cart.main.actions {
    .button {
        padding: 1.4rem 0 1rem 0;

        &:before {
            width: 100%;
        }
    }
}

.button__decrement {
    &:after {
        @include icon('caret-down-black');
        margin-left: 0;
    }

    &:hover {
        cursor: pointer;
    }
}

.button__increment {
    &:after {
        @include icon('caret-up-black');
        margin-left: 0;
    }

    &:hover {
        cursor: pointer;
    }
}

.cart-container .form-cart {
    @include min-screen($screen__sm) {
        width: 65%;
    }
}

.cart-extra-buttons {
    margin-bottom: 3rem;
}

.cart-summary {
    @include min-screen($screen__sm) {
        margin-bottom: 0;
        position: absolute;
        right: 0;
        width: 33%;
        z-index: 9;
    }

    .coupon .primary {
        margin: 0;
        width: 100%;

        span {
            font-size: 1.4rem;
        }
    }
}

.cart.table-wrapper {
    .col.qty {
        padding-top: 0.6rem;
    }

    .col.price,
    .col.subtotal {
        &.discount-price {
            .price {
                color: $color-red;

                &::after {
                    content: '*';
                }
            }
        }
    }

    .control.qty {
        align-content: flex-end;
        display: flex;
        justify-content: right;
        padding-right: 1rem;
        width: 100%;

        .qty {
            line-height: 43px;
            margin: 0 1rem;
        }

        .button__decrement,
        .button__increment {
            padding: 10px 15px;

            &::after {
                height: 12px !important;
                width: 12px !important;
            }
        }

        .button__decrement {
            &::after {
                @include icon('minus-black');
            }
        }

        .button__increment {
            &::after {
                @include icon('plus-black');
            }
        }
    }

    .product-item-photo {
        width: 15%;

        @include min-screen($screen__sm) {
            min-width: 80px;
        }
    }

    .item .col.item {
        min-height: unset;
    }

    @include min-screen($screen__sm) {
        thead .col.qty span {
            padding-right: 5rem;
        }
    }
}

.cart.item {
    .item-actions {
        td {
            padding-top: 0;

            &.discount-label__message {
                text-align: left;
            }
        }

        .actions-toolbar {
            justify-content: flex-end;
            margin-top: 0;

            .action-edit {
                display: none;
            }

            .action-towishlist {
                display: none;
            }

            .action-delete {
                border: 0;
                padding: 0;

                &:hover {
                    border: 0;
                }
            }
        }
    }
}

.checkout-methods-items .to-checkout-button {
    width: auto;
}

.cart-container {
    position: relative;
    + .crosssell {
        @include min-screen($screen__sm) {
            margin-top: 126px;
        }
    }
}
