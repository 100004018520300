.flickity-page-dots {
    bottom: -35px;

    .dot {
        background: transparent;
        border: 1px solid $black;
        height: 11px;
        opacity: 1;
        width: 11px;

        &.is-selected {
            background: $dark;
            border-color: $dark;
        }
    }
}
