.toolbar-items {
    border-bottom: 1px solid $border-color__base--light;
    display: flex;
    justify-content: space-between;
    margin-bottom: $indent__xxs;
    width: 100%;

    @include min-screen($screen__sm) {
        border-bottom: 0;
        margin-bottom: 0;
    }
}

.toolbar-sorter.sorter {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.toolbar-item.toolbar-limit-sort {
    align-self: flex-end;
    margin: $indent__xxxxs 0 $indent__xxs auto;

    @include min-screen($screen__sm) {
        margin: $indent__sm 0 $indent__xs auto;
    }
}

.sorter-action {
    margin-left: $indent__sm;
    padding: $indent__xxxxs 0 $indent__xxxxs $indent__xxxxs;

    span {
        display: none;
    }

    &.sort-desc {
        &:after {
            @include icon('caret-up-grey');
            transform: rotate(180deg);
        }
    }

    &.sort-asc {
        &:after {
            @include icon('caret-up-grey');
        }
    }
}
