@mixin icon(
    $icon,
    $scale: null,
    $display: inline-block,
    $load-icon: true
) {
    @if $load-icon == true {
        @extend .icon-#{$icon} !optional;
        @extend .icon-#{$icon}-dims !optional;
    }

    content: '';
    display: $display;

    @if $scale != null {
        transform: scale($scale);
    }
}

.icon {
    display: inline-block;
}

@function svg-icon($icon, $icon-color) {
    @return str-replace($icon, '__color__', $icon-color);
}

@mixin svg-size($icon, $width) {
    height: $icon * $width;
    width: #{$width};
}
