
.filter__remove-icon {
    color: $text__base-color;
    font-size: 1.7rem;
    font-weight: 300;
    line-height: 0.9rem;
    margin-left: $indent__xxxs;
}

.filter-current {
    color: $text__base-color;
    margin-bottom: 3rem;
}

.filter-content {
    font-family: $font-family-sourcesanspro;
    position: relative;
}

.block.filter .filter-title {
    margin-bottom: 0;
}

.filter-current.item {
    margin-bottom: 5px;
}

.product-list-items .toolbar {
    display: none;
}

.filter-options.filter-options-title {
    margin-bottom: $indent__xxs;

    @include min-screen($screen__md) {
        margin-bottom: $indent__xs;
    }
}

.filter-options-item:last-child {
    .filter-options-content {
        border-bottom: 0;
    }

    .filter-options-title[aria-expanded="false"] {
        border-bottom: 0;
    }
}

.filter-options-content {
    max-height: 50vh;

    overflow-x: hidden;
    overflow-y: auto;

    .filter-options & {
        margin-bottom: $indent__xxs;
        padding-bottom: $indent__xxs;
    }

    .items {
        .item {
            color: $text__base-color;
            font-weight: 300;
            margin-bottom: $indent__xxxxs;

            &:last-child {
                margin-bottom: 0;
            }

            > a {
                color: $text__base-color;
            }
        }
    }

    .field.search {
        margin-bottom: $indent__xxs;
    }
}

.collapsible-filter {
    overflow: hidden;
    @include default-transition($targets: (height), $duration: 0.1s);

    &.active {
        height: 100% !important; // Use important because the height is set dynamically via inline css with JS.
        @include default-transition($targets: (height), $duration: 0.1s);
    }
}

.filter-options-content input[type="checkbox"] + label {
    font-weight: 300;
    margin-left: 4rem;
}

.block-content.filter-content {
    @include max-screen($screen__sm) {
        display: none;

        .block.filter.active & {
            display: block;
        }
    }
}
