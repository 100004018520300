body.navigation--open {
    height: 100%;
    margin: 0;
    overflow: hidden;
}

.desktop-navigations__wrapper {
    display: none;

    @include min-screen($screen__sm) {
        display: flex;
    }
}

.navigation__actions-wrapper {
    align-items: flex-start;
    border-top: 1px solid $border-color__base--darkest;
    display: none;
    justify-content: space-between;
    padding-top: $indent__xs;
    width: 90%;

    .navigation--open & {
        @include min-screen($screen__lg) {
            bottom: 0;
            display: flex;
            padding: $indent__xxl;
            position: absolute;
        }
    }

    @include min-screen($screen__sm) {
        border-top: 0;
        width: 100%;
    }
}

nav.navigation {
    ul.navigation__actions {
        @include min-screen($screen__sm) {
            display: none;
        }
    }

    & > svg {
        display: none;
    }
}

.my-account__link {
    // display: flex;
    display: none;

    &:before {
        @include icon('user-white');
        margin-right: $indent__xxs;
    }
}

.my-wishlist__link {
    display: flex;

    &:before {
        @include icon('heart-white');
        margin-right: $indent__xxs;
        transform: translateY(1px) scale(0.9);
        vertical-align: baseline;
    }
}

.navigation--open .header__content--collapsed {
    .logo--desktop {
        filter: invert(0);
        transform: scale(1);
        transform-origin: center;
        width: 11.4rem;
    }

    .logo--desktop-collapsed {
        transform: scale(0);
        transform-origin: center;
        width: 0;
    }
}

body.navigation--open {
    .store-language__wrapper {
        display: block;

        @include min-screen($screen__sm) {
            display: none;
        }

        .switcher {
            display: block;

            @include min-screen($screen__sm) {
                display: none;
            }

            .switcher-dropdown li a {
                color: $black;
            }
        }
    }

    .social-links {
        display: none;

        @include min-screen($screen__sm) {
            display: block;
        }
    }

    nav.navigation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        max-width: none;
        min-height: 100vh;
        opacity: 1;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        //background-blend-mode: multiply;
        background: $gradient-black;
        z-index: 999999;

        & > svg {
            display: none;
            pointer-events: none;
            position: absolute;
            z-index: -1;
        }

        &:before {
            background: linear-gradient(90deg, #0f341c 0%, #0f341c 100%);
            background-blend-mode: multiply;
            content: '';
            height: 10px;
            left: 0;
            mask-position: 50% 0;
            mask-repeat: no-repeat;
            mask-size: 150%;
            position: absolute;
            top: 100%;
            transform: rotate(180deg);
            width: 100%;
        }

        &:after {
            border-bottom: 25px solid #fff;
            border-left: 25px solid #fff;
            border-right: 25px solid transparent;
            border-top: 25px solid transparent;
            content: "";
            position: absolute;
            right: -50px;
        }

        @include min-screen($screen__sm) {
            min-height: 100vh;
        }

        .ui-menu {
            flex-direction: column;
            text-align: center;

            @include min-screen($screen__sm) {
                text-align: left;
                @include default-transition($targets: (opacity, transform), $duration: 0.5s);

                li {
                    @include default-transition($targets: (opacity, transform), $duration: 0.5s);
                }

                &:hover {
                    @include default-transition($targets: (opacity, transform), $duration: 0.5s);

                    li {
                        opacity: 0.5;
                        @include default-transition($targets: (opacity, transform), $duration: 0.5s);

                        &:hover {
                            opacity: 1;
                            transform: translateX($indent__sm);
                            @include default-transition($targets: (opacity, transform), $duration: 0.5s);
                        }

                        a {
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .page-header__items {
        z-index: 99999999;
    }
}

.menu-trigger__container {
    cursor: pointer;
    z-index: 9999999;
}

.default-header-page,
.header__content--white {
    .menu-trigger__link {
        filter: invert(0);
    }
}

.default-header-page,
.has--video-hero-widget,
.has--category-header-image {
    .header__content--collapsed {

        @include min-screen($screen__sm) {
            .navigation {
                height: auto;
                opacity: 1;
            }

            .menu-trigger__container {
                display: none;
            }
        }
    }
}

.navigation--open {
    &.default-header-page {
        .action.toggle {
            filter: invert(1);
        }
    }

    &.default-header-page,
    &.has--category-header-image,
    &.has--video-hero-widget {
        .header__content--white {
            .action.toggle {
                filter: invert(1);
            }
        }

        .menu-trigger__link {
            filter: invert(0);
        }

        .menu-trigger__text {
            color: $white;
        }

        .page-header__items {
            .link.wishlist .wishlist__text,
            .minicart-wrapper .action.showcart .text,
            .search-trigger__text,
            .authorization-link .authorization-link__text {
                color: $white;
            }
        }
    }

    .header__content--white {
        .page-header .switcher strong {
            color: $white;
        }

        .authorization-link .icon,
        .icon--wishlist,
        .menu-trigger__link {
            filter: invert(0);
        }

        .block-search,
        .minicart-wrapper .action.showcart {
            &:before {
                filter: invert(0);
            }
        }
    }

    nav.navigation {
        .ui-menu {
            align-self: flex-start;
            flex-direction: column;
            margin: $indent__xxs 0 0;
            padding-bottom: 4vh;
            text-align: center;
            width: 100%;

            @include min-screen(350px) {
                padding-bottom: 2vh;
            }

            @include min-screen($screen__sm) {
                border-bottom: 0;
                margin: 0 auto 0 $indent__lg;
                padding-bottom: 0;
                text-align: left;
                width: auto;
            }

            @include min-screen($screen__lg) {
                margin: 0 auto 0 $indent__xxl;
            }

            li {
                margin-right: 0;

                a {
                    align-items: flex-start;
                    color: $white;
                    margin-right: 0;
                    text-transform: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            & > .level-top,
            & > .level-top a {
                @extend .h1;

                @include max-screen($screen__sm) {
                    @include rfs(4.5rem);
                    line-height: $indent__lg;
                }
            }
        }
    }
}
