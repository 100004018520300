.filter-options-content .actions .secondary {
    margin-top: $indent__xxs;
}

.filter-current + .filter-actions {
    margin-bottom: 0;
}

.filter-actions {
    position: absolute;
    right: 0;
    top: 0;
}

a.action.remove {
    &:hover {
        text-decoration: none;
    }
}


.actions-toolbar {
    display: flex;
    justify-content: space-between;
    margin-top: $indent__xxs;
    padding: 0;
    width: 100%;

    &:after,
    &:before {
        display: none;
    }
}

.action-button {
    background: $black;
    border-radius: 40px;
    color: $white;
    font-size: 1.2rem;
    font-weight: 300;
    padding: $indent__xxxxs $indent__xxxs;
    @include default-transition($targets: (transform));

    &:hover {
        color: $white;
        cursor: pointer;
        text-decoration: none;
        transform: translateY(-5px);
        @include default-transition($targets: (transform));
    }
}

.action:not(.primary):not(.tocart):not(.share):not(.update):not(.remove):not(.action-button):not(.switcher-trigger) {
    color: $text__muted-color;

    > span {
        border-bottom: 1px solid $text__muted-color;
        font-weight: 300;

        &:hover {
            border-bottom: 1px solid transparent;
        }
    }

    &:focus,
    &:hover,
    &:visited {
        color: $text__muted-color;
    }

    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
}
