.hero-video-widget__container {
    height: 100vh;
    overflow: hidden; // Support for the min-height of the video poster
    position: relative;
    @include default-transition($targets: (height));

    @include min-screen($screen__md) {
        height: 0;
        padding-bottom: Min(56.25%, 100vh);
    }
}

.hero-video-widget {
    height: 100vh;

    @include min-screen($screen__md) {
        height: 0;
        padding-bottom: 56.25%; // 16 / 9

        &.hero-video-widget--playing {
            height: auto;
            padding-bottom: initial;
        }
    }
}

.hero-image-widget__player {
    height: 100vh;
    position: relative;

    @include min-screen($screen__md) {
        height: 0;
        padding-bottom: Min(56.25%, 100vh);
    }
}

.hero-video-widget__player {
    position: relative;

    > .plyr {
        max-height: 100vh;
    }

    .plyr__video-embed {
        @include max-screen($screen__md) {
            padding-bottom: 100vh !important;
        }

        @include min-screen($screen__md) {
            padding-bottom: 56.25% !important;
        }
    }

    .plyr__video-embed > .plyr__video-embed__container iframe {
        // To prevent letterboxing from showing.
        left: -160vw;
        top: -5%;
        width: 410vw !important;

        @include min-screen($screen__md) {
            left: initial;
            top: 1%;
            transform: scale(1.2);
            width: 100% !important;
        }
    }

    .plyr__poster {
        background-size: cover;
        transform: scale(1.01); // fix for small black bar
    }
}

.hero-video-widget__poster {
    bottom: 0;
    height: 100vh;
    left: 0;
    object-fit: cover;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    @include default-transition($targets: (opacity), $duration: 1s);

    .hero-video-widget--playing & {
        opacity: 0;
        @include default-transition($targets: (opacity), $duration: 1s);
    }
}

.hero-video-widget__poster--full-image {
    max-height: 100vh;
}

.hero-video-widget__content {
    bottom: 0;
    left: 0;
    max-height: 100vh;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
}

.hero-video-widget__bottom-bar {
    background: url('../images/svg/squiggle-horizontal.svg') left 100% no-repeat;
    background-size: contain;
    bottom: 0;
    height: 100%;
    left: 0;
    max-width: 1140px;
    position: absolute;
    width: 250vw;
    z-index: 10;

    svg {
        bottom: -45vh;
        height: 100%;
        left: 0;
        opacity: 0.55;
        position: absolute;
    }

    @include min-screen($screen__md) {
        display: none;
    }
}

.hero-video-widget__left-bar {
    display: none;

    svg {
        height: 100%;
        left: 0;
        margin-left: -3px;
        opacity: 0.55;
        position: absolute;
        width: unset !important; // override JS
    }

    @include min-screen($screen__md) {
        background: url("../images/svg/squiggle.svg") left 100% no-repeat;
        background-size: contain;
        bottom: 0;
        display: block;
        position: absolute;
        top: 0;
        width: 30%;
        z-index: 10;

        svg & {
            background-image: none;
        }
    }

    &:before {
        background: rgba(0, 0, 0, 0.55);
        bottom: 0;
        content: '';
        display: block;
        height: 100%;
        left: -100%;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.hero-video-widget__player .plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
    @include max-screen($screen__sm) {
        transform: translateY(-4rem) !important; // Zoom fix
    }
}

.hero-video-widget__content-text,
.hero-video-widget__content-text--breadcrumbs {
    align-items: end;
    bottom: 0;
    color: $white;
    display: grid;
    grid-template-rows: auto $indent__xxl $indent__xxl $indent__sm;
    height: 100%;
    left: 0;
    padding: $indent__sm;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 20;
}

.hero-video-widget__content-text {
    @include min-screen($screen__sm) {
        bottom: initial;
        margin-left: $indent__lg;
        max-width: 413px; // as per design
        padding: 0 0 $indent__xl 0;
    }

    @include min-screen($screen__md) {
        grid-template-rows: auto minmax(5%, $indent__xxxl) minmax(5%, $indent__xl) minmax(10%, 20%);
    }

    @include min-screen($screen__lg) {
        margin-left: $indent__xxl;
    }

    @include min-screen($screen__xxl) {
        grid-template-rows: auto minmax(5%, 10rem) minmax(5%, 6rem) minmax(10%, 25%);
    }

    @include min-screen($screen__xxxl) {
        grid-template-rows: auto minmax(5%, $indent__xxxl) minmax(5%, $indent__xl) minmax(10%, 30%);
    }
}

.hero-video-widget__content-text--breadcrumbs {
    grid-template-rows: 90% auto;

    @include min-screen($screen__lg) {
        grid-template-rows: 70% auto;
    }

    .breadcrumbs--hero,
    .content-text__heading {
        margin-bottom: $indent__xs;

        @include min-screen($screen__md) {
            margin-bottom: $indent__md;
            white-space: nowrap;
        }
    }
}

.hero-video-widget__content-text:not(.hero-video-widget__content-text--breadcrumbs) {
    .content-text__heading,
    .content-text__subtitle {
        hyphens: auto;
        max-width: 80%;
        word-break: break-word;

        @include min-screen($screen__md) {
            hyphens: inherit;
            max-width: 100%;
            word-break: inherit;
        }
    }
}

.content-text__subtitle {
    align-self: center;
    font-family: $font-family-sourcesanspro;

    @include min-screen($screen__sm) {
        max-width: 335px; // as per design
    }
}

.video-widget__cta {
    cursor: pointer;
}

.hero-video-widget__cta:first-of-type {
    margin-right: $indent__xxs;
}

.content-text__buttons {
    align-self: center;
    display: flex;
    flex-direction: row;
}

//
// Ambiance Meter
// ---------------------------------------------------------

.hero-video-widget__content-ambiance-meter {
    align-items: baseline;
    display: none;
    flex-direction: row;
    min-width: 50%;
    user-select: none;
    z-index: 10;

    &.hero-video-widget__content-ambiance-meter--activated {
        display: flex;
    }

    &:hover {
        cursor: pointer;
    }
}

.breadcrumbs.breadcrumbs--hero {
    margin-top: $indent__xl;
}

.scroll-down__text,
.ambiance-meter__text {
    color: $white;
    cursor: pointer;
    font-family: $font-family-sourcesanspro;
    text-transform: uppercase;
}

.scroll-down__text {
    position: relative;
    user-select: none;

    &:after {
        content: '';
        height: 3rem;
        position: absolute;
        right: -3rem;
        top: 0;
        width: 3rem;
    }
}

.scroll-down-icon {
    cursor: pointer;
    margin-left: $indent__xxxs;
    transform: scale(0.7) translateY(-0.2rem);
    @include default-transition();
}

.hero-image-widget__content-scroll-down {
    margin-bottom: $indent__xxs;

    &:hover {
        .scroll-down-icon {
            transform: scale(0.7) translateY(-1rem);
            @include default-transition();
        }
    }
}

.ambiance-meter__icon {
    margin-right: $indent__xxxs;
    transform: scaleY(1);
    transform-origin: bottom;
    @include default-transition($targets: (transform), $duration: 0.5s);

    .ambiance-meter--ambiance-off & {
        transform: scaleY(0.2);
        transform-origin: bottom;
        @include default-transition($targets: (transform), $duration: 0.5s);
    }
}
