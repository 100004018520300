$button__color: $black;
$button__color--hover: $black;

$button__border-color: transparent;
$button__border-color--hover: transparent;

$button__background-color: $white;
$button__background-color--hover: $white;

$button-black__color: $white;
$button-black__background-color: $black;

$button-ghost__background-color: transparent;
$button-ghost__border-color: $black;
$button-ghost__background-color--hover: $black;
$button-ghost__border-color--hover: $black;
$button-ghost__color: $white;

$button-ghost-white__background-color: transparent;
$button-ghost-white__border-color: $white;
$button-ghost-white__background-color--hover: $white;
$button-ghost-white__border-color--hover: $white;
$button-ghost-white__color: $white;
$button-ghost-white__color--hover: $black;


@keyframes scaleAnimation {
    0% {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(0.5, 0.5, 0.5);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

button,
.button {
    align-items: center;
    background: $button__background-color;
    border: 1px solid $button__border-color;
    border-radius: 2.5rem;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    color: $button__color;
    display: inline-flex;
    font-family: $font-family-sourcesanspro--semibold;
    font-size: $font-size--button;
    font-weight: 600;
    padding: 0.85rem 1.6rem;
    height: 3.8rem;

    letter-spacing: 1.5px;
    line-height: 1.9rem;

    text-decoration: none;
    text-transform: uppercase;
    vertical-align: top;
    white-space: nowrap;
    @include default-transition();

    @include min-screen($screen__xs) {
        height: 4.5rem;
        padding: 1.2rem 3rem;
    }

    &:after {
        @include icon('caret-right-black');
        margin-left: 1rem;

        @include max-screen($screen__xxs) {
            transform: scale(0.9) translateY(1px);
        }
    }

    &:focus,
    &:hover {
        background: $button__background-color--hover;
        border: 1px solid $button__border-color--hover;
        box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.1);
        color: $button__color--hover;
        text-decoration: none;
        transform: translateY(-5px);
    }

    &.ghost {
        background: $button-ghost__background-color;
        border: 1px solid $button-ghost__border-color;

        &:after {
            @include icon('caret-right-black');
            @include default-transition();
        }

        &:focus,
        &:hover {
            background: $button-ghost__background-color--hover;
            border: 1px solid $button-ghost__border-color--hover;
            color: $button-ghost__color;

            &:after {
                filter: invert(1);
            }
        }
    }

    &.ghost-white {
        background: $button-ghost-white__background-color;
        border: 1px solid $button-ghost-white__border-color;
        color: $button-ghost-white__color;

        &:after {
            @include icon('caret-right-white');
            @include default-transition();
        }

        &:focus,
        &:hover {
            background: $button-ghost-white__background-color--hover;
            border: 1px solid $button-ghost-white__border-color--hover;
            color: $button-ghost-white__color--hover;

            &:after {
                filter: invert(1);
            }
        }
    }

    &.black {
        background: $button-black__background-color;
        color: $button-black__color;

        &:after {
            @include icon('caret-right-white');
        }

        &:focus,
        &:hover {
            background: $button-black__background-color;
            color: $button-black__color;
        }
    }


    &.cart {
        &:after {
            display: none;
        }

        &:before {
            margin-right: 1rem;
            transform: scale3d(1, 1, 1) translateX(-0.1rem);
            vertical-align: bottom;
            @include icon('cart-white');
        }
    }

    &.cart-icon {
        padding: 0.8rem 1.6rem;
        @include default-transition();

        &:after {
            display: none;
        }

        &:before {
            transform: scale3d(1, 1, 1) translateX(-0.1rem);
            vertical-align: bottom;
            @include icon('cart-white');
        }
    }

    &.cart.disabled,
    &.cart-icon.disabled {
        background-color: #189a00;

        &:before {
            animation: 1s cubic-bezier(0.08, 0.4, 0.44, 0.57) scaleAnimation;
        }
    }
}

.button--back-button {
    @include max-screen($screen__sm) {
        transform: scale(0.7);
        transform-origin: top left;

        .catalogsearch-result-index & {
            margin-bottom: $indent__xxs;
        }
    }

    &:after {
        display: none;
    }

    &:before {
        @include icon('caret-left-black');
        margin-right: $indent__xxxs;
    }
}
