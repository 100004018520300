.amlocator-search-container {
    .amlocator-button.-nearby {
        @extend .button;
    }

    .amlocator-current-location .amlocator-text {
        border-radius: 2.5rem;
    }
}

.amlocator-main-container {
    .amlocator-map-container .amlocator-map button {
        border-radius: 2px;

        &:hover {
            box-shadow: none;
            transform: none;
        }

        &:after {
            display: none;
        }
    }
}

.amlocator-pager-container {
    .pages a.page {
        background: transparent;

        &:hover {
            color: $white;
        }
    }
}

.cms-store-locator .pages .item.pages-item-previous .button span {
    display: none;
}

.amlocator-current-location .amlocator-wrapper .amlocator-text + .amlocator-search,
.amlocator-current-location .amlocator-wrapper .amlocator-search {
    @include icon('magnifier-black');
    margin-right: 9px;
    margin-top: 1px;
    opacity: 0.5;
}

.amlocator-current-location .amlocator-wrapper .amlocator-text:focus + .amlocator-search,
.amlocator-current-location .amlocator-wrapper .amlocator-search:hover {
    @include icon('magnifier-black');
}
