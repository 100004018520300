.icon--inactive {
    &,
    > * {
        opacity: 0.3;
        @include default-transition();

        &:hover {
            opacity: 1;
            text-decoration: none;
            @include default-transition();
        }
    }
}

.icon {
    vertical-align: middle;

    &:after {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        display: block;
    }

    &.chevron:after {
        margin-bottom: 0.4rem;
        //background-image: svg-url(svg-icon($icon-chevron,$pallete-tertiary));
        //@include svg-size($icon-chevron-size,28px);
    }
}
