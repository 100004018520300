.cms-page-header,
.category-header {
    align-items: flex-start;
    background: linear-gradient(90deg, #0f341c 0%, #0f341c 100%);
    background-blend-mode: multiply;
    background-position: center top;
    background-repeat: repeat;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    min-height: 20rem;
    overflow: hidden;
    padding-top: 14rem;
    position: relative;
    width: 100%;
    z-index: 1;

    @include min-screen($screen__xs) {
        min-height: 25rem;
        padding-top: 12rem;
    }

    @include min-screen($screen__md) {
        min-height: 30rem;
        padding-top: 16rem;
    }

    @include min-screen($screen__lg) {
        min-height: 35rem;
        padding-top: 17rem;
    }

    &:after {
        background-size: cover;
        bottom: -1px;
        content: '';
        height: 2rem;
        left: -1px;
        position: absolute;
        width: 101%;
    }
}

.category-header__container {
    margin-top: $indent__sm;

    @include min-screen($screen__sm) {
        margin-top: 0;
    }
}


.cms-page-header {
    .page-title-wrapper {
        text-align: left;
    }

    .page-title {
        color: $white;
    }

    &:before {
        background: linear-gradient(90deg, rgba(15, 52, 28, 0.5), transparent);
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
