.absolute-footer__content-container {
    margin-bottom: 5.5rem; // specific as per design

    ul {
        color: $text__muted-color--light;

        a {
            color: $text__muted-color--light;
            word-wrap: break-word;

            &:hover {
                color: $text__muted-color--light;
            }
        }

        > li {
            display: inline-block;
            padding-bottom: $indent__xxxxs;
            width: 48%;

            @include min-screen($screen__sm) {
                padding-right: $indent__md;
                width: auto;
            }
        }
    }
}
