$price-slider-handle__dimension: 3.3rem; // specific as per design.
$price-slider-bar__border-radius: $indent__xxs;

.slider-inputs {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 19px;
    justify-content: flex-start;

    input[type="number"].slider-inputs__input {
        border: 1px solid $border-color__base;
        max-width: 77px;
        padding: 9px 16px 9px 28px;

        @include default-transition($targets: (border-color));

        &:focus {
            border-color: $border-color__base--focus;
            @include default-transition($targets: (border-color));
        }
    }

    .slider-between {
        margin: 0 $indent__xxs;
    }

    .slider-inputs__input-currency {
        color: $text__base-color;
        display: inline-block;
        font-family: $font-family-sourcesanspro--light;
        left: $indent__xxs;
        position: absolute;
        top: calc(50% - 14px);
    }

    .slider-inputs__input-currency-container {
        position: relative;
    }
}

.ui-slider-range.ui-widget-header.ui-corner-all {
    background: $dark;
    height: 7px;
    position: absolute;
    top: -1px; // Height and top make the range overlap with the border.
}

[data-role="to-label"],
[data-role="from-label"] {
    display: inline-block;
}

[data-role="to-label"] {
    float: right;
}

.ui-slider-horizontal {
    background: $white;
    border: 1px solid $border-color__base;
    border-bottom-left-radius: 8px;
    border-right: 0;
    border-top-left-radius: 8px;
    height: 7px;
    margin: 4rem 0 $indent__sm 0; // 4rem specific as per design
    width: 90%;

    &:after {
        background: $white;
        border: 1px solid $border-color__base;
        border-bottom-right-radius: 8px;
        border-left: 0;
        border-top-right-radius: 8px;
        box-sizing: border-box;
        content: '';
        display: inline-block;
        height: 7px;
        position: absolute;
        right: -6%;
        top: -1px;
        width: 7%;
    }
}

.ui-slider .ui-slider-handle {
    background: $link__base-color;
    border-radius: $price-slider-handle__dimension;
    height: $price-slider-handle__dimension;
    margin-left: -8px;
    margin-top: -1.55rem;
    width: $price-slider-handle__dimension;


    &:first-of-type {
        margin-left: 0.04rem;
    }

    &:after {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:nth-of-type(1):after {
        @include icon('caret-left-white');
        left: calc(50% - 1px);
    }

    &:nth-of-type(2):after {
        @include icon('caret-right-white');
        left: 50%;
    }

    &:hover {
        cursor: pointer;
    }
}
