@import "checkout-shipping-address";
@import "checkout-shipping-method";
@import "opc-form-login";
@import "opc-step-payment";
@import "opc-step-shipment";
@import "opc-summary";


.field-tooltip {
    .label {
        @include abs-visually-hidden();
    }
}

.field.street {
    .field {
        .label {
            @include abs-visually-hidden();
        }
    }
}

.checkout-index-index {

    label.label {
        display: none;
    }

    .payment-method-title.field {
        > label.label > span {
            margin-left: 12rem;
        }

        @include max-screen($screen__m) {
            > label.label > span {
                margin-left: 3rem;
            }
        }
    }

    .payment-method-title {
        label.label {
            display: inline-block;

            span {
                margin-left: 0;
            }
        }
    }


    .opc-progress-bar-item {
        > span {
            line-height: 2.8rem;
        }

        & > span:after {
            content: counter(i);
            counter-increment: i;
            font-family: inherit;
            font-size: 18px;
            line-height: inherit;
        }

        &:first-child > span:after {
            content: "1";
        }

        &._active {
            &:before {
                background: $black;
            }

            > span:before {
                background: $black;
            }
        }
    }

    .logo {
        margin: 0 $indent__xxs;
    }

    .checkout-container {
        margin: $indent__sm 0;

        @include min-screen($screen__md) {
            margin: $indent__lg 0 $indent__xs;
        }
    }

    .shippingAddressAdditional {
        margin-top: $indent__xxs;
    }
}


.opc-estimated-wrapper {
    padding: 0 0 $indent__xxs 0;

    .minicart-wrapper {
        display: block;
        margin: 0 0 $indent__xxs 0;
        width: 100%;
    }

    .action {
        width: 100%;
    }
}

.step-content form.methods-shipping .actions-toolbar,
.step-content form .payment-method .actions-toolbar {
    @include min-screen($screen__sm) {
        justify-content: flex-end;
        margin-left: 0;
    }
}

.checkout-payment-method .payment-methods {
    margin: 0;
}

.checkout-payment-method .payment-option {
    @include max-screen($screen__sm) {
        margin: 0;
    }
}

.authentication-wrapper {
    display: none;
}

.payment-method.klarna-payments-method {
    .payment-method-title {
        height: 67px;
        position: relative;
    }
}

//.action.secondary.action-hide-popup,
//.action.action-show-popup,
//.action.action-apply {
//    @include button('secondary');
//}
//
//.action.primary {
//    @include button('primary');
//}

.cart.table-wrapper .product-item-photo {
    width: 33%;
}

.cart.table-wrapper .col.qty .input-text {
    width: 25%;
}

.checkout-payment-method .payment-option .payment-option-title {
    padding: $indent__xxs 0;
}

.checkout-cart-index .item-options .price {
    display: none;
}

span.klarna-payments-method-cell {
    left: 4.6rem;
    margin-left: 0 !important;
    position: absolute;
    top: 0.9rem;

    & + & {
        left: 17rem;
        top: 1.4rem;
    }

    @include min-screen($screen__sm) {
        left: 3.2rem;
        top: 1.4rem;

        & + & {
            left: 13.3rem;
            top: 1.9rem;
        }
    }
}
