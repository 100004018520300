.card__collections {
    &.product-item {
        .card__cta {
            margin-left: 0;
        }

        .card__title {
            margin-bottom: $indent__xs;
        }
    }

    .card__image {
        padding-top: 127%; // forces aspect-ratio
    }
}

.products-wrapper--shop-the-look {
    margin-bottom: $indent__xl;
    margin-top: $indent__xl;

    .card__container {
        margin: 0;
    }
}

.shop-the-look__headings {
    margin-bottom: $indent__sm;
    text-align: center;

    @include min-screen($screen__lg) {
        margin-bottom: $indent__lg;
    }

}

.shop-the-look__title {
    margin-bottom: $indent__xxxs;
}

.page-header ~ .breadcrumbs {
    display: none;
}

.shop-the-look-list-items {
    display: grid;
    grid-column-gap: $indent__xxxxs;
    grid-row-gap: $indent__xxxxs;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    padding: 0;

    .card__container {
        min-height: 0;
    }

    .card__content {
        justify-content: space-between;
    }

    @include min-screen($screen__sm) {
        grid-column-gap: $indent__sm;
        grid-row-gap: $indent__sm;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    }

    @include min-screen($screen__lg) {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    }
}
