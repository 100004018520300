.block-search--minisearch {
    cursor: pointer;
    margin-bottom: 0;
    margin-top: $header-inflated-height;
    width: 90%;

    @include min-screen($screen__sm) {
        margin-top: 0;
        position: relative;
        width: initial;
    }
}

.close-search-button {
    position: absolute;

    @include min-screen($screen__sm) {
        display: none;
    }
}

.search-content {
    margin-bottom: 0;
    position: relative;

    @include max-screen($screen__sm) {
        display: block !important;
    }

    @include min-screen($screen__sm) {
        display: none;
        margin-bottom: initial;
        position: absolute;
        right: -15px;
        top: -50%;
        width: clamp(350px, 32vw, 500px);
        z-index: 9999999;
    }
}

.close-search-button {
    position: absolute;
    right: $indent__xxxs;
    top: $indent__xxxs;
}

.search-actions {
    position: absolute;
    right: 0;
    top: 0;
}

.search-button {
    background: transparent;
    padding: $indent__xxs;

    &:hover {
        background: transparent;
        box-shadow: none;
        transform: unset;
    }

    &:after {
        display: none;
    }
}

