.widget-promises,
.promise-steps {
    p {
        font-weight: 700;
    }

    @include min-screen($screen__sm) {
        justify-content: space-evenly;
        margin: 0 auto;
    }

}

.promises__tagline {
    margin-bottom: $indent__xxxs;
    @include min-screen($screen__sm) {
        margin-bottom: $indent__xs;
    }
}

.widgets-promise__slider {
    margin-bottom: $indent__xs;
}

.promise__container {
    display: none;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &:first-of-type {
        display: flex;
    }

    .flickity-enabled & {
        display: flex;
    }

    @include min-screen($screen__sm) {
        flex-direction: row;
    }
}

.promise-steps {
    display: none;
    @include min-screen($screen__sm) {
        flex: 1 0 100%;
        justify-content: center;

        .flickity-enabled + & {
            display: flex;
        }
    }

    li {
        color: $dark;
        cursor: pointer;
        font-family: $font-playfairdisplay--regular;
        list-style: none;
        margin-bottom: $indent__xs;
        margin-right: auto;
        margin-right: Max($indent__xs, 5%);

        &.active {
            color: $black;
            display: block;
        }
    }
}

.promise-widget-body {
    min-height: 300px;
}

.uppercase-promise {
    margin-bottom: $indent__xs;
}

.promise__container-left {
    align-content: center;
    margin-bottom: $indent__xxs;
    text-align: center;
    width: 100%;

    @include min-screen($screen__sm) {
        justify-content: right;
        margin-bottom: 0;
        margin-right: $indent__xxs;
        text-align: right;
        width: 50%;
    }

    img {
        margin: 0 auto;
    }
}

.promise__container-right {
    text-align: center;
    width: 100%;

    @include min-screen($screen__sm) {
        margin-left: $indent__xxs;
        text-align: left;
        width: 50%;
    }

    .promise__title {
        margin-bottom: $indent__xxs;
        @include min-screen($screen__sm) {
            margin-bottom: $indent__sm;
        }
    }

    .promise__subtitle {
        display: block;
        margin-bottom: $indent__xs;
        @include min-screen($screen__sm) {
            margin-bottom: $indent__sm;
        }
    }
}

.promise__description {
    margin-top: $indent__sm;
    @include min-screen($screen__sm) {
        max-width: 500px;
    }
}
