$social-icon-dimensions: 37px !default;

.social-links__item-icon {
    display: inline-block;
}

.social-links__list {
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
}

.social-links__item-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

a.social-links__item {
    align-items: center;
    border: 1px solid $border-color__base--dark;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    height: $social-icon-dimensions;
    justify-content: center;
    margin-bottom: $indent__xxxs;
    margin-right: $indent__xxxs;
    transition: all 0.5s cubic-bezier(0.32, 1, 0.23, 1);
    width: $social-icon-dimensions;

    > span {
        transition: all 0.5s cubic-bezier(0.32, 1, 0.23, 1);
    }


    &:focus,
    &:hover {
        background: $button__background-color--hover;
        border-color: $white;
        box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.1);
        color: $button__color--hover;
        text-decoration: none;
        transform: translateY(-5px);
        transition: all 0.5s cubic-bezier(0.32, 1, 0.23, 1);

        > span {
            filter: invert(1);
            transition: all 0.5s cubic-bezier(0.32, 1, 0.23, 1);
        }
    }
}
