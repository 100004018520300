.widget-siblings {
    margin-top: $indent__lg;

    .widget-siblings-slider {
        &.flickity-enabled {
            margin-bottom: $indent__lg;
        }

        > *:nth-of-type(n+1) {
            display: none;
        }

        > *:nth-of-type(1) {
            display: inherit;
        }

        @include max-screen($screen__lg) {
            &.flickity-enabled {
                > *:nth-of-type(n+1) {
                    display: inherit;
                }
            }
        }

        @include min-screen($screen__lg) {
            > *:nth-of-type(2) {
                display: inherit;
            }

            .page-layout-1column .page-main .column.main > &,
            & {
                display: flex;
                margin-left: -1%;
                margin-right: -1%;

                &.flickity-enabled {
                    display: inherit;
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .widget-promo__container {
                width: 48%;
            }

            &.flickity-enabled {
                .widget-promo__container {
                    width: 49%;
                }
            }
        }
    }


    .widget-promo__container {
        margin: 0 1%;
        width: 100%;
        @include min-screen($screen__sm) {
            padding-top: 13%;
        }
    }
}

.widget-siblings__container {
    margin-bottom: $indent__lg;

    @include min-screen($screen__sm) {
        margin-bottom: $indent__xl;
    }
}
