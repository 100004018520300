$breadcrumbs__font-size: $font-size__small;
$breadcrumbs-current__font-weight: 400;
$breadcrumbs-current__background: false;
$breadcrumbs-link__text-decoration: underline;
$breadcrumbs-link__visited__background: false;
$breadcrumbs-link__visited__border: false;
$breadcrumbs-link__visited__text-decoration: underline;
$breadcrumbs-link__hover__background: false;
$breadcrumbs-link__hover__border: false;
$breadcrumbs-link__hover__text-decoration: none;
$breadcrumbs-link__hover__background: false;
$breadcrumbs-link__hover__border: false;
$breadcrumbs-link__hover__text-decoration: none;
$black: #000 !default;
$white: #fff !default;

.breadcrumbs {
    font-family: $font-family-sourcesanspro;
    margin-bottom: 0;
    padding: 0;
    position: relative;

    @include lib-breadcrumbs(
        $_breadcrumbs-current-color: $text__muted-color--dark,
        $_breadcrumbs-separator-color: $black,
        $_breadcrumbs-link-color: $black,
        $_breadcrumbs-link-color-visited: $black,
        $_breadcrumbs-link-color-hover: $black,
        $_breadcrumbs-link-color-active: $black
    );

    .has--category-header-image &,
    .catalog-category-view &,
    &.light {
        @include lib-breadcrumbs(
            $_breadcrumbs-current-color: $white,
            $_breadcrumbs-separator-color: $white,
            $_breadcrumbs-link-color: $white,
            $_breadcrumbs-link-color-visited: $white,
            $_breadcrumbs-link-color-hover: $white,
            $_breadcrumbs-link-color-active: $white
        );
    }

    .item:not(:last-child):after {
        color: $black;
    }

    .has--category-header-image & .item:not(:last-child):after,
    .catalog-category-view & .item:not(:last-child):after,
    &.light .item:not(:last-child):after {
        color: $white;
    }

    .item:not(:last-child):after,
    .has--category-header-image & .item:not(:last-child):after,
    .catalog-category-view & .item:not(:last-child):after,
    &.light .item:not(:last-child):after {
        vertical-align: middle;
    }
}

.has--category-header-image,
.catalog-category-view {
    .breadcrumbs {
        margin-bottom: 0;
    }
}
