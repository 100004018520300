.button--subscribe {
    margin-left: $indent__xxxs;

    &:before {
        @include screen($screen__sm, 1023px) {
            content: attr(title);
            margin-right: $indent__xxxxs;
        }

        @include min-screen($screen__xl) {
            content: attr(title);
            margin-right: $indent__xxxxs;
        }
    }

    &:after {
        margin-left: 0;
    }
}

p.label {
    color: $text__muted-color--light;
    font-family: $font-sourcesanspro--light, Helvetiva, Arial, sans-serif;
    margin-bottom: 1.8rem; // Specific as per design
}

.actions--subscribe {
    align-self: flex-start;
}

.form-subscribe--subscribe {
    display: flex;
}

.field.newsletter {
    @include min-screen($screen__md) {
        width: 100%;
    }
}
