.master-login__resp-table {
    display: table;
    width: 100%;
}

#master-login__resp-table-caption {  // sass-lint:disable-line no-ids
    display: table-caption;
    font-weight: bold;
    text-align: left;
}

#master-login__resp-table-header { // sass-lint:disable-line no-ids
    background-color: $dark-grey;
    color: $text__white-color;
    display: table-header-group;
    font-weight: bold;
}

.master-login__table-header-cell {
    border-bottom: 1px solid #000;
    display: table-cell;
    padding: 10px;
    text-align: left;
}

#master-login__resp-table-body { // sass-lint:disable-line no-ids
    display: table-row-group;
}

.master-login__resp-table-row {
    display: table-row;

    &:nth-child(odd) {
        background-color: $light-grey;
    }

    &:hover {
        background-color: $muted-grey;
    }
}

.master-login__table-body-cell {
    display: table-cell;
    font-weight: normal;
    text-align: left;
}

#master-login__resp-table-footer { // sass-lint:disable-line no-ids
    display: table-footer-group;
    font-weight: bold;
}

.master-login__table-footer-cell {
    border-bottom: 1px solid #000;
    display: table-cell;
    padding: 10px;
    text-align: left;
}

.master-login__table-body-cell.action,
.master-login__table-header-cell.action {
    text-align: left;
}

.master-login__table-footer-cell.pagination {
    text-align: center;

    li,
    li.active {
        display: inline-block;
        padding: 5px;
    }

    li {
        text-decoration: underline;
    }

    li.active {
        text-decoration: none;
    }
}

.master-login__table-header-cell,
.master-login__table-body-cell,
.master-login__table-footer-cell {
    width: 25%;

    &.large {
        width: 50%;
    }
}
