.discount-label {
    color: $white;
    font-family: $font-sourcesanspro--semibold;
    font-weight: 700;
    left: 0;
    padding: 5px 20px;
    position: absolute;
    top: 15px;
    z-index: $z-index-1;
    margin-right: 5px;

    &__asterix {
        color: $color-red;
        font-size: 3rem;
        font-weight: $font-weight__bold;
        margin-right: $indent__lg;

        &::before {
            content: '*';
        }
    }

    &__message {
        color: $color-red;
        flex: 0 0 100%;
        font-size: $font-size__extrasmall;
        margin-top: $indent__xxxxs;

        &::before {
            content: '*';
        }
    }

    &.discount-gray {
        background: $dark;
    }

    &.discount-red {
        background: $color-red;
    }

    &.product-page {
        display: inline-block;
        position: static;
        margin-bottom: 5px;
    }

    &.page-cart {
        left: unset;
        top: 0;
        transform: translateX(250px);
    }
}

.group-productgroup {
    position: relative;
}

.checkout-cart-index {
    .product-item-details {
        position: relative;
    }
}
